import React from 'react';
import dayjs from 'dayjs';
import { Typography } from 'antd';
import { useIntl } from 'react-intl';

import { openModal } from 'utils/modal';
import { mapCancelReason } from 'utils/pickups';
import { TICKET_LINK } from 'constants/shipments';
import {
  OUTBOUND,
  OUTBOUND_AGENT,
  PICKUP_LOG_ACTIONS,
  PICKUP_TICKET_LOG
} from 'constants/pickups';

import BRButton from 'components/BRButton/BRButton';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';
import EmptyData from 'components/Pickups/components/PickupDetails/components/EmptyData/EmptyData';

import './ActivityTimeline.less';

export default function ActivityTimeline({ log }) {
  const intl = useIntl();
  if (!log || log.length === 0) {
    return <EmptyData />;
  }

  const updatedLog = log.map((item) => {
    const { takenBy } = item;
    if (item.actionType === OUTBOUND && takenBy.userRole !== OUTBOUND_AGENT) {
      return {
        ...item,
        doneBy: `${takenBy.userRole}: ${takenBy.userName} ${intl.formatMessage({
          id: 'pickups.pickup_details.outbound_action'
        })}`
      };
    }

    return {
      ...item,
      doneBy: `${takenBy.userRole}: ${takenBy.userName}`
    };
  });

  return (
    <div className="br-pickup-activity-timeline">
      {updatedLog.map((logEntry) => {
        const formattedTime = dayjs(logEntry.time).tz().format('ddd, D MMM  hh:mm A');
        return (
          <div className="br-pickup-activity-timeline-item" key={logEntry._id}>
            <Typography.Title
              level={5}
              className="br-pickup-activity-timeline-item__title"
            >
              <LogAction log={logEntry} />
            </Typography.Title>

            <Typography.Paragraph className="br-pickup-activity-timeline-item__time">
              <Typography.Text>
                {formattedTime}
              </Typography.Text>
            </Typography.Paragraph>

            <Typography.Paragraph className="br-pickup-activity-timeline-item__user">
              <Typography.Text>{logEntry.doneBy}</Typography.Text>
            </Typography.Paragraph>
          </div>
        );
      })}
    </div>
  );
}

function ProofOfExceptionButton({ log }) {
  const intl = useIntl();

  const handleViewProofOfExceptionClick = (previewImage) => {
    openModal(BRPreviewImage, {
      previewImageTitle: intl.formatMessage({
        id: 'pickups.pickup_details.proof_of_exception'
      }),
      previewImage
    });
  };

  if (!log.exceptionProofUrl) {
    return <></>;
  }

  return (
    <BRButton
      type="link-color"
      label={intl.formatMessage({
        id: 'pickups.pickup_details.proof_of_exception'
      })}
      onClick={() => handleViewProofOfExceptionClick(log.exceptionProofUrl)}
    />
  );
}

function LogAction({ log }) {
  const intl = useIntl();

  if (log.action === PICKUP_LOG_ACTIONS.EXCEPTION) {
    return (
      <div>
        {intl.formatMessage(
          {
            id: 'pickups.pickup_details.action_reason'
          },
          {
            action: log.action,
            reason: mapCancelReason[log.reason]
              ? intl.formatMessage({
                  id: `pickups.cancel_reasons.${
                    log.reason.includes('_')
                      ? log?.reason
                      : mapCancelReason[log.reason]
                  }`
                })
              : log.reason
          }
        )}
        <ProofOfExceptionButton log={log} />
      </div>
    );
  }

  return (
    <div>
      {log.action}
      {log.action === PICKUP_TICKET_LOG ? (
        <a
          className="br-pickup-details__ticket-link"
          href={`${TICKET_LINK}${log.ticketId}`}
          target="_blank"
        >
          {log.ticketId}
        </a>
      ) : (
        ''
      )}
      <ProofOfExceptionButton log={log} />
    </div>
  );
}
