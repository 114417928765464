import classNames from 'classnames';

import { Skeleton, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { DELIVERIES_TYPE } from 'constants/sorting-facility';
import { getInitialDate } from 'utils/sorting-facility';

import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as DownloadIcon } from 'assets/bosta-icons/Download.svg';

import './SingleCardInfo.less';

const SingleInfoCard = ({
  title,
  data,
  type,
  loading,
  tooltip,
  exportFunction,
  selectedDate,
  deliveryReceivedState,
  states,
  isTransferredFrom,
  isTransitPlus,
  isInvestigation,
  isInvestigationPlus,
  initialDate
}) => {
  const handleExportCardsData = () => {
    return exportFunction({
      deliveryReceivedState,
      states,
      isTransferredFrom,
      isTransitPlus,
      isInvestigation,
      isInvestigationPlus
    });
  };
  return (
    <div className="single-info__container">
      <h3 className="single-info__header body-medium">
        {title}{' '}
        {tooltip && (
          <Tooltip title={tooltip}>
            <TooltipIcon />
          </Tooltip>
        )}
      </h3>
      <div className="single-info__data-container">
        {loading ? (
          <Skeleton active paragraph={{ rows: 0 }} />
        ) : (
          <h6
            className={classNames(
              {
                'single-info__pinding-orders': type === DELIVERIES_TYPE.PENDING
              },
              {
                'single-info__damaged-orders': type === DELIVERIES_TYPE.DAMAGED
              },
              'display-md'
            )}
          >
            {data}
            {exportFunction &&
              !dayjs(selectedDate).isBefore(initialDate, 'day') && (
                <DownloadIcon onClick={handleExportCardsData} />
              )}
          </h6>
        )}
      </div>
    </div>
  );
};
export default SingleInfoCard;
