export const convexHull = (points) => {
  points.sort((a, b) => (a.lat !== b.lat ? a.lat - b.lat : a.lng - b.lng));

  const n = points.length;
  const hull = [];

  for (let i = 0; i < 2 * n; i++) {
    const j = i < n ? i : 2 * n - 1 - i;
    while (
      hull.length >= 2 &&
      removeMiddle(hull[hull.length - 2], hull[hull.length - 1], points[j])
    )
      hull.pop();
    hull.push(points[j]);
  }

  hull.pop();

  return hull;
};

const removeMiddle = (a, b, c) => {
  const cross =
    (a.lat - b.lat) * (c.lng - b.lng) - (a.lng - b.lng) * (c.lat - b.lat);
  const dot =
    (a.lat - b.lat) * (c.lat - b.lat) + (a.lng - b.lng) * (c.lng - b.lng);
  return cross < 0 || (cross === 0 && dot <= 0);
};

const getAvg = (arr, sortBy) => {
  const sortedUniqueArr = [...new Set(arr.map((el) => el[sortBy]))].sort(
    (a, b) => a - b
  );
  const avg =
    (sortedUniqueArr[0] + sortedUniqueArr[sortedUniqueArr.length - 1]) / 2;

  return avg;
};

export const getCenter = (paths) => {
  return {
    lat: getAvg(paths, 'lat'),
    lng: getAvg(paths, 'lng')
  };
};
