import dayjs from 'dayjs';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { saveAs } from 'file-saver';

import { openModal } from 'utils/modal';
import { formatSignAndReturnType } from 'utils/deliveries';
import {
  isUserAuthorized,
  isDefaultBostaCourier,
  isVendor
} from 'utils/helpers';
import { MAP_DELIVERY_TYPES, PAYMENT_PROVIDERS } from 'constants/Deliveries';
import {
  SIGN_AND_RETURN_PICKUP,
  ORDER_TYPES_VALUE,
  DELIVERY_DETAILS_SECTIONS
} from 'constants/shipment-details';
import {
  PROMOTION_TYPES_MAPPING,
  PROMOTION_SUBTYPES_MAPPING,
  VALUE_TYPES
} from 'constants/promotions';
import {
  FINAL_STATS,
  FINAL_STATES_CODES,
  SOHO,
  MARKETPLACE,
  FILE_EXTENTIONS
} from 'constants/shipments';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { MAP_PACKAGE_SIZES } from 'constants/hubs';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { getMaxNumOfAttempts } from 'common/countries/countries-mapping';
import { ACL_MATRIX } from 'common/permissions';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';
import RateStarModal from 'components/Shipments/components/NewDeliveryDetails/components/RateStarModal/RateStarModal';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';

import CRPEmptyStateImage from 'assets/imgRevamp/CRPparcelImage.svg';
import { ReactComponent as Like } from 'assets/imgRevamp/like.svg';
import { ReactComponent as CCODPaymentIcon } from 'assets/imgRevamp/ccod-payment-type-icon.svg';
import { ReactComponent as DocumentIcon } from 'assets/bosta-icons/Document.svg';

export const formatStarNoteDate = (date) => {
  return dayjs(date).format('DD MMMM YYYY, h:mm a');
};

export const formatDate = (date, withTime = false) => {
  if (withTime) {
    return dayjs(date).format('ddd, DD MMM YYYY h:mm a');
  }
  return dayjs(date).isValid() ? dayjs(date).format('ddd, DD MMM YYYY') : date;
};

export const handleOpenRateStarModal = (delivery, onSumbit) => {
  if (!isUserAuthorized(aclMatrix.RATING_STARS)) {
    notify('You are not authorized to rate a star.');
    return;
  } else if (delivery.rate) {
    notify(
      'Star is already rated on this delivery, you can’t add another rating.'
    );
    return;
  }
  FINAL_STATES_CODES.includes(delivery.state.code)
    ? openModal(RateStarModal, { delivery, onSumbit })
    : [100, 101].includes(delivery?.state?.code)
    ? notify(`You cannot rate a star on a ${delivery?.state?.value} order.`)
    : notify(
        'Delivery is still in progress. You can only rate a star on an order in a final state.'
      );
};

export const getRowColumnsMapping = (delivery, sectionName) => {
  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  switch (type) {
    case ORDER_TYPES_VALUE.DELIVER:
      switch (sectionName) {
        case 'delivery_details':
          const defaultArray = [
            [8, 8, 8],
            ![
              MAP_PACKAGE_SIZES.LIGHT_BULKY,
              MAP_PACKAGE_SIZES.HEAVY_BULKY
            ].includes(delivery?.specs?.packageType)
              ? [8, 8, 8]
              : [8, 16],
            [8, 8, 8],
            [24],
            [24],
            ...(!isNaN(delivery?.prepaidAmount) || delivery.addressType
              ? [[8, 8]]
              : []),
            ...(delivery?.extraData?.lm_delivered_prf ? [[24]] : []),
            ...(delivery?.specs?.volumetricWeight ? [[8, 8]] : [])
          ];
          return delivery?.creationSrc?.includes(SOHO)
            ? [...defaultArray, [24]]
            : defaultArray;
        case 'operations_details':
          return [
            [8, 16],
            [8, 16]
          ];
        case 'pricing_details':
          return delivery?.internationalPricing
            ? [
                [8, 8, 8],
                [8, 8, 8]
              ]
            : [
                [8, 8, 8],
                [8, 8, 8],
                [8, 8, 8],
                [8, 8, 8]
              ];
        case 'promotion_details':
          return [[6, 6, 6, 6]];
        case 'pickup_details':
          return [
            [8, 16],
            [8, 8, 8],
            [8, 8, 8]
          ];
      }
      break;
    case ORDER_TYPES_VALUE.EXCHANGE:
      switch (sectionName) {
        case 'delivery_details':
          return [
            [8, 8, 8],
            ![
              MAP_PACKAGE_SIZES.LIGHT_BULKY,
              MAP_PACKAGE_SIZES.HEAVY_BULKY
            ].includes(delivery?.specs?.packageType)
              ? [8, 16]
              : [24],
            [8, 8, 8],
            [24],
            [24],
            ...(!isNaN(delivery?.prepaidAmount) || delivery.addressType
              ? [[8, 8]]
              : []),
            ...(delivery?.specs?.volumetricWeight ? [[8, 8]] : [])
          ];
        case 'operations_details':
          return [
            [8, 16],
            [8, 16]
          ];
        case 'pricing_details':
          return delivery?.internationalPricing
            ? [
                [8, 8, 8],
                [8, 8, 8]
              ]
            : [[8, 8, 8], [8, 8, 8], [8, 8, 8], [8]];
        case 'promotion_details':
          return [[6, 6, 6, 6]];
        case 'return_details':
          return [[8, 16], [8, 8, 8], [24]];
        case 'pickup_details':
          return [
            [8, 16],
            [8, 8, 8],
            [8, 8, 8]
          ];
      }
      break;
    case ORDER_TYPES_VALUE.CRP:
      switch (sectionName) {
        case 'delivery_details':
          return [
            [8, 8, 8],
            [8, 8, 8],
            [8, 8, 8],
            [24],
            [24],
            ...(!isNaN(delivery?.prepaidAmount) || delivery.addressType
              ? [[8, 8]]
              : []),
            ...(delivery?.specs?.volumetricWeight ? [[8, 8]] : [])
          ];
        case 'operations_details':
          return [
            [8, 16],
            [8, 16],
            [8, 16]
          ];
        case 'pricing_details':
          return delivery?.internationalPricing
            ? [
                [8, 8, 8],
                [8, 8, 8]
              ]
            : [[8, 8, 8], [8, 8, 8], [8, 8, 8], [8]];
        case 'promotion_details':
          return [[6, 6, 6, 6]];
        case 'return_details':
          return [[24], [24], [24]];
      }
      break;
    case ORDER_TYPES_VALUE.CASH_COLLECTION:
      switch (sectionName) {
        case 'delivery_details':
          return [
            [8, 8, 8],
            delivery?.ccod?.POSDelivery ? [8, 16] : [24],
            [8, 8, 8],
            ...(!isNaN(delivery?.prepaidAmount) || delivery.addressType
              ? [[8, 8]]
              : [])
          ];
        case 'operations_details':
          return [[8, 16], [24]];
        case 'pricing_details':
          return delivery?.internationalPricing
            ? [
                [8, 8, 8],
                [8, 8, 8]
              ]
            : [[8, 8, 8], [8, 8, 8], [8, 8, 8], [8]];
        case 'promotion_details':
          return [[6, 6, 6, 6]];
      }
      break;
    case ORDER_TYPES_VALUE.SIGN_AND_RETURN:
      switch (sectionName) {
        case 'delivery_details':
          return [
            [8, 8, 8],
            [8, 8, 8],
            [8, 8, 8],
            [24],
            [24],
            ...(!isNaN(delivery?.prepaidAmount) || delivery.addressType
              ? [[8, 8]]
              : [])
          ];
        case 'operations_details':
          return [
            [8, 8, 8],
            [8, 16],
            [8, 16]
          ];
        case 'pricing_details':
          return delivery?.internationalPricing
            ? [
                [8, 8, 8],
                [8, 8, 8]
              ]
            : [[8, 8, 8], [8, 8, 8], [8, 8, 8], [8]];
        case 'promotion_details':
          return [[6, 6, 6, 6]];
        case 'return_details':
          return [
            [8, 16],
            [8, 8, 8]
          ];
        case 'pickup_details':
          return [
            [8, 16],
            [8, 8, 8],
            [8, 8, 8]
          ];
      }
      break;
    default:
      return [[8, 8, 8], [8, 8, 8], [24], [24], [24]];
  }
};

export const handleCODFormat = (delivery) => {
  let cod = delivery?.cod?.amount;
  const internationalCurrency = delivery?.internationalPricing?.currency;
  if (cod) {
    if (cod < 0) {
      cod = cod * -1;
      return (
        <div className="br-delivery-details__COD-refund">
          <span>
            {fmt(
              {
                id: internationalCurrency
                  ? `common.${internationalCurrency}`
                  : COUNTRY_CURRENCY
              },
              { cod: cod }
            )}
          </span>
          <span className="br-delivery-details__COD-refund-tag">Refund</span>
        </div>
      );
    } else {
      return `${fmt(
        {
          id: internationalCurrency
            ? `common.${internationalCurrency}`
            : COUNTRY_CURRENCY
        },
        { cod: cod }
      )}`;
    }
  } else {
    return delivery?.paymentInfo?.amount ? (
      <span className="br-delivery-details__COD-paid-online">
        <p>
          {fmt(
            {
              id: internationalCurrency
                ? `common.${internationalCurrency}`
                : COUNTRY_CURRENCY
            },
            { cod: 0 }
          )}
        </p>
        <s>
          {fmt(
            {
              id: internationalCurrency
                ? `common.${internationalCurrency}`
                : COUNTRY_CURRENCY
            },
            { cod: delivery.paymentInfo.amount }
          )}
        </s>
      </span>
    ) : (
      fmt({ id: 'common.empty_field' })
    );
  }
};

export const getDeliveryAttempt = (
  attemptsCount,
  attemptsCountForBusiness = getMaxNumOfAttempts().DELIVERY
) => {
  return `${attemptsCount}/${attemptsCountForBusiness}`;
};

export const formatPackageSize = (delivery) => {
  if (
    delivery?.pricingPackageSize?.alias &&
    delivery?.pricingPackageSize?.alias !==
      delivery?.pricingPackageSize?.name &&
    delivery?.pricingPackageSize?.alias === 'XXL'
  ) {
    return `${delivery?.pricingPackageSize?.alias} (${delivery?.pricingPackageSize?.name})`;
  }

  return delivery?.pricingPackageSize?.name
    ? `${delivery?.pricingPackageSize.name}`
    : fmt({ id: 'common.empty_field' });
};

export const formatAddress = (address = {}) => {
  const addressArr = [];
  if (address.buildingNumber) {
    addressArr.push(address.buildingNumber);
  }

  if (address.firstLine) {
    addressArr.push(address.firstLine);
  }
  if (address.floor) {
    addressArr.push(`Floor: ${address.floor}`);
  }
  if (address.apartment) {
    addressArr.push(`Apart: ${address.apartment}`);
  }
  if (address.secondLine) {
    addressArr.push(address.secondLine);
  }

  if (address.district) {
    addressArr.push(address.district.name);
  }
  // if (address.zone) {
  //   addressArr.push(address.zone.name);
  // }
  if (address.city) {
    addressArr.push(address.city.name);
  }
  return addressArr.join(', ');
};

export const handleSignAndReturnCollectedAt = (
  delivery,
  returnCollectedAtOnly = false
) => {
  const collectedAt =
    delivery?.pickupRequestType === SIGN_AND_RETURN_PICKUP
      ? 'Other day'
      : 'Same day';
  const collectedAtDate =
    delivery?.pickupRequestType === SIGN_AND_RETURN_PICKUP
      ? formatDate(delivery?.scheduledAt)
      : null;
  if (returnCollectedAtOnly) {
    return collectedAt;
  }
  if (collectedAtDate) {
    return (
      <span className="br-delivery-details__collected-at-date-content">
        <span>{collectedAt}</span>{' '}
        <span className="br-delivery-details__collected-at-date">
          {collectedAtDate}
        </span>
      </span>
    );
  } else {
    return collectedAt;
  }
};

export const getRowColumnContentMapping = (
  sectionName,
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  switch (type) {
    case ORDER_TYPES_VALUE.DELIVER:
      return getRowColumnContentMappingSend(
        sectionName,
        delivery,
        stateColor,
        noOfAttempts,
        onSubmit
      );

    case ORDER_TYPES_VALUE.EXCHANGE:
      return getRowColumnContentMappingExchange(
        sectionName,
        delivery,
        stateColor,
        noOfAttempts,
        onSubmit
      );

    case ORDER_TYPES_VALUE.CRP:
      return getRowColumnContentMappingCRP(
        sectionName,
        delivery,
        stateColor,
        noOfAttempts,
        onSubmit
      );

    case ORDER_TYPES_VALUE.CASH_COLLECTION:
      return getRowColumnContentMappingCC(
        sectionName,
        delivery,
        stateColor,
        noOfAttempts,
        onSubmit
      );
    case ORDER_TYPES_VALUE.SIGN_AND_RETURN:
      return getRowColumnContentMappingSignAndReturn(
        sectionName,
        delivery,
        stateColor,
        noOfAttempts,
        onSubmit
      );
  }
};

const deliveryDetailsSection = (delivery, stateColor) => {
  const deliverySpecs =
    delivery?.type === ORDER_TYPES_VALUE.CRP
      ? delivery?.returnSpecs || delivery?.specs
      : delivery?.specs;

  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  const proofOfDeliveryUrl = delivery?.extraData?.lm_delivered_prf;

  return [
    [
      {
        name: 'Type',
        value:
          type === ORDER_TYPES_VALUE.SIGN_AND_RETURN ||
          delivery?.type === ORDER_TYPES_VALUE.RTO
            ? formatSignAndReturnType(delivery)
            : MAP_DELIVERY_TYPES[delivery?.type] || ''
      },
      {
        name: 'State',
        value: (
          <span
            className={classnames(
              'br-order-info-delivery-details__state',
              stateColor
            )}
          >
            {deliveryStateFormatter.getStateName(delivery)}
          </span>
        )
      },
      {
        name: 'COD',
        value: handleCODFormat(delivery)
      }
    ],
    type !== ORDER_TYPES_VALUE.CASH_COLLECTION
      ? [
          type !== ORDER_TYPES_VALUE.EXCHANGE
            ? {
                name: 'Package Type',
                value:
                  deliverySpecs?.packageType ||
                  fmt({ id: 'common.empty_field' })
              }
            : null,
          {
            name:
              type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
                ? 'Collected at'
                : 'No. of Items',
            value:
              type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
                ? handleSignAndReturnCollectedAt(delivery)
                : deliverySpecs?.packageDetails?.itemsCount ||
                  fmt({ id: 'common.empty_field' })
          },
          type !== ORDER_TYPES_VALUE.CRP
            ? type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
              ? {
                  name: 'No. of Items',
                  value:
                    deliverySpecs?.packageDetails?.itemsCount ||
                    fmt({ id: 'common.empty_field' })
                }
              : ![
                  MAP_PACKAGE_SIZES.LIGHT_BULKY,
                  MAP_PACKAGE_SIZES.HEAVY_BULKY
                ].includes(deliverySpecs?.packageType)
              ? {
                  name: 'Allow opening package',
                  value: delivery?.allowToOpenPackage ? 'Yes' : 'No'
                }
              : null
            : {
                name: 'Order Reference',
                value:
                  delivery?.businessReference ||
                  fmt({ id: 'common.empty_field' })
              },
          delivery?.paymentInfo?.method
            ? {
                name: fmt({ id: 'deliveries.details.payment_method' }),
                value: fmt(
                  { id: 'deliveries.details.paid_online_method' },
                  {
                    method:
                      PAYMENT_PROVIDERS[delivery?.paymentInfo?.method] ||
                      delivery?.paymentInfo?.method
                  }
                )
              }
            : null
        ]
      : null,
    type !== ORDER_TYPES_VALUE.CRP
      ? [
          {
            name: 'Order Reference',
            value:
              delivery?.businessReference || fmt({ id: 'common.empty_field' })
          },
          delivery?.paymentInfo?.method
            ? {
                name: fmt({ id: 'deliveries.details.payment_method' }),
                value: fmt(
                  { id: 'deliveries.details.paid_online_method' },
                  {
                    method:
                      PAYMENT_PROVIDERS[delivery?.paymentInfo?.method] ||
                      delivery?.paymentInfo?.method
                  }
                )
              }
            : null,
          delivery?.internationalTrackingNumber
            ? {
                name: 'International Tracking Number',
                value: delivery.internationalTrackingNumber
              }
            : null,
          delivery.multiPackages
            ? { name: 'No. of Packages', value: delivery.multiPackages }
            : null,
          delivery?.fulfillmentInfo?.saleOrderName
            ? {
                name: 'FXF SO Number',
                value:
                  delivery?.fulfillmentInfo?.saleOrderName ||
                  fmt({ id: 'common.empty_field' })
              }
            : null,
          delivery?.ccod?.POSDelivery
            ? {
                name: 'Payment Type',
                value: (
                  <span className="br-delivery-details__payment-type-text">
                    <CCODPaymentIcon />
                    CCOD
                  </span>
                )
              }
            : null,
          delivery?.creationSrc === MARKETPLACE
            ? {
                name: 'Creation Src',
                value: 'Sllr'
              }
            : null,
          delivery?.barcodeRef
            ? { name: 'Smart Sticker No.', value: delivery.barcodeRef }
            : null
        ]
      : null,
    type !== ORDER_TYPES_VALUE.CASH_COLLECTION
      ? [
          {
            name: 'Package Description',
            value:
              deliverySpecs?.packageDetails?.description ||
              fmt({ id: 'common.empty_field' })
          },
          type === ORDER_TYPES_VALUE.CRP && delivery?.multiPackages
            ? { name: 'No. of Packages', value: delivery.multiPackages }
            : null,
          type === ORDER_TYPES_VALUE.CRP && delivery?.ccod?.POSDelivery
            ? {
                name: 'Payment Type',
                value: (
                  <span className="br-delivery-details__payment-type-text">
                    <CCODPaymentIcon />
                    CCOD
                  </span>
                )
              }
            : null
        ]
      : null,
    [
      {
        name: 'Delivery Notes',
        value: delivery?.notes || fmt({ id: 'common.empty_field' })
      }
    ],
    type === ORDER_TYPES_VALUE.CRP
      ? [
          {
            name: 'Return Package',
            value:
              delivery?.shipmentDetailsBusinessView.crpImageUrl &&
              delivery?.shipmentDetailsBusinessView?.crpImageUrl !==
                fmt({ id: 'common.empty_field' }) ? (
                <img
                  onClick={() => {
                    if (delivery?.shipmentDetailsBusinessView?.crpImageUrl) {
                      openModal(BRPreviewImage, {
                        previewImage:
                          delivery?.shipmentDetailsBusinessView?.crpImageUrl
                      });
                    }
                  }}
                  src={delivery?.shipmentDetailsBusinessView?.crpImageUrl}
                />
              ) : (
                <div className="br-delivery-details__return-package-image">
                  <img src={CRPEmptyStateImage} />
                  <p>
                    This image will be filled as soon as the star picks up the
                    items from consignee.
                  </p>
                </div>
              )
          }
        ]
      : null,
    !isNaN(delivery.prepaidAmount) || delivery.addressType
      ? [
          ...(!isNaN(delivery.prepaidAmount)
            ? [
                {
                  name: 'Pre-paid Amount',
                  value: fmt(
                    {
                      id: COUNTRY_CURRENCY
                    },
                    { cod: delivery.prepaidAmount }
                  )
                }
              ]
            : []),
          ...(delivery.addressType
            ? [
                {
                  name: 'Address Type',
                  value: delivery.addressType
                }
              ]
            : [])
        ]
      : null,
    proofOfDeliveryUrl
      ? [
          {
            name: 'Proof of Delivery',
            value: (
              <img
                onClick={() => {
                  openModal(BRPreviewImage, {
                    previewImage: proofOfDeliveryUrl
                  });
                }}
                src={proofOfDeliveryUrl}
              />
            )
          }
        ]
      : null,
    delivery?.specs?.volumetricWeight
      ? [
          {
            name: 'Volumetric Weight',
            value: fmt(
              { id: 'international_orders.deliveries.delivery_details.weight' },
              {
                weight: delivery.specs.volumetricWeight
              }
            )
          },
          {
            name: 'Actual Weight',
            value: fmt(
              { id: 'international_orders.deliveries.delivery_details.weight' },
              {
                weight: delivery.specs.actualWeight
              }
            )
          }
        ]
      : null
  ];
};

const ItemValueSection = (delivery) => {
  const { amount, proofOfCostAttachmentUrl, proofOfCostAttachmentType } =
    delivery?.goodsInfo || {};
  const extension = proofOfCostAttachmentType?.split('/')[1];
  return [
    [
      {
        name: fmt({ id: 'deliveries.delivery_details.goods_value.value' }),
        value: amount
      }
    ],
    [
      {
        name: fmt({ id: 'deliveries.delivery_details.goods_value.proof' }),
        value: proofOfCostAttachmentUrl ? (
          <div className="br-deliveries__item-value-container">
            {extension === FILE_EXTENTIONS.PDF ? (
              <DocumentIcon />
            ) : (
              <img
                onClick={() => {
                  openModal(BRPreviewImage, {
                    previewImage: proofOfCostAttachmentUrl
                  });
                }}
                alt="proofOfCost"
                src={proofOfCostAttachmentUrl}
              />
            )}
            <BRButton
              type="basic"
              label={fmt({ id: 'common.download' })}
              onClick={() => {
                saveAs(proofOfCostAttachmentUrl, `proofOfCost.${extension}`);
              }}
            />
          </div>
        ) : (
          fmt({ id: 'common.empty_field' })
        )
      }
    ]
  ];
};

const customerDetailsSection = (delivery) => {
  const customerAddress = [
    ORDER_TYPES_VALUE.CRP,
    ORDER_TYPES_VALUE.CASH_COLLECTION
  ].includes(delivery?.type)
    ? delivery?.pickupAddress
    : delivery?.dropOffAddress;

  return [
    [
      {
        name: 'Customer Name',
        value: `${
          delivery?.receiver?.fullName && delivery?.receiver?.fullName !== '-'
            ? delivery?.receiver.fullName
            : `${delivery?.receiver?.firstName} ${delivery?.receiver?.lastName}`
        }`
      }
    ],
    [
      {
        name: 'Customer Address',
        value: customerAddress ? (
          <span className="br-order-info__customer-details-address">
            {formatAddress(customerAddress)}
          </span>
        ) : (
          fmt({ id: 'common.empty_field' })
        )
      }
    ],
    [
      {
        name: 'Working Address',
        value:
          delivery?.dropOffAddress?.isWorkAddress ||
          delivery?.pickupAddress?.isWorkAddress
            ? 'Yes'
            : 'No'
      }
    ],
    [
      {
        name: 'Phone',
        value: delivery?.receiver?.phone || fmt({ id: 'common.empty_field' })
      }
    ],
    [
      {
        name: 'Second Phone',
        value:
          delivery?.receiver?.secondPhone || fmt({ id: 'common.empty_field' })
      }
    ]
  ];
};

const pricingDetailsSection = (delivery) => {
  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  const pricing = delivery?.internationalPricing || delivery.pricing;
  const weight =
    delivery?.internationalPricing?.weight?.orderWeight ||
    delivery?.specs?.weight;

  return [
    [
      {
        name: 'Pricing Tier',
        value: delivery?.internationalPricing ? (
          <Link
            to={{
              pathname: `/pricing-plan`,
              search: `businessId=${delivery?.sender?._id}`
            }}
            target="_blank"
            className="br-pickup-request-link"
          >
            {pricing?.tier?.name}
          </Link>
        ) : delivery?.pricingTier?.name ? (
          <Link
            to={{
              pathname: `/pricing-plan`,
              search: `businessId=${delivery?.sender?._id}`
            }}
            target="_blank"
            className="br-pickup-request-link"
          >
            {delivery?.pricingTier?.name}
          </Link>
        ) : (
          fmt({ id: 'common.empty_field' })
        )
      },
      {
        name: 'Pricing With VAT',
        value: pricing?.priceAfterVat
          ? `${fmt(
              {
                id: `common.${pricing?.currency}` || COUNTRY_CURRENCY
              },
              {
                cod: pricing?.priceAfterVat?.toFixed(2)
              }
            )}`
          : fmt({ id: 'common.empty_field' })
      },
      {
        name: 'Pricing without VAT',
        value: pricing?.priceBeforeVat
          ? `${fmt(
              {
                id: `common.${pricing?.currency}` || COUNTRY_CURRENCY
              },
              {
                cod: pricing?.priceBeforeVat?.toFixed(2)
              }
            )}`
          : fmt({ id: 'common.empty_field' })
      }
    ],
    [
      type !== ORDER_TYPES_VALUE.CASH_COLLECTION
        ? {
            name: `Package ${pricing?.weight ? 'Weight' : 'Size'}`,
            value: pricing?.weight
              ? fmt(
                  {
                    id: 'common.weight_kg'
                  },
                  {
                    weight: weight
                  }
                )
              : formatPackageSize(delivery)
          }
        : null,
      delivery?.internationalPricing
        ? {
            name: 'Pickup County',
            value:
              pricing?.transitCost?.originCountryName ||
              fmt({ id: 'common.empty_field' })
          }
        : {
            name: 'Zero COD Discount',
            value:
              delivery?.pricing?.zeroCodDiscount?.amount ||
              fmt({ id: 'common.empty_field' })
          },
      delivery?.internationalPricing
        ? {
            name: 'Drop-Off County',
            value:
              pricing?.transitCost?.destinationCountryName ||
              fmt({ id: 'common.empty_field' })
          }
        : {
            name: 'Extra COD Fees',
            value:
              delivery?.pricing?.extraCodFee?.amount ||
              fmt({ id: 'common.empty_field' })
          }
    ],
    !delivery?.internationalPricing && [
      {
        name: 'Insurance Fees',
        value:
          delivery?.pricing?.insuranceFee?.amount ||
          fmt({ id: 'common.empty_field' })
      },
      {
        name: fmt({
          id: 'pricing.next_day_cod_fees'
        }),
        value:
          delivery?.pricing?.expediteFee?.amount ||
          fmt({ id: 'common.empty_field' })
      },
      {
        name: fmt({
          id: 'pricing.cod_fees_label'
        }),
        value:
          delivery?.pricing?.codFee?.amount || fmt({ id: 'common.empty_field' })
      }
    ],
    !delivery?.internationalPricing && [
      {
        name: fmt({
          id: 'pricing.pos_fee'
        }),
        value:
          delivery?.pricing?.posFee?.amount || fmt({ id: 'common.empty_field' })
      },
      {
        name: fmt({
          id: 'pricing.opening_package_fees'
        }),
        value: delivery?.pricing?.openingPackageFee?.amount
          ? `${fmt(
              {
                id: COUNTRY_CURRENCY
              },
              { cod: delivery?.pricing?.openingPackageFee?.amount?.toFixed(2) }
            )}`
          : fmt({ id: 'common.empty_field' })
      }
    ],
    type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
      ? [
          {
            name: 'Picked up from consignee',
            value: handleSignAndReturnCollectedAt(delivery, true)
          }
        ]
      : null
  ];
};

const promotionDetailsSection = ({ pricing: { promotion } }) => [
  [
    {
      name: 'Value',
      value:
        promotion.valueType === VALUE_TYPES[0].value
          ? `${(promotion.value * 100).toFixed(2)}%`
          : fmt(
              {
                id: COUNTRY_CURRENCY
              },
              { cod: promotion.value }
            )
    },
    {
      name: 'Discount Amount',
      value: fmt(
        {
          id: COUNTRY_CURRENCY
        },
        { cod: promotion.amount.toFixed(2) }
      )
    },
    {
      name: 'Promotion Type',
      value:
        PROMOTION_TYPES_MAPPING[promotion.type] ||
        fmt({ id: 'common.empty_field' })
    },
    {
      name: 'Promotion Subtype',
      value:
        PROMOTION_SUBTYPES_MAPPING[promotion.subType] ||
        fmt({ id: 'common.empty_field' })
    }
  ]
];

const currentLocationDetailsSection = (delivery, onSubmit) => {
  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  return [
    [
      {
        name: 'Current Hub',
        value:
          delivery?.state?.receivedAtWarehouse?.warehouse?.name ||
          fmt({ id: 'common.empty_field' })
      },
      {
        name: 'Location In Hub',
        value:
          delivery?.state?.receivedAtWarehouse?.location ||
          fmt({ id: 'common.empty_field' })
      }
    ],
    [
      {
        name: 'Route ID',
        value: delivery?.routeId ? (
          <a href={`/routes/${delivery.routeId}/edit`} target="_blank">
            {delivery.routeId}
          </a>
        ) : (
          fmt({ id: 'common.empty_field' })
        )
      },
      {
        name: 'Star Name',
        value: (
          <>
            <span>
              {delivery?.star?.name || fmt({ id: 'common.empty_field' })}
            </span>
            <div>
              <Button
                className="br-order-info-section_rate-star-action"
                onClick={() => handleOpenRateStarModal(delivery, onSubmit)}
              >
                <Like />
                Rate Star
              </Button>
            </div>
          </>
        )
      },
      {
        name: 'Star Phone',
        value: delivery?.star?.phone || fmt({ id: 'common.empty_field' })
      }
    ],
    [
      {
        name: 'Return Manifest ID',
        value: delivery?.returnGroupId ? (
          <a
            href={`/routes/${delivery.routeId}/return-manifest-details/${delivery.returnGroupId}`}
            target="_blank"
          >
            {delivery.returnGroupId}
          </a>
        ) : (
          fmt({ id: 'common.empty_field' })
        )
      }
    ]
  ];
};

const operationDetailsSection = (delivery, noOfAttempts) => {
  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  return [
    [
      {
        name: [
          ORDER_TYPES_VALUE.CRP,
          ORDER_TYPES_VALUE.CASH_COLLECTION
        ].includes(type)
          ? 'No, of Pickups Attempts'
          : 'No, of Delivery Attempts',
        value: getDeliveryAttempt(delivery?.attemptsCount, noOfAttempts)
      },
      [ORDER_TYPES_VALUE.SIGN_AND_RETURN].includes(type)
        ? {
            name: 'No, of Pickup Attempts',
            value: getDeliveryAttempt(delivery?.attemptsCount, noOfAttempts)
          }
        : null,
      [ORDER_TYPES_VALUE.CASH_COLLECTION].includes(type)
        ? {
            name: 'Collection Hub',
            value:
              delivery?.firstHub?.warehouse?.name ||
              delivery?.assignedHub?.name ||
              fmt({ id: 'common.empty_field' })
          }
        : {
            name: 'No, of Return Attempts',
            value: getDeliveryAttempt(
              delivery?.attemptsCount - delivery?.forwardAttemptsCount,
              noOfAttempts
            )
          }
    ],
    [
      ![ORDER_TYPES_VALUE.CASH_COLLECTION].includes(type)
        ? {
            name: 'Pickup Hub',
            value:
              delivery?.firstHub?.warehouse?.name ||
              delivery?.assignedHub?.name ||
              fmt({ id: 'common.empty_field' })
          }
        : null,
      {
        name: 'Drop-off Hub',
        value: delivery?.assignedHub?.name || fmt({ id: 'common.empty_field' })
      }
    ],
    [ORDER_TYPES_VALUE.CRP, ORDER_TYPES_VALUE.SIGN_AND_RETURN].includes(type)
      ? [
          {
            name:
              type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
                ? 'Sign and Return Pickup Request ID'
                : 'Pickup Request ID',
            value:
              type === ORDER_TYPES_VALUE.SIGN_AND_RETURN ? (
                delivery?.pickupRequestId &&
                delivery?.pickupRequestType === SIGN_AND_RETURN_PICKUP ? (
                  <a
                    href={`/pickups/${delivery?.pickupRequestId}`}
                    target="_blank"
                  >
                    {delivery?.pickupRequestId}
                  </a>
                ) : (
                  fmt({ id: 'common.empty_field' })
                )
              ) : delivery?.pickupRequestId ? (
                <a
                  href={`/pickups/${delivery?.pickupRequestId}`}
                  target="_blank"
                >
                  {delivery?.pickupRequestId}
                </a>
              ) : (
                fmt({ id: 'common.empty_field' })
              )
          },
          {
            name: 'Pickup Date',
            value: delivery?.scheduledAt
              ? formatDate(delivery?.scheduledAt)
              : fmt({ id: 'common.empty_field' })
          }
        ]
      : null
  ];
};

const hasReturnPackages = (delivery) => {
  return (
    delivery?.extraData?.starProofOfReturnedPackages ||
    delivery?.extraData?.businessProofOfReturnedPackages
  );
};

export const getRowColumnContentMappingSend = (
  sectionName,
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  switch (sectionName) {
    case 'delivery_details':
      return deliveryDetailsSection(delivery, stateColor).filter((n) => n);
    case 'operations_details':
      return operationDetailsSection(delivery, noOfAttempts).filter((n) => n);
    case 'second_operations_details':
      return currentLocationDetailsSection(delivery, onSubmit).filter((n) => n);
    case 'pricing_details':
      return pricingDetailsSection(delivery).filter((n) => n);
    case 'promotion_details':
      return promotionDetailsSection(delivery).filter((n) => n);
    case 'pickup_details':
      return [
        [
          {
            name: 'Pickup Request ID',
            value: delivery?.pickupRequestId ? (
              <a
                href={`/pickups/${delivery?.pickupRequestId}`}
                target="_blank"
                className="br-pickup-request-link"
              >
                {delivery?.pickupRequestId}
              </a>
            ) : (
              fmt({ id: 'common.empty_field' })
            )
          },
          {
            name: 'Pickup Date',
            value: delivery?.collectedFromBusiness
              ? formatDate(delivery?.collectedFromBusiness)
              : fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Location Name',
            value:
              (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
                delivery?.pickupAddress?.locationName) ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Pickup Address',
            value:
              isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
              delivery?.pickupAddress
                ? formatAddress(delivery?.pickupAddress)
                : fmt({ id: 'common.empty_field' })
          },
          {
            name: fmt({
              id: 'pickups.pickup_request.table_columns.fm_district_code'
            }),
            value:
              delivery?.pickupAddress?.district?.fmCode ||
              fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Hub',
            value:
              delivery?.firstHub?.warehouse?.name ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Name',
            value: delivery?.star?.name || fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Phone',
            value: delivery?.star?.phone || fmt({ id: 'common.empty_field' })
          }
        ]
      ];
    case 'customer_details':
      return customerDetailsSection(delivery).filter((n) => n);
    case 'item_value':
      return ItemValueSection(delivery).filter((n) => n);
  }
};

export const getRowColumnContentMappingExchange = (
  sectionName,
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  switch (sectionName) {
    case 'delivery_details':
      return deliveryDetailsSection(delivery, stateColor).filter((n) => n);
    case 'return_details':
      return [
        [
          {
            name: 'No. of Return Items',
            value:
              delivery?.returnSpecs?.packageDetails?.itemsCount ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Return Package Description',
            value:
              delivery?.returnSpecs?.packageDetails?.description ||
              fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Return Location Name',
            value:
              delivery?.returnAddress?.locationName ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Return Address',
            value: delivery?.returnAddress
              ? formatAddress(delivery?.returnAddress)
              : fmt({ id: 'common.empty_field' })
          },
          {
            name: fmt({
              id: 'pickups.pickup_request.table_columns.fm_district_code'
            }),
            value:
              delivery?.returnAddress?.district?.fmCode ||
              fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Return package image',
            value:
              delivery?.shipmentDetailsBusinessView.crpImageUrl &&
              delivery?.shipmentDetailsBusinessView?.crpImageUrl !==
                fmt({ id: 'common.empty_field' }) ? (
                <img
                  onClick={() => {
                    if (delivery?.shipmentDetailsBusinessView?.crpImageUrl) {
                      openModal(BRPreviewImage, {
                        previewImage:
                          delivery?.shipmentDetailsBusinessView?.crpImageUrl
                      });
                    }
                  }}
                  src={delivery?.shipmentDetailsBusinessView?.crpImageUrl}
                />
              ) : (
                <div className="br-delivery-details__return-package-image">
                  <img src={CRPEmptyStateImage} />
                  <p>
                    This image will be filled as soon as the star picks up the
                    items from consignee.
                  </p>
                </div>
              )
          }
        ]
      ];
    case 'operations_details':
      return operationDetailsSection(delivery, noOfAttempts).filter((n) => n);
    case 'second_operations_details':
      return currentLocationDetailsSection(delivery, onSubmit).filter((n) => n);
    case 'pricing_details':
      return pricingDetailsSection(delivery).filter((n) => n);
    case 'promotion_details':
      return promotionDetailsSection(delivery).filter((n) => n);
    case 'pickup_details':
      return [
        [
          {
            name: 'Pickup Request ID',
            value: delivery?.pickupRequestId ? (
              <a href={`/pickups/${delivery?.pickupRequestId}`} target="_blank">
                {delivery?.pickupRequestId}
              </a>
            ) : (
              fmt({ id: 'common.empty_field' })
            )
          },
          {
            name: 'Pickup Date:',
            value: delivery?.collectedFromBusiness
              ? formatDate(delivery?.collectedFromBusiness)
              : fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Location Name',
            value:
              (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
                delivery?.pickupAddress?.locationName) ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Pickup Address',
            value:
              isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
              delivery?.pickupAddress
                ? formatAddress(delivery?.pickupAddress)
                : fmt({ id: 'common.empty_field' })
          },
          {
            name: fmt({
              id: 'pickups.pickup_request.table_columns.fm_district_code'
            }),
            value:
              delivery?.pickupAddress?.district?.fmCode ||
              fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Hub',
            value:
              delivery?.firstHub?.warehouse?.name ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Name',
            value: delivery?.star?.name || fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Phone',
            value: delivery?.star?.phone || fmt({ id: 'common.empty_field' })
          }
        ]
      ];
    case 'customer_details':
      return customerDetailsSection(delivery).filter((n) => n);
    case 'item_value':
      return ItemValueSection(delivery).filter((n) => n);
  }
};

export const getRowColumnContentMappingCRP = (
  sectionName,
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  switch (sectionName) {
    case 'delivery_details':
      return deliveryDetailsSection(delivery, stateColor).filter((n) => n);
    case 'return_details':
      return [
        [
          {
            name: 'Return Location Name',
            value:
              delivery?.returnAddress || delivery?.dropOffAddress
                ? delivery?.returnAddress?.locationName ||
                  delivery?.dropOffAddress?.locationName
                : fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Return Address',
            value:
              delivery?.returnAddress || delivery?.dropOffAddress
                ? formatAddress(
                    delivery?.returnAddress || delivery?.dropOffAddress
                  )
                : fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: fmt({
              id: 'pickups.pickup_request.table_columns.fm_district_code'
            }),
            value:
              delivery?.returnAddress?.district?.fmCode ||
              delivery?.returnAddress?.district?.fmCode ||
              fmt({ id: 'common.empty_field' })
          }
        ]
      ];
    case 'operations_details':
      return operationDetailsSection(delivery, noOfAttempts).filter((n) => n);
    case 'second_operations_details':
      return currentLocationDetailsSection(delivery, onSubmit).filter((n) => n);
    case 'pricing_details':
      return pricingDetailsSection(delivery).filter((n) => n);
    case 'promotion_details':
      return promotionDetailsSection(delivery).filter((n) => n);
    case 'pickup_details':
      return [
        [
          {
            name: 'Pickup Request ID',
            value: delivery?.pickupRequestId ? (
              <a href={`/pickups/${delivery?.pickupRequestId}`} target="_blank">
                {delivery?.pickupRequestId}
              </a>
            ) : (
              fmt({ id: 'common.empty_field' })
            )
          },
          {
            name: 'Picked Up At',
            value: delivery?.collectedFromBusiness
              ? formatDate(delivery?.collectedFromBusiness)
              : fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Address',
            value:
              isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
              delivery?.pickupAddress
                ? formatAddress(delivery?.pickupAddress)
                : fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Pickup Hub',
            value:
              delivery?.firstHub?.warehouse?.name ||
              fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Star Name',
            value: delivery?.star?.name || fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Phone',
            value: delivery?.star?.phone || fmt({ id: 'common.empty_field' })
          }
        ]
      ];
    case 'customer_details':
      return customerDetailsSection(delivery).filter((n) => n);
    case 'item_value':
      return ItemValueSection(delivery).filter((n) => n);
  }
};

export const getRowColumnContentMappingCC = (
  sectionName,
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  switch (sectionName) {
    case 'delivery_details':
      return deliveryDetailsSection(delivery, stateColor).filter((n) => n);
    case 'operations_details':
      return operationDetailsSection(delivery, noOfAttempts).filter((n) => n);
    case 'second_operations_details':
      return currentLocationDetailsSection(delivery, onSubmit).filter((n) => n);
    case 'pricing_details':
      return pricingDetailsSection(delivery).filter((n) => n);
    case 'promotion_details':
      return promotionDetailsSection(delivery).filter((n) => n);
    case 'customer_details':
      return customerDetailsSection(delivery).filter((n) => n);
  }
};

export const getRowColumnContentMappingSignAndReturn = (
  sectionName,
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  switch (sectionName) {
    case 'delivery_details':
      return deliveryDetailsSection(delivery, stateColor).filter((n) => n);
    case 'return_details':
      return [
        [
          {
            name: 'Return To',
            value: delivery?.sender?.name || fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Phone Number',
            value: delivery?.sender?.phone || fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Return Location Name',
            value:
              delivery?.returnAddress?.locationName ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Return Address',
            value: delivery?.returnAddress
              ? formatAddress(delivery?.returnAddress)
              : fmt({ id: 'common.empty_field' })
          },
          {
            name: fmt({
              id: 'pickups.pickup_request.table_columns.fm_district_code'
            }),
            value:
              delivery?.returnAddress?.district?.fmCode ||
              fmt({ id: 'common.empty_field' })
          }
        ]
      ];
    case 'operations_details':
      return operationDetailsSection(delivery, noOfAttempts).filter((n) => n);
    case 'second_operations_details':
      return currentLocationDetailsSection(delivery, onSubmit).filter((n) => n);
    case 'pricing_details':
      return pricingDetailsSection(delivery).filter((n) => n);
    case 'promotion_details':
      return promotionDetailsSection(delivery).filter((n) => n);
    case 'pickup_details':
      return [
        [
          {
            name: 'Pickup Request ID',
            value:
              delivery?.pickupRequestId &&
              delivery?.pickupRequestType !== SIGN_AND_RETURN_PICKUP ? (
                <a
                  href={`/pickups/${delivery?.pickupRequestId}`}
                  target="_blank"
                >
                  {delivery?.pickupRequestId}
                </a>
              ) : (
                fmt({ id: 'common.empty_field' })
              )
          },
          {
            name: 'Pickup Date',
            value: delivery?.collectedFromBusiness
              ? formatDate(delivery?.collectedFromBusiness)
              : fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Location Name',
            value:
              (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
                delivery?.pickupAddress?.locationName) ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Pickup Address',
            value:
              isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
              delivery?.pickupAddress
                ? formatAddress(delivery?.pickupAddress)
                : fmt({ id: 'common.empty_field' })
          },
          {
            name: fmt({
              id: 'pickups.pickup_request.table_columns.fm_district_code'
            }),
            value:
              delivery?.pickupAddress?.district?.fmCode ||
              fmt({ id: 'common.empty_field' })
          }
        ],
        [
          {
            name: 'Pickup Hub',
            value:
              delivery?.firstHub?.warehouse?.name ||
              fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Name',
            value: delivery?.star?.name || fmt({ id: 'common.empty_field' })
          },
          {
            name: 'Star Phone',
            value: delivery?.star?.phone || fmt({ id: 'common.empty_field' })
          }
        ]
      ];
    case 'customer_details':
      return customerDetailsSection(delivery).filter((n) => n);
  }
};

export const infoSectionContentMapping = (
  delivery,
  stateColor,
  noOfAttempts,
  onSubmit
) => {
  const type =
    delivery?.type === ORDER_TYPES_VALUE.RTO
      ? delivery?.shipmentDetailsBusinessView?.type_before
        ? ORDER_TYPES_VALUE.FXF_SEND
          ? ORDER_TYPES_VALUE.DELIVER
          : delivery?.shipmentDetailsBusinessView?.type_before
        : delivery?.type
      : delivery?.type;

  return [
    {
      title: 'Delivery Details',
      id: 'delivery_details',
      rowColumnMapping: getRowColumnsMapping(delivery, 'delivery_details'),
      isEditable: true,
      content: getRowColumnContentMapping(
        'delivery_details',
        delivery,
        stateColor,
        noOfAttempts,
        onSubmit
      ),
      access: true
    },

    [
      ORDER_TYPES_VALUE.EXCHANGE,
      ORDER_TYPES_VALUE.CRP,
      ORDER_TYPES_VALUE.SIGN_AND_RETURN
    ].includes(type)
      ? {
          title:
            type === ORDER_TYPES_VALUE.EXCHANGE
              ? 'CRP Details'
              : 'Return Location Details',
          id: 'return_details',
          rowColumnMapping: getRowColumnsMapping(delivery, 'return_details'),
          isEditable: true,
          content:
            getRowColumnContentMapping(
              'return_details',
              delivery,
              stateColor,
              noOfAttempts,
              onSubmit
            ) || null,
          access: true
        }
      : null,

    {
      title: 'Operations Details',
      id: 'operation_details',
      rowColumnMapping: getRowColumnsMapping(delivery, 'operations_details'),
      isEditable: false,
      secondHeader: 'Current Location',
      secondRowColumnMapping: [[8, 16], [8, 8, 8], [8]],
      content:
        getRowColumnContentMapping(
          'operations_details',
          delivery,
          stateColor,
          noOfAttempts,
          onSubmit
        ) || null,
      secondSectionContent:
        getRowColumnContentMapping(
          'second_operations_details',
          delivery,
          stateColor,
          null,
          onSubmit
        ) || null,
      access: isUserAuthorized(aclMatrix.DELIVERIES_DETAILS)
    },

    ![ORDER_TYPES_VALUE.CASH_COLLECTION, ORDER_TYPES_VALUE.CRP].includes(type)
      ? {
          title: 'FM Pickup Details',
          id: 'pickup_details',
          rowColumnMapping: getRowColumnsMapping(delivery, 'pickup_details'),
          isEditable: false,
          content:
            getRowColumnContentMapping(
              'pickup_details',
              delivery,
              stateColor,
              noOfAttempts,
              onSubmit
            ) || null,
          access: isUserAuthorized(aclMatrix.DELIVERIES_DETAILS)
        }
      : null,
    {
      title: delivery.internationalPricing
        ? 'International Pricing'
        : 'Pricing',
      id: 'pricing_details',
      rowColumnMapping: getRowColumnsMapping(delivery, 'pricing_details'),
      isEditable: false,
      content:
        getRowColumnContentMapping(
          'pricing_details',
          delivery,
          stateColor,
          noOfAttempts,
          onSubmit
        ) || null,
      access: !isVendor()
    },
    delivery?.pricing?.promotion && {
      title: 'Promotion',
      id: 'promotion_details',
      rowColumnMapping: getRowColumnsMapping(delivery, 'promotion_details'),
      isEditable: false,
      content:
        getRowColumnContentMapping('promotion_details', delivery, stateColor) ||
        null,
      access: isUserAuthorized(aclMatrix.DELIVERIES_DETAILS)
    },
    hasReturnPackages(delivery) && {
      title: 'Delivery Images',
      id: 'delivery_images',
      isEditable: false,
      access: isUserAuthorized(aclMatrix.DELIVERIES_DETAILS)
    }
  ];
};

export const infoSectionContentMappingCol8 = (delivery, stateColor) => {
  return [
    {
      title: 'Customer Details',
      id: 'customer_details',
      rowColumnMapping: [[24], [24], [24], [24], [24]],
      isEditable: true,
      content:
        getRowColumnContentMapping('customer_details', delivery, stateColor) ||
        null,
      isSmallCol: true,
      fixedHeight: false
    },
    ...(delivery?.rating
      ? [
          {
            title: fmt({
              id: 'deliveries.delivery_details.delivery_rate.title'
            }),
            id: DELIVERY_DETAILS_SECTIONS.CONSIGNEE_RATE,
            isSmallCol: true,
            fixedHeight: true,
            className: 'br-delivery-details__consignee-rate'
          }
        ]
      : []),
    {
      title: 'Delivery Highlights',
      id: 'delivery_highlights',
      isSmallCol: true,
      fixedHeight: true
    },
    {
      title: 'Item Value',
      id: 'item_value',
      rowColumnMapping: [[24], [24]],
      content:
        getRowColumnContentMapping('item_value', delivery, stateColor) || null,
      isEditable: isUserAuthorized(aclMatrix.DELIVERIES_DETAILS, [
        ACL_MATRIX.UPDATE_GOODS_INFO
      ]),
      isSmallCol: true,
      fixedHeight: true
    },

    {
      title: 'Notes',
      id: 'notes',
      isSmallCol: true,
      fixedHeight: true
    },
    {
      title: 'Star Notes',
      id: 'star_notes',
      isSmallCol: true,
      fixedHeight: true
    }
  ];
};

export const formatSla = (delivery) => {
  let finalDate = new Date();
  const isFinalState = FINAL_STATS.includes(delivery?.state?.value);
  if (isFinalState) {
    finalDate =
      delivery?.state?.delivering?.time ||
      delivery.state?.lost?.time ||
      delivery?.state?.damaged?.time ||
      delivery?.state?.terminated ||
      delivery?.state?.returnedToBusiness;
    finalDate = new Date(finalDate);
  }
  const startDate = new Date(
    [ORDER_TYPES_VALUE.CRP, ORDER_TYPES_VALUE.CASH_COLLECTION].includes(
      delivery?.type
    )
      ? delivery?.createdAt
      : delivery?.collectedFromBusiness
  );

  const diffTime = Math.abs(finalDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays > 10) {
    return (
      <span>
        {diffDays || 0}/10 days{' '}
        <span className="br-order-header__exceeded-sla-tag">Exceeded SLA</span>
      </span>
    );
  }
  return `${diffDays || 0}/10 days`;
};

export const formatRole = (role) => {
  const roleArray = role.split('_');
  let formattedRole = '';
  roleArray.length &&
    roleArray.forEach((part) => {
      part = part.toLowerCase();
      part = part.charAt(0).toUpperCase() + part.slice(1);
      formattedRole += `${part} `;
    });

  return formattedRole ? formattedRole : role;
};

export const getOrderAge = (del) => {
  const {
    createdAt,
    changedToRTODate,
    collectedFromBusiness,
    collectedFromConsignee
  } = del;
  let orderDate = null;
  let tooltipId = null;

  if (changedToRTODate) {
    orderDate = changedToRTODate;
    tooltipId = 'changed_to_rto_date';
  } else if (collectedFromConsignee) {
    orderDate = collectedFromConsignee;
    tooltipId = 'received_at_warehouse_date_for_return';
  } else if (collectedFromBusiness) {
    orderDate = collectedFromBusiness;
    tooltipId = 'starting_from_pickup_date';
  } else if (createdAt) {
    orderDate = createdAt;
    tooltipId = 'creation_date';
  }

  const orderAge = orderDate
    ? dayjs().startOf('day').businessDiff(dayjs(orderDate).startOf('day'))
    : null;

  return {
    orderAge,
    ...(tooltipId && {
      tooltip: fmt({
        id: `hubs.packages_debrief.exceptions_tab.order_age_tooltips.${tooltipId}`
      })
    })
  };
};

export const isFulfillmentOrder = ({ type, oldType }) => {
  return [type, oldType].includes(ORDER_TYPES_VALUE.FXF_SEND);
};

export const isReturnOrder = ({ type, collectedFromConsignee }) => {
  return type === ORDER_TYPES_VALUE.RTO || collectedFromConsignee;
};
