import { fmt } from 'IntlWrapper/IntlWrapper';

export const TOGGLE_BUTTONS_LIST = [
  {
    title: fmt({ id: 'ops_control.pending_transit.toggle_bottons.all' }),
    value: 'All'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.toggle_bottons.forward' }),
    value: 'Forward'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.toggle_bottons.return' }),
    value: 'Return'
  }
];

export const HUBS_TABLE_COLUMNS = [
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.hub' }),
    dataIndex: 'hub'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.pending_from' }),
    dataIndex: 'pending_from',
    sorter: (a, b) => a.pending_from - b.pending_from
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.pending_to' }),
    dataIndex: 'pending_to',
    sorter: (a, b) => a.pending_to - b.pending_to
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.total_pending' }),
    dataIndex: 'total_pending',
    sorter: (a, b) => a.total_pending - b.total_pending
  },
  {
    title: fmt({
      id: 'ops_control.pending_transit.hubs_table.pending_from_plus'
    }),
    dataIndex: 'pending_from_plus',
    sorter: (a, b) => a.pending_from_plus - b.pending_from_plus
  },
  {
    title: fmt({
      id: 'ops_control.pending_transit.hubs_table.pending_to_plus'
    }),
    dataIndex: 'pending_to_plus',
    sorter: (a, b) => a.pending_to_plus - b.pending_to_plus
  },
  {
    title: fmt({
      id: 'ops_control.pending_transit.hubs_table.total_pending_plus'
    }),
    dataIndex: 'total_pending_plus',
    sorter: (a, b) => a.total_pending_plus - b.total_pending_plus
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.max_order_age' }),
    dataIndex: 'max_order_age',
    sorter: (a, b) => {
      const agingA =
        typeof a.max_order_age === 'number' ? a.max_order_age : -Infinity;
      const agingB =
        typeof b.max_order_age === 'number' ? b.max_order_age : -Infinity;
      return agingA - agingB;
    }
  }
];

export const TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES = (hubData) => {
  const {
    outgoing_forward_deliveries_not_exceeded_duration,
    outgoing_forward_deliveries_exceeded_duration
  } = hubData || {};
  return (
    outgoing_forward_deliveries_not_exceeded_duration +
      outgoing_forward_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES = (hubData) => {
  const {
    incoming_forward_deliveries_not_exceeded_duration,
    incoming_forward_deliveries_exceeded_duration
  } = hubData || {};
  return (
    incoming_forward_deliveries_not_exceeded_duration +
      incoming_forward_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_INCOMING_PENDING_RETURN_DELIVERIES = (hubData) => {
  const {
    incoming_return_deliveries_not_exceeded_duration,
    incoming_return_deliveries_exceeded_duration
  } = hubData || {};

  return (
    incoming_return_deliveries_not_exceeded_duration +
      incoming_return_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES = (hubData) => {
  const {
    outgoing_return_deliveries_not_exceeded_duration,
    outgoing_return_deliveries_exceeded_duration
  } = hubData || {};

  return (
    outgoing_return_deliveries_not_exceeded_duration +
      outgoing_return_deliveries_exceeded_duration || 0
  );
};

const getInvestgationValues = (hubData, toggleButtonValue) => {
  const {
    outgoing_forward_deliveries_investigation,
    outgoing_return_deliveries_investigation,
    incoming_forward_deliveries_investigation,
    incoming_return_deliveries_investigation,
    outgoing_forward_deliveries_exceeded_investigation,
    outgoing_return_deliveries_exceeded_investigation,
    incoming_forward_deliveries_exceeded_investigation,
    incoming_return_deliveries_exceeded_investigation
  } = hubData || {};

  const values = {
    investigation: 0,
    exceededInvestigation: 0,
    investigationFrom: 0,
    investigationTo: 0,
    exceededInvestigationFrom: 0,
    exceededInvestigationTo: 0
  };
  switch (toggleButtonValue) {
    case TOGGLE_BUTTONS_LIST[1].value:
      values.investigation =
        outgoing_forward_deliveries_investigation +
        incoming_forward_deliveries_investigation;
      values.exceededInvestigation =
        outgoing_forward_deliveries_exceeded_investigation +
        incoming_forward_deliveries_exceeded_investigation;
      values.investigationFrom = outgoing_forward_deliveries_investigation;
      values.investigationTo = incoming_forward_deliveries_investigation;
      values.exceededInvestigationFrom =
        outgoing_forward_deliveries_exceeded_investigation;
      values.exceededInvestigationTo =
        incoming_forward_deliveries_exceeded_investigation;
      break;
    case TOGGLE_BUTTONS_LIST[2].value:
      values.investigation =
        outgoing_return_deliveries_investigation +
        incoming_return_deliveries_investigation;
      values.exceededInvestigation =
        outgoing_return_deliveries_exceeded_investigation +
        incoming_return_deliveries_exceeded_investigation;
      values.investigationFrom = outgoing_return_deliveries_investigation;
      values.investigationTo = incoming_return_deliveries_investigation;
      values.exceededInvestigationFrom =
        outgoing_return_deliveries_exceeded_investigation;
      values.exceededInvestigationTo =
        incoming_return_deliveries_exceeded_investigation;
      break;
    default:
      values.investigation =
        outgoing_forward_deliveries_investigation +
        incoming_forward_deliveries_investigation +
        outgoing_return_deliveries_investigation +
        incoming_return_deliveries_investigation;
      values.exceededInvestigation =
        outgoing_forward_deliveries_exceeded_investigation +
        incoming_forward_deliveries_exceeded_investigation +
        outgoing_return_deliveries_exceeded_investigation +
        incoming_return_deliveries_exceeded_investigation;
      values.investigationFrom =
        outgoing_forward_deliveries_investigation +
        outgoing_return_deliveries_investigation;
      values.investigationTo =
        incoming_forward_deliveries_investigation +
        incoming_return_deliveries_investigation;
      values.exceededInvestigationFrom =
        outgoing_forward_deliveries_exceeded_investigation +
        outgoing_return_deliveries_exceeded_investigation;
      values.exceededInvestigationTo =
        incoming_forward_deliveries_exceeded_investigation +
        incoming_return_deliveries_exceeded_investigation;
  }
  return values;
};

export const TOTAL_PENDING_IN_TRANSIT = (
  hubData,
  toggleButtonValue,
  exportFunction
) => {
  const {
    exceededInvestigationFrom,
    exceededInvestigationTo,
    investigationFrom,
    investigationTo
  } = getInvestgationValues(hubData, toggleButtonValue);
  const transfferedFromSelectedHub =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData) +
        investigationFrom +
        exceededInvestigationFrom +
        hubData?.outgoing_forward_deliveries_exceeded_duration
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
      ? TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData) +
        investigationFrom +
        exceededInvestigationFrom +
        hubData?.outgoing_return_deliveries_exceeded_duration
      : TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData) +
        TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData) +
        investigationFrom +
        exceededInvestigationFrom +
        hubData?.outgoing_forward_deliveries_exceeded_duration +
        hubData?.outgoing_return_deliveries_exceeded_duration;

  const trasfferedToSelectedHub =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
        investigationTo +
        exceededInvestigationTo +
        hubData?.incoming_forward_deliveries_exceeded_duration
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
      ? TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData) +
        investigationTo +
        exceededInvestigationTo +
        hubData?.incoming_return_deliveries_exceeded_duration
      : TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
        TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData) +
        investigationTo +
        exceededInvestigationTo +
        hubData?.incoming_forward_deliveries_exceeded_duration +
        hubData?.incoming_return_deliveries_exceeded_duration;
  return [
    {
      title: fmt({ id: 'ops_control.hub_deliveries.transferred_from_hub' }),
      data: transfferedFromSelectedHub || 0,
      exportFunction,
      isTransferredFrom: true
    },
    {
      title: fmt({ id: 'ops_control.hub_deliveries.transferred_to_hub' }),
      data: trasfferedToSelectedHub || 0,
      exportFunction
    }
  ];
};

export const TOTAL_PENDING_IN_TRANSIT_PLUS = (
  hubData,
  toggleButtonValue,
  exportFunction
) => {
  const {
    outgoing_forward_deliveries_exceeded_duration,
    outgoing_return_deliveries_exceeded_duration,
    incoming_forward_deliveries_exceeded_duration,
    incoming_return_deliveries_exceeded_duration
  } = hubData || {};

  const transferredFromHubPlus =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? outgoing_forward_deliveries_exceeded_duration
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
      ? outgoing_return_deliveries_exceeded_duration
      : outgoing_forward_deliveries_exceeded_duration +
        outgoing_return_deliveries_exceeded_duration;

  const transferredToHubPlus =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? incoming_forward_deliveries_exceeded_duration
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? incoming_return_deliveries_exceeded_duration
      : incoming_forward_deliveries_exceeded_duration +
        incoming_return_deliveries_exceeded_duration;
  return [
    {
      title: fmt({
        id: 'ops_control.hub_deliveries.transferred_from_hub'
      }),
      data: transferredFromHubPlus || 0,
      exportFunction,
      isTransferredFrom: true,
      isTransitPlus: true
    },
    {
      title: fmt({
        id: 'ops_control.hub_deliveries.transferred_to_hub'
      }),
      data: transferredToHubPlus || 0,
      exportFunction,
      isTransitPlus: true
    }
  ];
};

export const ISSUES = (hubData, toggleButtonValue, exportFunction) => {
  const values = getInvestgationValues(hubData, toggleButtonValue);

  return [
    {
      title: fmt({
        id: 'ops_control.pending_transit.investigation'
      }),
      data: values.investigation,
      exportFunction,
      isInvestigation: true
    },
    {
      title: fmt({
        id: 'ops_control.pending_transit.exceeded_investigation'
      }),
      data: values.exceededInvestigation,
      exportFunction,
      isInvestigationPlus: true
    }
  ];
};

export const SUM_PENDING_IN_TRANSIT = (hubData, toggleButtonValue) => {
  const { investigation, exceededInvestigation } = getInvestgationValues(
    hubData,
    toggleButtonValue
  );
  return toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
    ? TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
        TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData) +
        investigation +
        exceededInvestigation +
        SUM_PENDING_IN_TRANSIT_PLUS(hubData, toggleButtonValue)
    : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
    ? TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData) +
      TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData) +
      investigation +
      exceededInvestigation +
      SUM_PENDING_IN_TRANSIT_PLUS(hubData, toggleButtonValue)
    : TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
      TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData) +
      TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData) +
      TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData) +
      investigation +
      exceededInvestigation +
      SUM_PENDING_IN_TRANSIT_PLUS(hubData, toggleButtonValue);
};

export const SUM_PENDING_IN_TRANSIT_PLUS = (hubData, toggleButtonValue) => {
  const {
    outgoing_forward_deliveries_exceeded_duration,
    outgoing_return_deliveries_exceeded_duration,
    incoming_forward_deliveries_exceeded_duration,
    incoming_return_deliveries_exceeded_duration
  } = hubData || {};

  const forwardData =
    outgoing_forward_deliveries_exceeded_duration +
    incoming_forward_deliveries_exceeded_duration;

  const returnData =
    outgoing_return_deliveries_exceeded_duration +
    incoming_return_deliveries_exceeded_duration;
  return toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
    ? forwardData
    : toggleButtonValue === TOGGLE_BUTTONS_LIST[2]
    ? returnData
    : forwardData + returnData || 0;
};

export const HUB_DELIVERIES_COLUMNS = [
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.tracking_number'
    }),
    dataIndex: 'tracking_number'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.transferred_from'
    }),
    dataIndex: 'trasferred_from'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.transferred_to'
    }),
    dataIndex: 'transferred_to'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.seal_number'
    }),
    dataIndex: 'seal_number'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.trasnfer_aging'
    }),
    dataIndex: 'transfer_aging'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.e2e_aging'
    }),
    dataIndex: 'e2e_aging'
  }
];

export const HUB_DELIVERIES_FILTERS = (
  hubs,
  transferredTo,
  transferredFrom
) => [
  {
    label: 'Transferred To',
    key: 'transferredTo',
    type: 'dropdown',
    menu: hubs,
    disabled: transferredFrom
  },
  {
    label: 'Transferred From',
    key: 'transferredFrom',
    type: 'dropdown',
    menu: hubs,
    disabled: transferredTo
  }
];

export const TOGGLE_BUTTON_INITAL_VALUE = 'All';

export const PENDING_TRANSIT_DATE_FORMAT = 'YYYY-MM-DD';
