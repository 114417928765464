import { fmt } from 'IntlWrapper/IntlWrapper';
import { allPendingSum } from 'utils/sorting-facility';
import { DELIVERY_CHILD_STATES, DELIVERY_STATES_CODES } from './shipments';
import { deliveryTypes } from 'containers/Deliveries/constants';
import { exportInTransitReport } from 'services/ops-control';
import { CAIRO_SORTING_FACILITY_ID } from './hubs';

export const TABLE_COLUMNS = [
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.tracking_number'
    }),
    dataIndex: 'tracking_number'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.business_name'
    }),
    dataIndex: 'business_name'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.order_type'
    }),
    dataIndex: 'order_type'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.order_state'
    }),
    dataIndex: 'order_state'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.picked_up'
    }),
    dataIndex: 'picked_up'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.last_receive'
    }),
    dataIndex: 'last_receive'
  },
  {
    title: fmt({
      id: 'ops_control.orders_table.columns.seal_number'
    }),
    dataIndex: 'seal_number'
  }
];

export const ORDER_STATES = {
  RECEIVED_AT_WAREHOUSE: 'Received At Warehouse',
  READY_TO_DISPATCH: 'Ready to Dispatch',
  LOST: 'Lost',
  DAMAGED: 'Damaged'
};

export const DELIVERY_RECIEVED_STATES = {
  NEW: 'New',
  TRANSFERRED: 'Transferred',
  RTO: 'RTO'
};

const ORDER_STATE_OPTIONS = [
  {
    label: fmt({
      id: 'ops_control.orders_table.filters.order_state_menu.received_label'
    }),
    value: `${ORDER_STATES.RECEIVED_AT_WAREHOUSE}`
  },
  {
    label: fmt({
      id: 'ops_control.orders_table.filters.order_state_menu.ready_to_dispatch_label'
    }),
    value: `${ORDER_STATES.READY_TO_DISPATCH}`
  }
];

export const SORTING_FACILITY_FILTERS = (businesses) => [
  {
    label: fmt({ id: 'ops_control.orders_table.filters.order_state' }),
    key: 'orderState',
    type: 'select',
    menu: ORDER_STATE_OPTIONS
  },
  {
    label: fmt({ id: 'ops_control.orders_table.filters.order_type' }),
    key: 'orderType',
    type: 'select',
    menu: deliveryTypes
  },
  {
    label: fmt({ id: 'ops_control.orders_table.filters.business' }),
    key: 'business',
    type: 'dropdown',
    menu: businesses
  }
];

export const SORTING_FACILITY_PENDING_DELIVERIES_COUNT = (
  sortingFacilityData,
  exportFunction
) => {
  const {
    pending_transfer_deliveries_count,
    ready_to_dispatch_deliveries_count
  } = sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.dispatching.all_pending' }),
      data: allPendingSum(sortingFacilityData),
      tooltip: fmt({ id: 'ops_control.sorting_facility.tooltips.all_pending' }),
      exportFunction,
      states: [
        ORDER_STATES.READY_TO_DISPATCH,
        ORDER_STATES.RECEIVED_AT_WAREHOUSE
      ]
    },
    {
      title: fmt({ id: 'ops_control.dispatching.pending_transfer' }),
      data: pending_transfer_deliveries_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.pending_transfer'
      }),
      exportFunction,
      states: [ORDER_STATES.RECEIVED_AT_WAREHOUSE]
    },
    {
      title: fmt({ id: 'ops_control.dispatching.pending_dispatching' }),
      data: ready_to_dispatch_deliveries_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.pending_dispatching'
      }),
      exportFunction,
      states: [ORDER_STATES.READY_TO_DISPATCH]
    }
  ];
};

export const SORTING_FACILITY_LOST_OR_DAMAGED_DELIVERIES_COUNT = (
  sortingFacilityData,
  exportFunction
) => {
  const { lost_deliveries_count, damaged_deliveries_count } =
    sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.dispatching.lost' }),
      data: lost_deliveries_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.lost'
      }),
      exportFunction,
      states: [ORDER_STATES.LOST]
    },
    {
      title: fmt({ id: 'ops_control.dispatching.damaged' }),
      data: damaged_deliveries_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.damaged'
      }),
      exportFunction,
      states: [ORDER_STATES.DAMAGED]
    }
  ];
};

export const DELIVERIES_TYPE = {
  PENDING: 'pending',
  DAMAGED: 'damaged'
};

export const TO_SORTING_FACILITY_CARDS_IFNO = (
  sortingFacilityData,
  exportFunction
) => {
  const { incoming_deliveries_count } = sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.sorting_facility.transferred_from_hubs' }),
      data: incoming_deliveries_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.transferred_from_hubs'
      }),
      exportFunction
    }
  ];
};

export const RECEIVED_AT_SF_CARDS_INFO = (
  sortingFacilityData,
  exportFunction
) => {
  const {
    fresh_deliveries_received_count,
    transferred_deliveries_received_count,
    rto_deliveries_received_count
  } = sortingFacilityData || {};
  return [
    {
      title: fmt({ id: 'ops_control.sorting_facility.fresh' }),
      data: fresh_deliveries_received_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.fresh'
      }),
      exportFunction,
      deliveryReceivedState: DELIVERY_RECIEVED_STATES.NEW
    },
    {
      title: fmt({ id: 'ops_control.sorting_facility.transferred' }),
      data: transferred_deliveries_received_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.transferred'
      }),
      exportFunction,
      deliveryReceivedState: DELIVERY_RECIEVED_STATES.TRANSFERRED
    },
    {
      title: fmt({ id: 'ops_control.sorting_facility.rto' }),
      data: rto_deliveries_received_count || 0,
      tooltip: fmt({
        id: 'ops_control.sorting_facility.tooltips.rto'
      }),
      exportFunction,
      deliveryReceivedState: DELIVERY_RECIEVED_STATES.RTO
    }
  ];
};

export const QUICK_FILTERS_VALUES = {
  ALL: 0,
  EXCEED_ONE_HOUR: 1,
  EXCEED_TWO_HOURS: 2,
  TWO_HOURS_PLUS: 3
};

export const DELIVERIES_TABLE_QUICK_FILTERS = [
  {
    label: fmt({ id: 'ops_control.sorting_facility.quickfilter.all' }),
    value: QUICK_FILTERS_VALUES.ALL
  },
  {
    label: fmt({
      id: 'ops_control.sorting_facility.quickfilter.exceed_one_hour'
    }),
    value: QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR
  },
  {
    label: fmt({
      id: 'ops_control.sorting_facility.quickfilter.exceed_two_hour'
    }),
    value: QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS
  },
  {
    label: fmt({
      id: 'ops_control.sorting_facility.quickfilter.two_hours_plus'
    }),
    value: QUICK_FILTERS_VALUES.TWO_HOURS_PLUS
  }
];

export const DATE_FORMAT = 'DD/MM/YYYY';

export const CURRENT_DATE_PARAMS = {
  SOTRING_FACILITY: 'sortingFacility',
  PENDING_TRANSIT: 'inTransit',
  HUB_DISPATCHING: 'hubDispatching'
};
