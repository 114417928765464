import * as yup from 'yup';

import { fmt } from 'IntlWrapper/IntlWrapper';

export const TABS_TYPES = {
  AMAZON: 'amazon',
  SOUQ: 'souq',
  REIMBURSEMENT: 'reimbursement'
};

export const AMAZON_TIPS_SHEET_URL =
  'https://storage.googleapis.com/bosta-fe/Admin-Dashboard/Amazon_Tips_Sheet.xlsx';

export const AMAZON_TIPS_HEADER_MAPPING = [
  'trackingNumber',
  'invoiceNumber',
  'invoiceDate',
  'currencyCode',
  'accountNumber',
  'billToName',
  'billToCompany',
  'billToAddress1',
  'billToAddress2',
  'billToCity',
  'remitToName',
  'remitToAddress',
  'remitToCityName',
  'remitToStateOrProvinceCode',
  'remitToPostalCode',
  'remitToCountryCode',
  'termsNetDueDate',
  'termsNetDays',
  'termsDiscountAmount',
  'valueAddedTaxAmount',
  'valueAddedTaxPercent',
  'creditInvoiceNumber',
  'creditInvoiceDate',
  'amazonVATId',
  'carrierVATId',
  'taxType',
  'packageTransportationCost',
  'extraCost',
  'netPackageTransportationCost',
  'extraCostDefinition'
];

export const AMAZON_TIPS_DATE_FIELDS = [
  'invoiceDate',
  'termsNetDueDate',
  'creditInvoiceDate'
];

export const AMAZON_TIPS_ERRORS_TABLE_COLUMNS = [
  {
    title: fmt({
      id: 'orders.mass_upload.error_table.columns.field_names'
    }),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: fmt({
      id: 'orders.mass_upload.error_table.columns.issue'
    }),
    dataIndex: 'issue',
    key: 'issue'
  },
  {
    title: fmt({
      id: 'orders.mass_upload.error_table.columns.in_rows'
    }),
    dataIndex: 'rows',
    key: 'rows'
  }
];

export const ordersSchema = yup.array().of(
  yup.object().shape({
    currencyCode: yup
      .string()
      .label('Currency Code')
      .required('Currency Code is required!'),
    accountNumber: yup
      .string()
      .label('Account Number')
      .required('Account Number is required!'),
    billToName: yup
      .string()
      .label('Bill to name')
      .required('Bill to name is required!'),
    billToCompany: yup
      .string()
      .label('Bill to company')
      .required('Bill to company is required!'),
    billToAddress1: yup
      .string()
      .label('Bill to Address 1')
      .required('Bill to Address 1 is required!'),
    billToAddress2: yup.string().label('Bill to Address 2').optional(),
    billToCity: yup
      .string()
      .label('Bill to city')
      .required('Bill to city is required!'),
    remitToName: yup
      .string()
      .label('Eemit to name')
      .required('Remit to name is required!'),
    remitToAddress: yup
      .string()
      .label('Remit to address')
      .required('Remit to addressr is required!'),
    remitToCityName: yup
      .string()
      .label('Remit to city name')
      .required('Remit to city name is required!'),
    remitToStateOrProvinceCode: yup
      .string()
      .label('Remit to State Or Province Code')
      .required('Remit to State Or Province Code is required!'),
    remitToPostalCode: yup
      .string()
      .label('Remit to postal code')
      .required('Remit to postal code is required!'),
    remitToCountryCode: yup
      .string()
      .label('Remit to country code')
      .required('Remit to country code is required!'),
    termsNetDueDate: yup
      .date()
      .label('Terms Net Due Date')
      .required('Terms Net Due Date is required!'),
    termsNetDays: yup
      .string()
      .label('Terms Net Days')
      .required('Terms Net Days is required!'),
    termsDiscountAmount: yup
      .string()
      .label('Terms Discount Amount')
      .required('Terms Discount Amount is required!'),
    valueAddedTaxAmount: yup
      .string()
      .label('Value Added Tax Amount')
      .required('Value Added Tax Amount is required!'),
    valueAddedTaxPercent: yup
      .string()
      .label('Value Added Tax Percent')
      .required('Value Added Tax Percent is required!'),
    creditInvoiceNumber: yup
      .string()
      .label('Credit Invoice Number')
      .optional(),
    creditInvoiceDate: yup.date().label('Credit Invoice Date').optional(),
    invoiceNumber: yup
      .string()
      .label('Invoice Number')
      .required('Invoice Number is required!'),
    trackingNumber: yup
      .string()
      .label('Tracking Number')
      .required('Tracking Number is required!'),
    amazonVATId: yup.string().label('Amazon VAT ID').optional(),
    carrierVATId: yup.string().label('Carrier VAT ID').optional(),
    taxType: yup.string().label('Tax Type').optional(),
    packageTransportationCost: yup
      .string()
      .label('Package Transportation Cost')
      .optional(),
    extraCost: yup.string().label('Extra Cost').optional(),
    netPackageTransportationCost: yup
      .string()
      .label('net Package Transportation Cost')
      .optional(),
    extraCostDefinition: yup.string().label('Extra Cost Definition').optional()
  })
);

export const AMAZON_TIPS_SHEET = 'AMAZON_TIPS_SHEET';
