import { Typography } from 'antd';
import { useIntl } from 'react-intl';

import DetailsGrid from 'components/Pickups/components/PickupDetails/components/DetailsGrid/DetailsGrid';

import { ReactComponent as RepetitionIcon } from 'assets/bosta-icons/repetition.svg';

export default function RepetitionInfoTable({ pickup }) {
  const intl = useIntl();
  const {
    repeatedData: { startDate, repeatedType, nextPickupDate, endDate, days }
  } = pickup;
  return (
    <DetailsGrid
      icon={<RepetitionIcon />}
      strokedIcon
      title={intl.formatMessage({
        id: 'pickups.pickup_details.repetition_info.title'
      })}
    >
      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          {intl.formatMessage({
            id: 'pickups.pickup_details.repetition_info.start_date'
          })}
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {startDate ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          {intl.formatMessage({
            id: 'pickups.pickup_details.repetition_info.repeated_type'
          })}
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {repeatedType ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          {intl.formatMessage({
            id: 'pickups.pickup_details.repetition_info.next_pickup_date'
          })}
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {nextPickupDate ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          {intl.formatMessage({
            id: 'pickups.pickup_details.repetition_info.end_date'
          })}
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {endDate ?? '-'}
        </Typography.Paragraph>
      </div>

      {days && (
        <div className="full-width">
          <Typography.Title
            level={5}
            className="br-pickup-details-content__title"
          >
            {intl.formatMessage({
              id: 'pickups.pickup_details.repetition_info.days'
            })}
          </Typography.Title>

          <Typography.Paragraph className="br-pickup-details-content__value">
            {days}
          </Typography.Paragraph>
        </div>
      )}
    </DetailsGrid>
  );
}
