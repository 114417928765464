import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import classnames from 'classnames';

import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as CircleClear } from 'assets/bosta-icons/Circle-clear.svg';

import './PickupValidation.less';

export default function PickupValidation({
  exception,
  className,
  showValidColor = false,
  useEmptyPlaceholder = true,
  ...props
}) {
  const intl = useIntl();

  const isFakeException = exception?.whatsAppVerification.fakeAttempt;
  const isValidException = exception?.whatsAppVerification.verified;

  const ValidityIcon = isValidException ? CircleCheck : CircleClear;
  const validityLabelId = isValidException
    ? 'pickups.pickup_request.search.valid'
    : 'pickups.pickup_request.search.fake';

  if (!isFakeException && !isValidException) {
    return useEmptyPlaceholder ? '-' : '';
  }

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'pickups.pickup_request.exception_validation_tooltip'
      })}
    >
      <div
        className={classnames('br-pickups__check', {
          'br-pickups__check-valid': isValidException,
          colored: showValidColor,
          [className]: !!className
        })}
        {...props}
      >
        <ValidityIcon />
        <span>{intl.formatMessage({ id: validityLabelId })}</span>
      </div>
    </Tooltip>
  );
}
