import classnames from 'classnames';
import Icon from '@ant-design/icons';
import React from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as emptyTable } from 'assets/imgRevamp/emptyTable.svg';

export default function EmptyData({ emptyViewSubtitle, className, ...props }) {
  const intl = useIntl();
  return (
    <div
      className={classnames('br-searchable-component__empty', {
        [className]: !!className
      })}
      {...props}
    >
      <Icon
        component={emptyTable}
        className="br-searchable-component__empty__icon"
      />
      <p className="br-searchable-component__empty__title">
        {intl.formatMessage({
          id: 'live_ops.empty_view_table_title'
        })}
      </p>
      <span className="br-searchable-component__empty__sub-title">
        {emptyViewSubtitle}
      </span>
    </div>
  );
}
