import { memo } from 'react';
import { injectIntl } from 'react-intl';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Polygon
} from '@react-google-maps/api';

import { STAR_MAP_DEFAULT_LOCATION } from 'constants/stars';
import { GOOGLE_MAP_API_KEY } from 'constants/dynamic-routing';

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '60%'
};

const defaultPolygonOptions = {
  fillColor: '#0098A5',
  fillOpacity: 0.4,
  strokeColor: '#0098A5',
  strokeOpacity: 0.8,
  strokeWeight: 2
};

const BRGoogleMap = ({
  markerPosition,
  intl,
  polygon,
  polygonOptions,
  center
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        center={center || markerPosition || STAR_MAP_DEFAULT_LOCATION}
        streetView={false}
        options={{
          streetViewControl: false,
          clickableIcons: false,
          mapTypeControl: false
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
        {!!polygon && (
          <Polygon
            paths={polygon}
            polygon={polygonOptions || defaultPolygonOptions}
          />
        )}
      </GoogleMap>
    );
  };

  return isLoaded ? (
    renderMap()
  ) : (
    <div>{intl.formatMessage({ id: 'common.map_failed_to_load' })}</div>
  );
};

export default injectIntl(memo(BRGoogleMap));
