import { Typography } from 'antd';

import { openModal } from 'utils/modal';

import BRTag from 'components/BRTag/BRTag';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';
import DetailsGrid from 'components/Pickups/components/PickupDetails/components/DetailsGrid/DetailsGrid';
import Rating from 'components/Pickups/components/PickupDetails/components/Rating/Rating';

import { ReactComponent as PickupIcon } from 'assets/bosta-icons/Pickups.svg';

import './PickupDetailsTable.less';

export default function PickupDetailsTable({ pickup }) {
  const {
    warehouse,
    business,
    routeId,
    star,
    signature,
    rating,
    pickupsCounter
  } = pickup;

  const hub = warehouse?.name ?? '-';
  const fmCode = business?.address?.district?.fmCode ?? '-';

  const starRating = rating?.starRating ?? 0;
  const starRatingComment = rating?.starRatingReasons.join(' - ') ?? '-';
  const serviceRating = rating?.serviceRating ?? 0;
  const serviceRatingComment = rating?.serviceComment ?? '-';

  return (
    <DetailsGrid icon={<PickupIcon />} title="Pickup Details (By Bosta)">
      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          #Picked-up Packages
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {pickupsCounter ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Hub
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {hub}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Route ID
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {routeId ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          FM District Code
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {fmCode}
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Star Info
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {star ? (
            <>
              {star.name}
              <Typography.Text>
                {star._id}
                <br />
                {star.phone}
              </Typography.Text>
            </>
          ) : (
            '-'
          )}
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Signature Photo
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {signature && signature !== 'N/A' ? (
            <img
              alt="Star Signature"
              onClick={() => {
                openModal(BRPreviewImage, {
                  previewImage: signature
                });
              }}
              className="br-pickup-details__signature-image"
              src={signature}
            />
          ) : (
            '-'
          )}
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Star Rating
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          <Rating rating={starRating} />
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Star Rating Comment
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {rating?.starRatingCategories && (
            <Typography.Paragraph>
              {rating.starRatingCategories.map((category) => (
                <BRTag key={category}>{category}</BRTag>
              ))}
            </Typography.Paragraph>
          )}
          {starRatingComment ?? '-'}
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Service Rating
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          <Rating rating={serviceRating} />
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Service Rating Comment
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {serviceRatingComment ?? '-'}
        </Typography.Paragraph>
      </div>
    </DetailsGrid>
  );
}
