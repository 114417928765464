import { useIntl } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  assignStarToRoute,
  deleteRoute,
  getManifestsRunSheetPrintCode,
  getRunSheetPrintCode
} from 'services/routes-form';
import { openModal } from 'utils/modal';
import { showBill } from 'utils';
import { ROUTE_STATES_LABEL } from 'constants/route';

import BRButton from 'components/BRButton/BRButton';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Actions } from 'assets/bosta-icons/More-Actions.svg';

const RoutesTableActions = ({
  _id,
  name,
  state,
  onlineStars,
  handleRefreshTable,
  setIsLoading
}) => {
  const intl = useIntl();

  const history = useHistory();

  const printRunSheet = async (routeId) => {
    setIsLoading(true);
    try {
      const { data } = await getRunSheetPrintCode(routeId);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const printManifestsRunSheet = async (routeId) => {
    setIsLoading(true);
    try {
      const { data } = await getManifestsRunSheetPrintCode(routeId);
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleDeleteRoute = async (routeId) => {
    setIsLoading(true);
    try {
      await deleteRoute({ routeId });
      notify(
        intl.formatMessage({
          id: 'routes.routes_listing.route_deleted_successfully'
        }),
        'success'
      );
      handleRefreshTable();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleAssignStarToRoute = async ({ routeId, starId }) => {
    try {
      const payload = {
        starId
      };
      await assignStarToRoute({ routeId, payload });
      notify(
        intl.formatMessage({
          id: 'routes.routes_listing.assign_star_modal.success_message'
        }),
        'success'
      );
      handleRefreshTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleDeleteRouteClick = (routeId) => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'routes.routes_listing.actions.delete_route'
      }),
      content: intl.formatMessage({
        id: 'routes.routes_listing.route_delete_confirmation'
      }),
      modalAction: () => handleDeleteRoute(routeId)
    });
  };

  const handleAssignStarClick = (routeId) => {
    openModal(BRDropdownModal, {
      title: intl.formatMessage({
        id: 'routes.routes_listing.assign_star_modal.title'
      }),
      subtitle: intl.formatMessage({
        id: 'routes.routes_listing.assign_star_modal.subtitle'
      }),
      confirmBtnText: intl.formatMessage({ id: 'common.confirm' }),
      dropdownData: onlineStars,
      onConfirm: (starId) => handleAssignStarToRoute({ routeId, starId })
    });
  };

  const handleEditRoute = (routeId) => {
    history.push(`/routes/${routeId}/edit`);
  };

  const actionsMenu = (item) => (
    <Menu>
      <Menu.Item onClick={() => printRunSheet(item._id)}>
        {intl.formatMessage({
          id: 'routes.routes_listing.actions.print_run_sheet'
        })}
      </Menu.Item>
      <Menu.Item onClick={() => printManifestsRunSheet(item._id)}>
        {intl.formatMessage({
          id: 'routes.routes_listing.actions.print_manifests'
        })}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleEditRoute(item._id);
        }}
      >
        {intl.formatMessage({
          id: 'routes.routes_listing.actions.edit_route'
        })}
      </Menu.Item>
      <Menu.Item onClick={() => handleAssignStarClick(item._id)}>
        {intl.formatMessage({
          id: 'routes.routes_listing.actions.assign_star'
        })}
      </Menu.Item>
      {[ROUTE_STATES_LABEL.UNASSIGNED, ROUTE_STATES_LABEL.REJECTED].includes(
        item.state
      ) && (
        <Menu.Item
          onClick={() => {
            handleDeleteRouteClick(item._id);
          }}
          className="br-delete-action"
        >
          {intl.formatMessage({
            id: 'routes.routes_listing.actions.delete_route'
          })}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={actionsMenu({ _id, name, state })} trigger={['click']}>
      <BRButton type="table-actions" suffixIcon={<Actions />} />
    </Dropdown>
  );
};

export default RoutesTableActions;
