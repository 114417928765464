import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { getHubs } from 'services/hubs';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import { notify } from 'components/Notify/Notify';
import StarLogs from './components/StarLogs/StarLogs';

const StarMap = ({ intl }) => {
  const [allHubs, setAllHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllHubs();
  }, []);

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      setAllHubs(result || []);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'sidebar.stars_map' })} />
      }
      content={
        <>
          <StarLogs
            hubs={allHubs}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </>
      }
    />
  );
};

export default injectIntl(StarMap);
