import { Typography } from 'antd';

import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';

import PickupState from 'components/Pickups/components/PickupState/PickupState';
import PickupValidation from 'components/Pickups/components/PickupValidation/PickupValidation';
import InfoCard from './components/InfoCard/InfoCard';

import { ReactComponent as PickupIcon } from 'assets/bosta-icons/Pickups.svg';

import './PickupSummary.less';

const PickupSummary = ({ pickup }) => {
  const { puid, business, exception, numberOfParcels, pickupsCounter } = pickup;

  const businessName = isDefaultBostaCourier([
    ...aclMatrix.THREE_PL,
    VENDOR_MANAGER
  ])
    ? business?.name
    : business?._id;

  return (
    <div className="br-pickup-summary">
      <PickupIcon className="br-pickup-summary__icon" />

      <div className="br-pickup-summary-info">
        <Typography.Title level={4} className="br-pickup-summary-info__title">
          Pickup ID: {puid}
        </Typography.Title>

        <PickupState item={pickup} />

        <PickupValidation
          exception={exception}
          showValidColor={true}
          useEmptyPlaceholder={false}
        />

        <Typography.Text className="br-pickup-summary-info__business-name">
          {businessName}
        </Typography.Text>
      </div>

      <InfoCard title="Business Requested" value={numberOfParcels} />
      <InfoCard title="Star Received" value={pickupsCounter} />
      {/*<InfoCard title="Hub Received" value={'TBA'} />*/}
    </div>
  );
};

export default PickupSummary;
