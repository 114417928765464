import { useEffect } from 'react';

const useKeyboardInput = (key, callback) => {
  const lowerCaseKey = key.toLowerCase();

  useEffect(() => {
    const handler = (evt) => {
      if (evt.key.toString().toLowerCase() !== lowerCaseKey) {
        return;
      }
      evt.preventDefault();
      callback?.(evt);
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [lowerCaseKey]);
};

export default useKeyboardInput;
