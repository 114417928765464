import React, { useState } from 'react';
import { Modal, Form, Switch, Divider, Input } from 'antd';
import { useIntl } from 'react-intl';

import { editMaterial } from 'services/packaging';

import { notify } from 'components/Notify/Notify';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import {
  getCurrency,
  isPriceColumnInPackagingMaterialsVailable
} from 'common/countries/countries-mapping';

import './EditMaterialModal.less';

const EditMaterialModal = ({
  material,
  close,
  setIsLoading,
  onSuccess,
  ...props
}) => {
  const [values, setValues] = useState({
    quantity: material?.quantity || '0',
    price: material?.price,
    status: material?.status,
    outOfStock: material?.outOfStock,
    description: ''
  });

  const intl = useIntl();

  const handleOnChange = (name, ...rest) => {
    setValues((prev) => ({ ...prev, [name]: rest[0][1] }));
  };

  const outOfStockSwitch = (checked) => {
    setValues((prev) => ({
      ...prev,
      outOfStock: checked
    }));
  };

  const onSwitchChange = (checked) => {
    setValues((prev) => ({
      ...prev,
      status: checked
    }));
  };

  const handleOnDescriptionChange = (e) => {
    setValues({
      ...values,
      description: e.target?.value
    });
  };

  const handleOnOk = async () => {
    try {
      setIsLoading(true);
      const {
        quantity: total_balance,
        price,
        status: avalible,
        outOfStock: out_of_stock,
        description
      } = values;
      const payload = {
        price,
        avalible: +avalible,
        out_of_stock: +out_of_stock,
        ...(total_balance && { total_balance }),
        ...(description && { description })
      };
      await editMaterial({ id: material.id, payload });
      close();
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={`Edit ${material?.material?.name}`}
      onCancel={close}
      okText={intl.formatMessage({ id: 'common.confirm' })}
      onOk={handleOnOk}
      {...props}
    >
      <div className="br-edit-material-modal__content">
        <div className="br-edit-material-modal__inputs-container">
          <Form.Item
            label={intl.formatMessage({
              id: 'packaging.manage_materials.table.quantity'
            })}
          >
            <BRFormInputNumber
              handleOnChangeForm={(...props) =>
                handleOnChange('quantity', props)
              }
              name="quantity"
              value={values.quantity}
              acceptNegative={true}
            />
          </Form.Item>
          {isPriceColumnInPackagingMaterialsVailable() && !material?.free && (
            <Form.Item
              label={intl.formatMessage({
                id: 'packaging.manage_materials.table.price'
              })}
            >
              <BRFormInputNumber
                handleOnChangeForm={(...props) =>
                  handleOnChange('price', props)
                }
                name="price"
                value={values.price}
                suffix={getCurrency().type}
                acceptFloat
              />
            </Form.Item>
          )}
        </div>

        <div className="br-edit-material-modal__switch">
          <div className="br-edit-material-modal__switch-container">
            <Switch
              checked={values.outOfStock}
              onChange={outOfStockSwitch}
              size="small"
            />
            <span className="body-medium">
              {intl.formatMessage({
                id: 'packaging.manage_materials.table.out_of_stock'
              })}
            </span>
          </div>

          <div className="br-edit-material-modal__switch-container">
            <Switch
              checked={values.status}
              onChange={onSwitchChange}
              size="small"
            />
            <span className="body-medium">
              {intl.formatMessage({
                id: 'packaging.manage_materials.table.enable'
              })}
            </span>
          </div>
        </div>
        <Divider />
        <Form.Item
          label={intl.formatMessage({
            id: 'packaging.manage_materials.description'
          })}
        >
          <Input.TextArea
            placeholder={intl.formatMessage({
              id: 'packaging.manage_materials.description_placeholder'
            })}
            autoSize={{ minRows: 3, maxRows: 3 }}
            onChange={handleOnDescriptionChange}
          />
        </Form.Item>
      </div>
    </Modal>
  );
};

export default EditMaterialModal;
