import { Skeleton } from 'antd';

import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';

import './GeneralInfoCard.less';
const GeneralInfoCard = ({
  Icon,
  title,
  total,
  cardsData,
  loading,
  selectedDate,
  initalDate
}) => {
  return (
    <div className="general-info__container">
      <div className="general-info__header">
        {Icon && <Icon />}
        <h3 className="display-xs general-info__title">{title}</h3>
        {total &&
          (loading ? (
            <Skeleton active paragraph={{ rows: 0 }} />
          ) : (
            <h3 className="general-info__total display-xs">{total}</h3>
          ))}
      </div>
      <div className="general-info__data-container">
        {cardsData.map((card) => {
          return (
            <SingleInfoCard
              tooltip={card.tooltip}
              title={card.title}
              data={card.data}
              loading={loading}
              exportFunction={card.exportFunction}
              selectedDate={selectedDate}
              deliveryReceivedState={card?.deliveryReceivedState}
              states={card?.states}
              isTransferredFrom={card?.isTransferredFrom}
              isTransitPlus={card?.isTransitPlus}
              isInvestigation={card?.isInvestigation}
              isInvestigationPlus={card?.isInvestigationPlus}
              initalDate={initalDate}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GeneralInfoCard;
