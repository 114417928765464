import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { CheckCircleFilled } from '@ant-design/icons';

import { fmt } from 'IntlWrapper/IntlWrapper';
import { formatAddress } from 'utils';
import {
  seperateNumberDigits,
  capitalizeFirstLetter,
  formatBigMoneyValues
} from 'utils/helpers';
import { renderStarNationalIdImages } from 'utils/stars';
import { COUNTRIES } from 'constants/country-data';
import {
  isFawryOptionAvailable,
  isPosOptionAvailable,
  isValuOptionAvailable,
  isNationalIdRequiredForStars
} from 'common/countries/countries-mapping';
import { IS_SAUDI } from 'constants/helper';

import { ReactComponent as moneys } from 'assets/imgRevamp/moneys.svg';
import { ReactComponent as box } from 'assets/imgRevamp/box.svg';
import { ReactComponent as RTOStops } from 'assets/imgRevamp/RTOStops.svg';
import { ReactComponent as repeatCircle } from 'assets/imgRevamp/repeat-circle.svg';

export const FLEET_TYPES = [
  {
    label: fmt({
      id: 'stars.create_edit_star.fleet_types.first_mile'
    }),
    value: 'First Mile'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.fleet_types.middle_mile'
    }),
    value: 'Middle Mile'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.fleet_types.last_mile'
    }),
    value: 'Last Mile'
  }
];

export const VEHICLE_TYPES = [
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.sedan'
    }),
    value: 'Sedan'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.bike'
    }),
    value: 'Bike'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.tricycle'
    }),
    value: 'Tricycle'
  }
];

export const SALARY_TYPES = [
  {
    label: fmt({
      id: 'stars.create_edit_star.salary_type.package_variable.title'
    }),
    sublabel: fmt({
      id: 'stars.create_edit_star.salary_type.package_variable.subtitle'
    }),
    helpSentenceFor: 'package_variable',
    salaryConfigKey: 'variable',
    value: 'Package Variable'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.salary_type.fixed_per_day.title'
    }),
    sublabel: fmt({
      id: 'stars.create_edit_star.salary_type.fixed_per_day.subtitle'
    }),
    helpSentenceFor: 'fixed_per_day',
    salaryConfigKey: 'fixedPerDay',
    value: 'Fixed Per Day'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.salary_type.guaranteed_salary.title'
    }),
    sublabel: fmt({
      id: 'stars.create_edit_star.salary_type.guaranteed_salary.subtitle'
    }),
    helpSentenceFor: 'guaranteed_salary',
    salaryConfigKey: 'guaranteePerDay',
    value: 'Guaranteed Salary'
  }
];

export const PAGE_LIMIT = 10;

export const FIRST_PAGE = 1;

export const CYCLE_STATES = {
  CLOSED: 'closed',
  OPENED: 'opened',
  IN_REVIEW: 'in review'
};

export const STAR_INFO_CELLS = [
  {
    key: 'starId',
    label: 'Star ID'
  },
  {
    key: 'phone',
    label: 'Phone'
  },
  ...(isFawryOptionAvailable()
    ? [
        {
          key: 'fawryUserId',
          label: 'Fawry ID'
        }
      ]
    : []),
  ...(isNationalIdRequiredForStars()
    ? [
        {
          key: 'nationalImages',
          label: 'National ID',
          render: (nationalImages) =>
            renderStarNationalIdImages(nationalImages) ||
            fmt({ id: 'common.empty_field' })
        },
        {
          key: 'nationalIdNumber',
          label: 'National Number'
        }
      ]
    : []),
  {
    key: 'isVerified',
    label: 'Status',
    render: (isVerified) => (isVerified ? 'Active' : 'Inactive')
  },
  {
    key: 'warehouseInfo',
    label: 'Hub',
    render: (warehouseInfo) => warehouseInfo?.name || 'N/A'
  },
  {
    key: 'warehouseInfo',
    label: 'Zone',
    render: (warehouseInfo, record) =>
      record?.salaryConfig?.salaryInfo?.zoneName
        ? record?.salaryConfig?.salaryInfo?.zoneName
        : warehouseInfo?.address?.zone?.name || 'N/A'
  },
  {
    key: 'email',
    label: 'Email',
    colSpan: 2
  },
  {
    key: 'fleetType',
    label: 'Fleet Type'
  },
  {
    key: 'currentVehicle',
    label: 'Vehicle Type'
  },
  {
    key: 'vehicles',
    label: 'Vehicle Description',
    render: (vehicles) => {
      return vehicles?.length
        ? `${vehicles[0]?.color || ''} ${vehicles[0]?.make || ''}  - License: ${
            vehicles[0]?.licencePlate || ''
          }
          ${vehicles[0]?.model || ''}`
        : 'N/A';
    }
  },
  {
    key: 'contractor',
    label: 'Contractor',
    render: (contractor) => contractor?.contractorName || 'N/A'
  },
  {
    key: 'address',
    label: 'Address',
    render: (address) => {
      return formatAddress(address) || 'N/A';
    }
  },
  ...(isValuOptionAvailable()
    ? [
        {
          key: 'valuQRCodeLink',
          label: fmt({
            id: 'stars.create_edit_star.form_labels.valu_payment_link'
          }),
          render: (valuQRCodeLink) => {
            return valuQRCodeLink ? (
              <a>{valuQRCodeLink}</a>
            ) : (
              fmt({ id: 'common.empty_field' })
            );
          }
        }
      ]
    : []),
  ...(isPosOptionAvailable()
    ? [
        {
          key: 'posId',
          label: 'POS Id'
        }
      ]
    : [])
];

export const getStateColor = (state) => {
  switch (state.toLowerCase()) {
    case CYCLE_STATES.OPENED:
      return 'ant-tag-green';
    case CYCLE_STATES.CLOSED:
      return 'ant-tag-red';
    case CYCLE_STATES.IN_REVIEW:
      return 'ant-tag-orange';
    default:
      return 'ant-tag-green';
  }
};

export const STAR_CYCLES_COLUMNS = [
  {
    title: fmt({
      id: `star_cycles.tables.columns.cycle_id`
    }),
    dataIndex: 'cycle_id',
    render: (cycleId, record) => (
      <Link
        to={{
          pathname: `/stars/star-cycles/${cycleId}`,
          state: {
            cycleState: record?.state
          }
        }}
      >
        {cycleId}
      </Link>
    )
  },
  {
    title: fmt({
      id: `star_cycles.start_date`
    }),
    dataIndex: 'start_date',
    render: (startDate) =>
      startDate !== 'N/A'
        ? dayjs.utc(startDate).tz().format('ddd, D MMM YYYY')
        : 'N/A'
  },
  {
    title: fmt({
      id: `star_cycles.end_date`
    }),
    dataIndex: 'end_date',
    render: (endDate, record) =>
      endDate !== 'N/A' && record?.state?.toLowerCase() !== CYCLE_STATES.OPENED
        ? dayjs.utc(endDate).tz().format('ddd, D MMM YYYY')
        : 'N/A'
  },
  {
    title: fmt({
      id: `star_cycles.accumulated_total_salary`
    }),
    dataIndex: 'total_salary',
    render: (totalSalary) =>
      `${formatBigMoneyValues(totalSalary)} ${
        JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
        COUNTRIES[1].codeName
          ? 'SAR'
          : 'EGP'
      }`
  },
  {
    title: fmt({
      id: `star_cycles.accumulated_total_salary_cc`
    }),
    dataIndex: 'total_salary_cc',
    render: (totalSalaryCC) =>
      `${formatBigMoneyValues(totalSalaryCC)} ${
        JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
        COUNTRIES[1].codeName
          ? 'SAR'
          : 'EGP'
      }`
  },
  {
    title: fmt({
      id: `star_cycles.accumulated_total_deliveries`
    }),
    dataIndex: 'total_deliveries',
    render: (totalDeliveries) => seperateNumberDigits(totalDeliveries)
  },
  {
    title: fmt({
      id: `star_cycles.accumulated_total_pickup_stops`
    }),
    dataIndex: 'total_pickup_stops',
    render: (totalPickups) => seperateNumberDigits(totalPickups)
  },
  {
    title: fmt({
      id: `star_cycles.accumulated_total_rto_stops`
    }),
    dataIndex: 'total_rto_stops',
    render: (totalRTOStops) => seperateNumberDigits(totalRTOStops)
  },
  {
    title: fmt({
      id: `star_cycles.state`
    }),
    dataIndex: 'state',
    render: (state) => (
      <Tag className={getStateColor(state)}>{capitalizeFirstLetter(state)}</Tag>
    )
  }
];

export const STAR_SUMMARY_CARDS = [
  {
    label: 'accumulated_total_salary',
    icon: moneys,
    suffix:
      JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
      COUNTRIES[1].codeName
        ? 'SAR'
        : 'EGP'
  },
  {
    label: 'accumulated_total_salary_cc',
    icon: moneys,
    suffix:
      JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
      COUNTRIES[1].codeName
        ? 'SAR'
        : 'EGP'
  },
  {
    label: 'accumulated_total_deliveries',
    icon: box
  },
  {
    label: 'accumulated_total_pickup_stops',
    icon: repeatCircle
  },
  {
    label: 'accumulated_total_rto_stops',
    icon: RTOStops
  }
];

export const STAR_CYCLE_DETAILS_COLUMNS = [
  {
    title: fmt({
      id: `star_cycles.tables.columns.id`
    }),
    dataIndex: 'star_id'
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.star_name`
    }),
    dataIndex: 'star_name'
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.contractor`
    }),
    dataIndex: 'contractor_name'
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.hub`
    }),
    dataIndex: 'hub_name'
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.zone`
    }),
    dataIndex: 'zone'
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.vehicle_type`
    }),
    dataIndex: 'vehicle_type'
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.working_days`
    }),
    dataIndex: 'working_days'
  },
  ,
  {
    title: fmt({
      id: `star_cycles.tables.columns.deliveries`
    }),
    dataIndex: 'deliveries',
    render: (deliveries) => seperateNumberDigits(deliveries) || 0
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.rto_stops`
    }),
    dataIndex: 'rto_stops',
    render: (totalRTOStops) => seperateNumberDigits(totalRTOStops) || 0
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.pickup_stops`
    }),
    dataIndex: 'pickup_stops',
    render: (totalPickups) => seperateNumberDigits(totalPickups) || 0
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.fixed_per_day`
    }),
    dataIndex: 'fixed_per_day',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  ,
  {
    title: fmt({
      id: `star_cycles.tables.columns.package_variable`
    }),
    dataIndex: 'package_variable',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.guarantee_per_day`
    }),
    dataIndex: 'guarantee_per_day',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_fixed`
    }),
    dataIndex: 'total_fixed',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_package_variable`
    }),
    dataIndex: 'total_package_variable',
    render: (value) =>
      `${seperateNumberDigits(value || 0)} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_pickups_variable`
    }),
    dataIndex: 'total_pickups_variable',
    render: (value) =>
      `${seperateNumberDigits(value || 0)} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_variable`
    }),
    dataIndex: 'total_variable',
    render: (value) =>
      `${seperateNumberDigits(value || 0)} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_guarantee`
    }),
    dataIndex: 'total_guarantee',
    render: (value) =>
      `${seperateNumberDigits(value || 0)} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.fake_exceptions_deductions`
    }),
    dataIndex: 'fake_exceptions_deductions',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.ops_deductions`
    }),
    dataIndex: 'ops_deductions',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.fleet_deductions`
    }),
    dataIndex: 'fleet_deductions',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_deductions`
    }),
    dataIndex: 'total_deductions',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.ops_bonus`
    }),
    dataIndex: 'ops_bonus',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.fleet_bonus`
    }),
    dataIndex: 'fleet_bonus',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_bonus`
    }),
    dataIndex: 'total_bonus',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.phone_lines`
    }),
    dataIndex: 'phone_lines',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  ,
  {
    title: fmt({
      id: `star_cycles.tables.columns.other_allowances`
    }),
    dataIndex: 'other_allowances',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.accumulated_total_salary`
    }),
    dataIndex: 'total_salary',
    render: (totalSalary) =>
      `${formatBigMoneyValues(totalSalary) || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.contractor`
    }),
    dataIndex: 'contractor_commission',
    render: (value) => `${value || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_with_contractor_com`
    }),
    dataIndex: 'total_with_contractor_commission',
    render: (totalSalaryCC) =>
      `${formatBigMoneyValues(totalSalaryCC) || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.total_with_vat`
    }),
    dataIndex: 'total_with_vat',
    render: (total_with_vat) =>
      `${formatBigMoneyValues(total_with_vat) || 0} ${IS_SAUDI ? 'SAR' : 'EGP'}`
  },
  {
    title: fmt({
      id: `star_cycles.tables.columns.config_change`
    }),
    dataIndex: 'salary_config_changed',
    render: (salaryConfig) =>
      salaryConfig ? (
        <CheckCircleFilled className="br-star-salary__config-column" />
      ) : (
        ''
      )
  }
];

export const STAR_CYCLES_PAGE_LIMIT = 50;

export const CYCLE_INFO_CELLS = [
  {
    key: 'cycle_id',
    label: fmt({
      id: `star_cycles.tables.columns.cycle_id`
    })
  },
  {
    key: 'start_date',
    label: fmt({
      id: `star_cycles.start_date`
    }),
    render: (startDate) =>
      startDate !== 'N/A'
        ? dayjs.utc(startDate).tz().format('ddd, D MMM YYYY')
        : 'N/A'
  },
  {
    key: 'end_date',
    label: fmt({
      id: `star_cycles.end_date`
    }),
    render: (endDate) =>
      endDate !== 'N/A'
        ? dayjs.utc(endDate).tz().format('ddd, D MMM YYYY')
        : 'N/A'
  }
];

export const STAR_PERFORMANCE_DEFAULT_HUB_FILTER_VALUE = IS_SAUDI
  ? 'Riyadh Hub'
  : 'Mohandeseen hub';
export const STAR_PERFORMANCE_DEFAULT_DATE_FILTER_LABEL = fmt({
  id: 'stars_performance.star_performance_tab.star_performance_filter.date_filter_default_value'
});
export const STAR_PERFORMANCE_DEFAULT_ALL_HUBS_VALUE = 'All Hubs';

export const STAR_MAP_DEFAULT_HUB_FILTER_CODE = 'MOHANDESSIN';

export const STAR_MAP_DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const STAR_MAP_CALL_TYPE = {
  CALL: 'Outgoing Call',
  MISSED_CALL: 'Missed Call',
  INCOMING_CALL: 'Incoming Call'
};

export const SMS = 'SMS';

export const STAR_MAP_DEFAULT_LOCATION = { lat: 29.9563293, lng: 31.2720199 };

export const STAR_MAP_ACTIONS = {
  INCOMING_CALL: 'INCOMING_CALL',
  MISSED_CALL: 'MISSED_CALL',
  EXCEPTION: 'EXCEPTION',
  PICKED_UP_FROM_CONSIGNEE: 'PICKED_UP_FROM_CONSIGNEE',
  DELIVER: 'DELIVER',
  COLLECT: 'COLLECT',
  RETURNED_TO_BUSINESS: 'RETURNED_TO_BUSINESS',
  PENDING_CUSTOMER_SIGNATURE: 'PENDING_CUSTOMER_SIGNATURE',
  CALL: 'CALL'
};

export const TOP_PERFORMANCE_SCORE = 85;
export const AVG_PERFORMANCE_SCORE = 75;

export const HIGH_PLACEHOLDER = 'HIGH';
export const AVG_PLACEHOLDER = 'AVERAGE';
export const LOW_PLACEHOLDER = 'LOW';
export const FAWRY_POS_SOURCE = 'Fawry POS';

export const STAR_MAP_EXCEPTIONS = [
  {
    label: fmt({
      id: 'star_map.star_actions.exception_title'
    }),
    value: STAR_MAP_ACTIONS.EXCEPTION
  },
  {
    label: fmt({
      id: 'star_map.star_actions.deliver_title'
    }),
    value: `${STAR_MAP_ACTIONS.DELIVER},${STAR_MAP_ACTIONS.COLLECT},${STAR_MAP_ACTIONS.RETURNED_TO_BUSINESS},${STAR_MAP_ACTIONS.PENDING_CUSTOMER_SIGNATURE},${STAR_MAP_ACTIONS.PICKED_UP_FROM_CONSIGNEE}`
  }
];

export const STAR_AVAILABILITY_STATE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  BUSY: 'BUSY'
};

export const SEARCH_VEHICLE_TYPES = [
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.walking'
    }),
    value: 'Walking'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.bicycle'
    }),
    value: 'Bicycle'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.motorcycle'
    }),
    value: 'Motorcycle'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.car'
    }),
    value: 'Car'
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.vehicle_types.truck'
    }),
    value: 'Truck'
  }
];

export const STAR_STATE = [
  {
    label: fmt({
      id: 'stars.create_edit_star.star_state.online'
    }),
    value: STAR_AVAILABILITY_STATE.ONLINE
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.star_state.offline'
    }),
    value: STAR_AVAILABILITY_STATE.OFFLINE
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.star_state.busy'
    }),
    value: STAR_AVAILABILITY_STATE.BUSY
  }
];

export const STAR_STATUS = [
  {
    label: fmt({
      id: 'stars.create_edit_star.star_status.verified'
    }),
    value: true
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.star_status.pending'
    }),
    value: false
  }
];

export const STAR_SHORTAGE = [
  {
    label: fmt({
      id: 'stars.create_edit_star.star_shortage.has_shortage'
    }),
    value: true
  },
  {
    label: fmt({
      id: 'stars.create_edit_star.star_shortage.no_shortage'
    }),
    value: false
  }
];

export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200, 300];

export const STAR_STATUS_TAG_COLOR = {
  VERIFIED: 'green',
  PENDING: 'yellow'
};

export const STAR_AVAILABILITY_TAG_COLOR = {
  ONLINE: 'green',
  BUSY: 'red',
  OFFLINE: 'red'
};

export const STAR_DEACTIVATION_REASONS_LABEL_ID = [
  'harassment',
  'deposit_issue',
  'thieft',
  'personal_reasons',
  'route_sortation',
  'deducation_without_knowledge',
  'hub_attiude',
  'lack_of_training',
  'long_waiting_time',
  'high_ofd',
  'vehicle_issues',
  'no_insurance',
  'total_income',
  'open_packages',
  'over_promising',
  'open_package_by_force'
];

export const STAR_NATIONAL_ID_ATTACHMENTS = [
  'FRONT_NATIONAL_ID',
  'BACK_NATIONAL_ID'
];

export const ID_IMAGE_MAX_SIZE = 15;

export const STAR_LOCATIONS_LIMIT = 2000;
