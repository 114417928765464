import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { getPickupInfo } from 'services/pickupDetails';
import { getOrderCallLogs } from 'services/shipments';
import { DELIVERIES_TYPES } from 'constants/pickups';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { CALL_TYPES, STAR_ACTIONS } from 'constants/shipment-details';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import PickupSummary from './components/PickupSummary/PickupSummary';
import RequestDetailsTable from './components/RequestDetailsTable/RequestDetailsTable';
import PickupDetailsTable from './components/PickupDetailsTable/PickupDetailsTable';
import DeliveriesTable from './components/DeliveriesTable/DeliveriesTable';
import RepetitionInfoTable from './components/RepetitionInfoTable/RepetitionInfoTable';
import LogsPanel from './components/LogsPanel/LogsPanel';

import './PickupDetails.less';

function formatData(data) {
  data.scheduledDate = data?.scheduledDate
    ? dayjs(data?.scheduledDate).tz().format('LLL')
    : 'N/A';
  data.createdAt = data?.createdAt
    ? dayjs(data?.createdAt).tz().format('LLL')
    : 'N/A';
  data.pickedupDate = data?.pickedupDate
    ? dayjs(data?.pickedupDate).tz().format('LLL')
    : 'N/A';

  data.address =
    data?.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP
      ? `${data?.consigneeAddress?.buildingNumber || ''} ${
          data?.consigneeAddress?.firstLine
        }`
      : `${data?.business?.address?.buildingNumber || ''} ${
          data?.business?.address?.firstLine
        }`;

  data.packages = data?.deliveries?.length || '0';

  data.city = data?.business?.address?.city?.name;

  data.zone =
    data?.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP
      ? data?.consigneeAddress?.zone?.name
      : data?.business?.address?.zone?.name;

  if (data?.repeatedData) {
    data.repeatedData.days = data?.repeatedData?.days?.join(',');
    data.repeatedData.startDate = data?.repeatedData?.startDate
      ? dayjs(data.repeatedData.startDate).tz().format('LL')
      : 'N/A';
    data.repeatedData.endDate = data?.repeatedData?.endDate
      ? dayjs(data.repeatedData?.endDate).tz().format('LL')
      : 'N/A';
    data.repeatedData.nextPickupDate = data?.repeatedData?.nextPickupDate
      ? dayjs(data.repeatedData?.nextPickupDate).tz().format('LL')
      : 'N/A';
  }
}

async function getPickupData(id) {
  try {
    const { message } = await getPickupInfo(id);
    formatData(message);
    return message;
  } catch (error) {
    notify(error.message);
  }
  return {
    list: [],
    total: 0
  };
}

async function getCallLogs(pickupId) {
  const payload = { pickupId };
  const result = {
    smsLogs: [],
    whatsAppLogs: [],
    callLogs: []
  };

  try {
    const {
      data: { actions }
    } = await getOrderCallLogs(payload);

    result.smsLogs = actions.filter((log) => log.action === STAR_ACTIONS.SMS);

    result.whatsAppLogs = actions.filter(
      (log) => log.action === STAR_ACTIONS.WHATSAPP
    );

    result.callLogs = actions.filter((log) =>
      Object.keys(CALL_TYPES).includes(log.action)
    );
  } catch (error) {
    notify(error.message);
  }
  return result;
}

const PickupDetails = ({
  match: {
    params: { id }
  },
  history,
  intl
}) => {
  const [data, setData] = useState({});
  const [callLogData, setCallLogData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    (async () => {
      setIsLoading(true);

      const pickupData = await getPickupData(id);
      const logData = await getCallLogs(pickupData?.puid);

      setData(pickupData);
      setCallLogData(logData);

      setIsLoading(false);
    })();
  }, [id]);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={<BRHeader title="Pickup Requests Details" showBackArrow />}
        content={
          <div className="br-pickup-details-page">
            <div className="br-pickup-summary-container">
              {isDefaultBostaCourier([
                ...aclMatrix.THREE_PL,
                VENDOR_MANAGER
              ]) && (
                <BRButton
                  type="primary"
                  className="br-pickup-details__header__edit-button"
                  hidden={data?.state !== 'Requested'}
                  onClick={() => {
                    history.push(`/pickups/edit/${id}`);
                  }}
                  label={intl.formatMessage({
                    id: 'pickups.pickup_details.edit_button'
                  })}
                />
              )}

              <PickupSummary pickup={data} />
            </div>

            <div className="br-pickup-details-container">
              <RequestDetailsTable pickup={data} />

              <PickupDetailsTable pickup={data} />

              {data?.repeatedData && data?.isRepeated && (
                <RepetitionInfoTable pickup={data} />
              )}

              <DeliveriesTable pickup={data} />

              <LogsPanel
                pickup={data}
                callLogData={callLogData}
                className="br-pickup-details-aside"
              />
            </div>
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(PickupDetails);
