import { PICKUP_MAX_RATING } from 'constants/pickups';

import { ReactComponent as Star } from 'assets/bosta-icons/star.svg';

import './Rating.less';

export default function Rating({ rating }) {
  const greyStars = PICKUP_MAX_RATING - rating;
  return (
    <div className="br-pickup-details-rating">
      {Array.from({ length: rating }).map((_, index) => (
        <Star key={index} className="br-pickup-details-rating__star colored" />
      ))}
      {Array.from({ length: greyStars }).map((_, index) => (
        <Star key={index} className="br-pickup-details-rating__star" />
      ))}
    </div>
  );
}
