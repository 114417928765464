import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';

import { getInternationalBusinesses } from 'services/business';
import {
  INTERNATIONAL_DELIVERIES_E2E_DATE_FILTERS,
  INTERNATIONAL_DELIVERY_E2E_MULTI_SEARCH_KEYS,
  INTERNATIONAL_DELIVERY_E2E_STATES_LABEL,
  INTERNATIONAL_DESTINATION_COUNTRIES,
  INTERNATIONAL_SOURCE_COUNTRIES,
  VAT_TYPE_OPTIONS
} from 'constants/international-orders';
import { nonSpecialCHRule } from 'utils/forms';
import { cleanEmptyString } from 'utils/helpers';
import { DATE_FORMAT } from 'constants/date-picker';
import { getHubs } from 'services/hubs';

import BRButton from 'components/BRButton/BRButton';
import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import { notify } from 'components/Notify/Notify';

import './InternationalDeliveriesE2ESearch.less';

const InternationalDeliveriesE2ESearch = ({
  isLoading,
  handleFilterDeliveries
}) => {
  const [businesses, setBusinesses] = useState([]);
  const [hubs, setHubs] = useState([]);

  const intl = useIntl();

  const formRef = useRef();

  useEffect(() => {
    fetchInternationalBusinesses();
    fetchHubs();
  }, []);

  const fetchInternationalBusinesses = async () => {
    try {
      const data = await getInternationalBusinesses();
      setBusinesses(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchHubs = async () => {
    try {
      const payload = {
        getRegardlessUserCountry: true
      };

      const { result } = await getHubs(payload);
      setHubs(result);
    } catch (error) {
      notify(error.message);
    }
  };

  const onFinish = (values) => {
    const cleanedValues = cleanEmptyString(values);
    const dateFilterKeys = INTERNATIONAL_DELIVERIES_E2E_DATE_FILTERS.flat();

    Object.keys(cleanedValues).forEach((key) => {
      if (INTERNATIONAL_DELIVERY_E2E_MULTI_SEARCH_KEYS.includes(key)) {
        cleanedValues[key] = values[key]
          .trim()
          .replace(/,+\s*$/, '')
          .split(/[, ]+/);
      }
    });

    for (const key of Object.keys(cleanedValues)) {
      if (dateFilterKeys.includes(key)) {
        const dateStartKey = `${key}Start`;
        const dateEndKey = `${key}End`;

        cleanedValues[dateStartKey] = values[key][0].format(DATE_FORMAT);
        cleanedValues[dateEndKey] = values[key][1].format(DATE_FORMAT);

        delete cleanedValues[key];
      }
    }

    if (cleanedValues.id) {
      cleanedValues.businessId = cleanedValues.id;
      delete cleanedValues.id;
    }

    handleFilterDeliveries(cleanedValues);
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleFilterDeliveries({});
  };

  const getStatesFilterOptions = () =>
    Object.entries(INTERNATIONAL_DELIVERY_E2E_STATES_LABEL).map(
      ([key, value]) => ({
        value: key,
        label: value
      })
    );

  return (
    <div className="br-international-deliveries-search__container">
      <div className="br-international-deliveries-search__header display-xs">
        {intl.formatMessage({ id: 'common.search' })}
      </div>
      <div className="br-international-deliveries-search__filters">
        <Form onFinish={onFinish} ref={formRef}>
          <div className="br-filters-search__row">
            <Form.Item
              name="internationalOrDomesticTN"
              label={intl.formatMessage({
                id: 'international_orders.deliveries.search.tracking_number_label'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'international_orders.e2e_listing.filters.tracking_number_label'
                })}
              />
            </Form.Item>
            <Form.Item
              name="businessId"
              label={intl.formatMessage({
                id: 'business_details.business_info.business_name'
              })}
            >
              <Select
                showSearch
                showArrow
                allowClear
                mode="multiple"
                optionFilterProp="name"
                placeholder={intl.formatMessage({
                  id: 'international_orders.pickups.search.business_placeholder'
                })}
                options={businesses}
                fieldNames={{ label: 'name', value: '_id' }}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'businesses.search_filters.business_id'
              })}
              name="id"
              rules={[
                nonSpecialCHRule({
                  message: intl.formatMessage({
                    id: 'wallet.no_special_ch'
                  })
                })
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'deliveries.search_filters_placeholder.business_id'
                })}
              />
            </Form.Item>
            <Form.Item
              name="receiverPhone"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.customer_phone'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'deliveries.search_filters_placeholder.customer_phone'
                })}
              />
            </Form.Item>
            <Form.Item
              name="stateCode"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.delivery_state'
              })}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getStatesFilterOptions()}
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="sourceCountry"
              label={intl.formatMessage({
                id: 'international_orders.e2e_listing.filters.from_origin'
              })}
            >
              <Select
                allowClear
                placeholder={intl.formatMessage({ id: 'common.select' })}
                options={INTERNATIONAL_SOURCE_COUNTRIES}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </Form.Item>
            <Form.Item
              name="destinationCountry"
              label={intl.formatMessage({
                id: 'international_orders.e2e_listing.filters.to_destination'
              })}
            >
              <Select
                allowClear
                placeholder={intl.formatMessage({ id: 'common.select' })}
                options={INTERNATIONAL_DESTINATION_COUNTRIES}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </Form.Item>
            <Form.Item
              name="warehouseId"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.hub'
              })}
            >
              <Select
                allowClear
                showSearch
                placeholder={intl.formatMessage({ id: 'common.select' })}
                options={hubs}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
              />
            </Form.Item>
            <Form.Item
              name="customVAT"
              label={intl.formatMessage({
                id: 'international_orders.e2e_listing.filters.vat_type'
              })}
            >
              <Select
                allowClear
                placeholder={intl.formatMessage({ id: 'common.select' })}
                options={VAT_TYPE_OPTIONS}
              />
            </Form.Item>
          </div>
          {INTERNATIONAL_DELIVERIES_E2E_DATE_FILTERS.map(
            (filtersRow, index) => (
              <div className="br-filters-search__row" key={index}>
                {filtersRow.map((key) => (
                  <SearchDateRangePicker
                    key={key}
                    name={key}
                    label={intl.formatMessage({
                      id: `international_orders.e2e_listing.filters.${key}`
                    })}
                  />
                ))}
              </div>
            )
          )}
          <div className="br-international-deliveries-search__actions">
            <BRButton
              onClick={handleResetFilters}
              label={intl.formatMessage({ id: 'common.clear_filters' })}
              disabled={isLoading}
            />
            <BRButton
              type="primary"
              htmlType="submit"
              label={intl.formatMessage({
                id: 'international_orders.pickups.search.apply_filters'
              })}
              loading={isLoading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InternationalDeliveriesE2ESearch;
