import classnames from 'classnames';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { Radio, Typography } from 'antd';

import DetailsGrid from 'components/Pickups/components/PickupDetails/components/DetailsGrid/DetailsGrid';
import ActivityTimeline from './ActivityTimeline/ActivityTimeline';
import CallLog from './CallLog/CallLog';

import { ReactComponent as LogsIcon } from 'assets/bosta-icons/Calendar.svg';

import './LogsPanel.less';

const logsTabs = [
  {
    label: 'pickups.pickup_details.timeline',
    value: 'timeline'
  },
  {
    label: 'deliveries.delivery_details.call_logs.title',
    value: 'callLog'
  }
];

export default function LogsPanel({
  pickup,
  callLogData,
  className,
  ...props
}) {
  const { log, exception } = pickup;
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState('timeline');

  const tabs = {
    timeline: <ActivityTimeline log={log} />,
    callLog: <CallLog callLogData={callLogData} exception={exception} />
  };

  const handleTabChange = (e) => {
    setCurrentTab(e.target.value);
  };

  return (
    <DetailsGrid
      className={classnames('br-pickup-activity', { [className]: !!className })}
      icon={<LogsIcon />}
      title={
        <>
          <Typography.Text>Logs</Typography.Text>
          <Radio.Group
            value={currentTab}
            onChange={handleTabChange}
            optionType="button"
            buttonStyle="solid"
            options={logsTabs.map((tab) => ({
              ...tab,
              label: intl.formatMessage({ id: tab.label })
            }))}
          />
        </>
      }
      {...props}
    >
      <div className="full-width">{tabs[currentTab]}</div>
    </DetailsGrid>
  );
}
