import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getSortingFacilityData = (params) => {
  return http.get(`/hubs/sorting-facility-ops-room-report`, params);
};

export const exportTableData = (payload) => {
  return http.post('deliveries/exportxlsx?', payload, downloadHeader);
};

export const inTransitDeliveriesCount = (payload) => {
  return http.get('hubs/in-transit-ops-room-report', payload);
};

export const exportInTransitReport = (payload) => {
  return http.get(
    'hubs/export-in-transit-ops-room-report',
    payload,
    downloadHeader
  );
};

export const getHubDeliveries = (id, payload) => {
  return http.get(`hubs/${id}/in-transit-ops-room-deliveries`, payload);
};

export const exportHubDeliveries = (id, payload) => {
  return http.get(`hubs/${id}/export-in-transit-ops-room-deliveries`, payload);
};

export const getDispatchingOpsRoom = (payload) => {
  return http.get(`hubs/dispatching-ops-room-report`, payload);
};

export const exportDispatchingOpsRoom = (payload) => {
  return http.get(
    `hubs/export-dispatching-ops-room-report`,
    payload,
    downloadHeader
  );
};

export const getDispatchingRoomDeliveries = (id, payload) => {
  return http.get(`hubs/${id}/dispatching-ops-room-deliveries`, payload);
};

export const exportDispatchingRoomDeliveries = (id, payload) => {
  return http.get(`hubs/${id}/export-dispatching-ops-room-deliveries`, payload);
};

export const getSortingFacilityDeliveries = (payload) => {
  return http.get('hubs/sorting-facility-ops-room-deliveries', payload);
};

export const exportSortingFacilityData = (payload) => {
  return http.get('hubs/export-sorting-facility-ops-room-deliveries', payload);
};

export const fetchDeliveriesCount = (payload) => {
  return http.get('hubs/sorting-facility-ops-room-deliveries-count', payload);
};

export const getCurrentActiveDate = (payload) => {
  return http.get('hubs/ops-room-active-date', payload);
};
