import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Checkbox, Divider, Radio } from 'antd';
import classnames from 'classnames';

import {
  COMPENSATION_REASONS,
  COMPENSATION_TYPE,
  DEPARTMENTS,
  INVESTIGATION,
  LIABILITY_TYPE
} from 'constants/compensation';
import { NUMBER_TEXT } from 'constants/form';
import { CURRENCIES } from 'common/countries/constants/finance';

import BRTag from 'components/BRTag/BRTag';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import './CompensationForm.less';

const DepartmentLiability = ({
  compensationType,
  reason,
  formRef,
  form,
  department,
  hubs,
  intl
}) => {
  const {
    approvals,
    assignedOn,
    contractorName,
    name,
    over_budget,
    _id,
    reasons
  } = department || {};

  const [checked, setChecked] = useState(false);
  const [showInvestigationReason, setShowInvestigationReason] = useState(false);
  const [assignedFilter, setAssignedFilter] = useState('');

  const checkedLiability = Form.useWatch(
    ['assignedDepartments', name, 'liability'],
    form
  );
  const isContractor = name === DEPARTMENTS.Contractors.value;
  const isOps = name === DEPARTMENTS.OPs.value;
  const isCX = name === DEPARTMENTS.Customer_Experience.value;
  const isFreeOrder = compensationType === COMPENSATION_TYPE.FREE;
  const isWrongPackageSize =
    reason.reason === COMPENSATION_REASONS.WRONG_PACKAGE_SIZE;
  const hideAssignedOn = isOps && checkedLiability === LIABILITY_TYPE.OPS;

  const liabilityOps = reason.assignedDepartments
    .find((department) => department.name === DEPARTMENTS.OPs.value)
    ?.liabilities?.map((item) => {
      return {
        label: item,
        value: item
      };
    });

  const handleEnableRow = (e) => {
    setChecked(e.target.checked);
  };

  const mapAssignedOn = () => {
    const list = isContractor ? contractorName : assignedOn;
    return list?.map((item) => ({
      label: item,
      value: item
    }));
  };

  const handleAssignedChanged = (e) => {
    setAssignedFilter(e);
    setShowInvestigationReason(e === INVESTIGATION);
  };

  const mapApprovals = () => {
    return isCX
      ? approvals.filter(
          ({ assignedOnReasons }) =>
            assignedOnReasons && assignedOnReasons[assignedFilter]
        )
      : isOps && checkedLiability
      ? approvals.filter(({ liabilityOn }) => liabilityOn[checkedLiability])
      : approvals;
  };

  const mapInvestigationReasons = () => {
    return reasons?.map((item) => ({
      label: item,
      value: item
    }));
  };

  useEffect(() => {
    // Clear the form item value if the checkbox is unchecked
    if (!checked) {
      formRef.current.setFieldsValue({
        assignedDepartments: {
          [name]: null
        }
      });
    }
  }, [checked, name]);

  return (
    <div className="br-compensation__form-dep-liability">
      <Checkbox value={_id} checked={checked} onChange={handleEnableRow}>
        <div className="br-compensation__form-dep-liability-label">
          {DEPARTMENTS[name]?.name}
          {over_budget && !isFreeOrder && (
            <BRTag
              color="red"
              children={intl.formatMessage({
                id: 'wallet.compensation.budget.over_budget'
              })}
            />
          )}
        </div>
      </Checkbox>

      {checked && (
        <div className="br-compensation__form-dep-liability-inputs">
          {!hideAssignedOn && (
            <Form.Item
              name={[
                'assignedDepartments',
                name,
                `${isContractor ? 'contractorName' : 'assignedOn'}`
              ]}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'request_compensation_modal.validation_messages.approved_by'
                  })
                }
              ]}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: `request_compensation_modal.form_placeholders.${
                    isContractor ? 'contractor_name' : 'assigned_on'
                  }`
                })}
                options={mapAssignedOn()}
                onChange={handleAssignedChanged}
                showSearch
                filterOption={(input, option) =>
                  option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          )}

          {!isFreeOrder && (
            <BRFormInputs
              formRef={formRef}
              name={['assignedDepartments', name, 'compensationAmount']}
              label={null}
              placeholder={intl.formatMessage({
                id: 'request_compensation_modal.form_placeholders.compensation_amount'
              })}
              type={NUMBER_TEXT}
              prefix={CURRENCIES.EGP}
              disabled={isWrongPackageSize}
            />
          )}

          <Form.Item
            name={['assignedDepartments', name, 'approvedBy']}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'request_compensation_modal.validation_messages.approved_by'
                })
              }
            ]}
          >
            {isContractor ? (
              <Select
                placeholder={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.approved_by'
                })}
                options={hubs}
                showSearch
                filterOption={(input, option) =>
                  option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            ) : (
              <Select
                placeholder={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.approved_by'
                })}
                fieldNames={{ label: 'name', value: 'name' }}
                options={mapApprovals()}
              />
            )}
          </Form.Item>

          {isOps && !!liabilityOps.length ? (
            <>
              <span
                className={classnames('br-compensation__liability-checkboxes', {
                  'br-compensation__checkboxes-show-first': isOps
                })}
              >
                <p className="body-medium">
                  {intl.formatMessage({
                    id: 'request_compensation_modal.liability'
                  })}
                </p>
                <Form.Item
                  name={['assignedDepartments', name, 'liability']}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'request_compensation_modal.validation_messages.approved_by'
                      })
                    }
                  ]}
                >
                  <Radio.Group>
                    <Radio value={LIABILITY_TYPE.OPS}>
                      {LIABILITY_TYPE.OPS}
                    </Radio>
                    <Radio value={LIABILITY_TYPE.HUB}>
                      {LIABILITY_TYPE.HUB}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </span>

              {checkedLiability === LIABILITY_TYPE.HUB && (
                <Form.Item
                  name={['assignedDepartments', name, 'hub']}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'request_compensation_modal.validation_messages.approved_by'
                      })
                    }
                  ]}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'request_compensation_modal.hub'
                    })}
                    options={hubs}
                    mode="multiple"
                  />
                </Form.Item>
              )}
            </>
          ) : isCX && showInvestigationReason ? (
            <Form.Item
              name={['assignedDepartments', name, 'investigationReason']}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'request_compensation_modal.validation_messages.approved_by'
                  })
                }
              ]}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: 'request_compensation_modal.investigation_reason'
                })}
                options={mapInvestigationReasons()}
              />
            </Form.Item>
          ) : null}
        </div>
      )}
      <Divider />
    </div>
  );
};

export default injectIntl(DepartmentLiability);
