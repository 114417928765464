import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Radio,
  Form,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Select,
  Divider
} from 'antd';
import Icon, { PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { openModal } from 'utils/modal';
import { codRule, rangeRule } from 'utils/forms';
import { fetchBusinessPickupLocations } from 'services/business';
import {
  COUNTRY_CURRENCY,
  COUNTRY_CURRENCY_MODIFIED,
  IS_SAUDI
} from 'constants/helper';

import {
  DELIVERY_TYPES_CODES,
  MAP_DELIVERIES_CODES,
  COD_OPTIONS,
  PACKAGE_TYPES,
  FINAL_STATS,
  PACKAGE_TYPES_BULKY,
  ALLOWED_STATES_TO_EDIT_ORDER,
  MULTI_PACKAGES_RANGE,
  ALLOWED_TYPES_FOR_MULTI_PACKAGE
} from 'constants/shipments';
import {
  getCurrency,
  isMultiPackageAvailable,
  isOpenPackageFeesAvailable
} from 'common/countries/countries-mapping';
import { getBusinessPricingTierInfo } from 'services/pricing';
import { NUMBER_TEXT } from 'constants/form';

import { notify } from 'components/Notify/Notify';
import CardHeader from 'components/Shipments/CreateEditOrder/CardHeader/CardHeader';
import CreatePickupLocationFormModal from 'components/Pickups/components/CreatePickupLocationFormModal/CreatePickupLocationFormModal';
import BRFormInputCounter from 'components/BRFormInputCounter/BRFormInputCounter';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import BRFormItemSubTitle from 'components/BRFormItemSubTitle/BRFormItemSubTitle';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as DeliverIcon } from 'assets/imgRevamp/DeliverIcon.svg';
import { ReactComponent as DeliverGreyIcon } from 'assets/imgRevamp/DeliverGreyIcon.svg';
import { ReactComponent as ExchangeIcon } from 'assets/imgRevamp/ExchangeIcon.svg';
import { ReactComponent as ExchangeGreyIcon } from 'assets/imgRevamp/ExchangeGreyIcon.svg';
import { ReactComponent as CashCollectionGreyIcon } from 'assets/imgRevamp/CashCollectionGreyIcon.svg';
import { ReactComponent as ReturnGreyIcon } from 'assets/imgRevamp/ReturnGreyIcon.svg';
import { ReactComponent as CashCollectionIcon } from 'assets/imgRevamp/CashCollectionIcon.svg';
import { ReactComponent as ReturnIcon } from 'assets/imgRevamp/ReturnIcon.svg';
import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/informationButton.svg';

import './OrderDetails.less';
import ItemValue from './ItemValue/ItemValue';
import GoodsTermsModal from './ItemValue/GoodsTermsModal';

const orderTypes = [
  {
    value: DELIVERY_TYPES_CODES.FORWARD,
    icon: DeliverIcon,
    iconGrey: DeliverGreyIcon,
    title: 'deliver',
    options: [
      { label: 'with_cash_collection', value: COD_OPTIONS.COD },
      { label: 'without_cash_collection', value: COD_OPTIONS.WITH_OUT }
    ]
  },
  {
    value: DELIVERY_TYPES_CODES.EXCHANGE,
    icon: ExchangeIcon,
    iconGrey: ExchangeGreyIcon,
    title: 'exchange',
    options: [
      { label: 'with_cash_difference', value: COD_OPTIONS.COD },
      { label: 'without_cash_difference', value: COD_OPTIONS.WITH_OUT }
    ]
  },
  {
    value: DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP,
    icon: ReturnIcon,
    iconGrey: ReturnGreyIcon,
    title: 'return',
    options: [
      { label: 'with_refund', value: COD_OPTIONS.REFUND },
      { label: 'without_refund', value: COD_OPTIONS.WITH_OUT }
    ]
  },
  {
    value: DELIVERY_TYPES_CODES.CASH_COLLECTION,
    iconGrey: CashCollectionGreyIcon,
    icon: CashCollectionIcon,
    title: 'cash_collection',
    options: [
      { label: 'with_cash_collection', value: COD_OPTIONS.COD },
      { label: 'with_refund', value: COD_OPTIONS.REFUND }
    ]
  }
];

const OrderDetails = (props) => {
  const [pickupLocations, setPickupLocations] = useState(null);
  const [isSignAndCollectChecked, setIsSignAndCollectChecked] = useState(false);
  const [isBulky, setIsBulky] = useState(false);
  const [bulkyType, setBulkyType] = useState(PACKAGE_TYPES_BULKY[0]);
  const [openPackageFees, setOpenPackageFees] = useState({
    isConfigEnabled: false,
    amount: 0
  });
  const [showOpenPackageFeesNote, setShowOpenPackageFeesNote] = useState(false);
  const [goodsValueFeatureSeen, setGoodsValueFeatureSeen] = useState(false);

  const [orderTypeOption, setOrderTypeOption] = useState({
    type: DELIVERY_TYPES_CODES.FORWARD,
    option: COD_OPTIONS.COD,
    amount: COD_OPTIONS.COD,
    packageType: PACKAGE_TYPES[0].value
  });
  const specialChar = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
  const preventSpecialChar = (e) => {
    if (e.key.match(specialChar)) {
      return e.key && e.preventDefault();
    }
  };
  const {
    intl,
    orderData,
    formRef,
    handleOnChangeForm,
    pricingSizes,
    selectedBusinessId,
    selectedPickupLocation,
    setIsPickupSelected,
    selectedBusinessType,
    selectedBusinessCodLimit
  } = props;

  const handleOpenCreatePickupLocationModal = () => {
    openModal(CreatePickupLocationFormModal, {
      businessId: selectedBusinessId,
      onSuccess: (pickupLocationId) => {
        getPickupLocations();
        formRef.current.setFieldsValue({
          locationId: pickupLocationId
        });
      }
    });
  };

  const getPickupLocations = async () => {
    try {
      const { handleAddNewPickupLocation } = props;
      const { data: pickups } = await fetchBusinessPickupLocations(
        selectedBusinessId
      );
      setPickupLocations(pickups);
      handleAddNewPickupLocation(pickups);
    } catch (error) {
      notify(error.message);
    }
  };

  const OnChangeSignAndReturnCheckBox = (e) => {
    setIsSignAndCollectChecked(e.target.checked);
  };

  useEffect(() => {
    setOrderTypeOption({
      type: DELIVERY_TYPES_CODES.FORWARD,
      option: COD_OPTIONS.COD,
      amount: COD_OPTIONS.COD,
      packageType: PACKAGE_TYPES[0].value
    });
  }, [selectedBusinessId]);

  const handleCheckPackageType = (e) => {
    const { setOrderTypeProps } = props;
    const {
      target: { value }
    } = e;
    setOrderTypeProps({
      ...orderTypeOption,
      packageType: value
    });
    setOrderTypeOption({
      ...orderTypeOption,
      packageType: value
    });
    if (value === PACKAGE_TYPES[2].value) {
      setBulkyType(PACKAGE_TYPES_BULKY[0]);
      formRef.current.setFieldsValue({
        packageTypeBulky: PACKAGE_TYPES_BULKY[0]
      });
      setIsBulky(true);
    } else {
      setIsBulky(false);
    }
  };
  const handleReturnLocation = () => {
    return (
      <div className="br-request-pickup-modal__create-pickup-location">
        <BRFormItemSubTitle
          name="locationId"
          title={intl.formatMessage({
            id: 'shipments.new_order.form_labels.return_location'
          })}
          subtitle={
            orderTypeOption.type === DELIVERY_TYPES_CODES.EXCHANGE &&
            intl.formatMessage({
              id: `shipments.new_order.form_labels.return_location_sub_title`
            })
          }
          rules={[
            {
              required: !orderData,
              label: intl.formatMessage({
                id: 'shipments.new_order.form_labels.return_location'
              })
            }
          ]}
          field={
            <Select
              label={intl.formatMessage({
                id: 'shipments.new_order.form_placeholder.select'
              })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              autoComplete={`${Math.random()}`}
            >
              {pickupLocations?.map((location) => (
                <Select.Option key={location?._id} value={location?._id}>
                  {location.locationName}
                </Select.Option>
              ))}
            </Select>
          }
        />

        <Button
          onClick={handleOpenCreatePickupLocationModal}
          className="br-add-button"
        >
          <PlusOutlined />
          {intl.formatMessage({
            id: `shipments.new_order.request_pickup_modal.actions.create_new_location`
          })}
        </Button>
      </div>
    );
  };

  const onChangeOrderTypeOption = (e) => {
    const { setOrderTypeProps } = props;
    formRef.current.setFieldsValue({ cod: null });
    setOrderTypeProps({ ...orderTypeOption, option: e.target.value });
    setOrderTypeOption({
      ...orderTypeOption,
      option: e.target.value
    });
  };

  const onChangeExchangeAmountType = (e) => {
    setOrderTypeOption({
      ...orderTypeOption,
      amount: e.target.value
    });
    props.setOrderTypeProps({ option: e.target.value });
  };

  const handleOnChangeOrderType = (e) => {
    setIsSignAndCollectChecked(false);
    props.setOrderTypeProps({
      type: e.target.value,
      option:
        e.target.value === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? COD_OPTIONS.REFUND
          : COD_OPTIONS.COD
    });
    formRef.current.resetFields();
    setIsPickupSelected(false);
    formRef.current.setFieldsValue({
      type: e.target.value,
      businessAddress: selectedPickupLocation?._id,
      allowToOpenPackage: false,
      sender: selectedBusinessId
    });
    setOrderTypeOption({
      ...orderTypeOption,
      type: e.target.value,
      option:
        e.target.value === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
          ? COD_OPTIONS.REFUND
          : COD_OPTIONS.COD,
      amount: COD_OPTIONS.COD
    });
  };

  useEffect(() => {
    if (orderData) {
      setIsSignAndCollectChecked(
        orderData?.type?.code === DELIVERY_TYPES_CODES.SIGN_AND_RETURN
      );
      const { RETURN_TO_ORIGIN, SIGN_AND_RETURN, ...restDeliveryCodes } =
        DELIVERY_TYPES_CODES;

      const codeOfDeliveryType = Object.entries(restDeliveryCodes).find(
        (item) => item[1] === orderData.type.code
      );
      setOrderTypeOption({
        type:
          orderData?.type?.code !== DELIVERY_TYPES_CODES.SIGN_AND_RETURN
            ? orderData?.type?.code
            : DELIVERY_TYPES_CODES.FORWARD,
        option: orderData?.cod?.amount
          ? Math.sign(orderData.cod.amount) === 1
            ? COD_OPTIONS.COD
            : orderData?.type?.code === 30
            ? COD_OPTIONS.COD
            : COD_OPTIONS.REFUND
          : COD_OPTIONS.WITH_OUT,
        amount: orderData?.cod?.amount
          ? Math.sign(orderData.cod.amount) === 1
            ? COD_OPTIONS.COD
            : COD_OPTIONS.REFUND
          : COD_OPTIONS.COD,
        packageType: orderData?.specs?.packageType
      });
    }
    setPickupLocations(props.pickupLocations);
  }, [orderData, props.pickupLocations]);

  const onChangeAllowOpenPackage = ({ target: { checked } }) => {
    if (
      checked &&
      isOpenPackageFeesAvailable() &&
      openPackageFees?.isConfigEnabled &&
      !!openPackageFees?.amount
    ) {
      setShowOpenPackageFeesNote(true);
    } else {
      setShowOpenPackageFeesNote(false);
      return false;
    }
  };

  const checkAllowOpenPackageFees = async () => {
    try {
      const {
        data: { pricingTier }
      } = await getBusinessPricingTierInfo(selectedBusinessId);
      const isOpeningPackageFeeEnabled =
        !!pricingTier?.configurations?.openingPackageFee;
      setOpenPackageFees({
        isConfigEnabled: isOpeningPackageFeeEnabled,
        amount: pricingTier?.openingPackageFee?.amount || 0
      });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const handleCloseFeatureTour = () => {
    setGoodsValueFeatureSeen(true);
  };

  useEffect(() => {
    setShowOpenPackageFeesNote(false);
    if (!!selectedBusinessId && isOpenPackageFeesAvailable()) {
      checkAllowOpenPackageFees();
    }
  }, [selectedBusinessId]);

  return (
    <>
      <div className="br-order-details">
        <div className="br-new-order-card">
          <CardHeader
            leftTitle={intl.formatMessage({
              id: `shipments.new_order.order_details.title`
            })}
            cardNumber={1}
          />
          <div className="br-new-order-card__content">
            <div className="br-form-row">
              <Form.Item name="type">
                <Radio.Group
                  onChange={handleOnChangeOrderType}
                  className="br-order-details__order_types"
                >
                  {orderTypes.map((item, index) => {
                    return (
                      <Radio.Button
                        disabled={orderData}
                        key={item.value}
                        value={item.value}
                        autoFocus={orderTypeOption.type === item.value}
                      >
                        <div
                          className={classnames('br-order-details__icons', {
                            'br-order-details__icons__with-options':
                              orderTypeOption.type === item.value &&
                              item.options
                          })}
                        >
                          <p className="br-order-details__icons__title">
                            <Icon
                              id={index}
                              component={
                                orderTypeOption.type === item.value
                                  ? item?.icon
                                  : item.iconGrey
                              }
                            />

                            {intl.formatMessage({
                              id: `shipments.new_order.order_details.order_types.${item.title}`
                            })}
                          </p>
                          <p className="br-order-details__icons__sub-title">
                            {intl.formatMessage({
                              id: `shipments.new_order.order_details.order_types.${item.title}_sub_title`
                            })}
                          </p>
                          {orderTypeOption.type === item.value && (
                            <Radio.Group
                              disabled={
                                orderData &&
                                FINAL_STATS.includes(orderData?.state?.value)
                              }
                              onChange={onChangeOrderTypeOption}
                              value={orderTypeOption.option}
                              className="br-new-order__order_types__cod"
                              optionType="default"
                            >
                              {item?.options &&
                                item?.options?.map((option) => {
                                  if (option?.label === 'with_refund') {
                                    return (
                                      <Radio
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {intl.formatMessage({
                                          id: `shipments.new_order.order_details.order_types.${item.title}_options.${option.label}`
                                        })}
                                      </Radio>
                                    );
                                  }
                                  return (
                                    <Radio
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {intl.formatMessage({
                                        id: `shipments.new_order.order_details.order_types.${item.title}_options.${option.label}`
                                      })}
                                    </Radio>
                                  );
                                })}
                            </Radio.Group>
                          )}
                        </div>
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="br-form-item-label">
              <span className="br-form-item-label__cod_title">
                {intl.formatMessage({
                  id: `shipments.new_order.form_labels.${
                    MAP_DELIVERIES_CODES[orderTypeOption.type || 10]
                  }_cod`
                })}
              </span>
              <span className="br-order-details__cod-form-item__label__sub-title">
                {intl.formatMessage({
                  id:
                    orderTypeOption.type !== DELIVERY_TYPES_CODES.EXCHANGE &&
                    orderTypeOption.type !==
                      DELIVERY_TYPES_CODES.CASH_COLLECTION
                      ? `shipments.new_order.form_labels.${
                          MAP_DELIVERIES_CODES[orderTypeOption.type || 10]
                        }_cod_sub_title`
                      : `shipments.new_order.form_labels.${
                          MAP_DELIVERIES_CODES[orderTypeOption.type]
                        }_${
                          orderTypeOption.option === COD_OPTIONS.REFUND ||
                          orderTypeOption.amount === COD_OPTIONS.REFUND
                            ? COD_OPTIONS.REFUND
                            : COD_OPTIONS.COD
                        }_sub_title`
                })}
              </span>
              <span className="br-order-details__cod-form-item__label__sub-title">
                {orderTypeOption.type === DELIVERY_TYPES_CODES.EXCHANGE &&
                  orderTypeOption.option !== COD_OPTIONS.WITH_OUT && (
                    <Radio.Group
                      onChange={onChangeExchangeAmountType}
                      defaultValue={orderTypeOption.amount}
                      disabled={
                        orderData &&
                        FINAL_STATS.includes(orderData?.state?.value)
                      }
                    >
                      <Radio value={COD_OPTIONS.COD}>
                        {intl.formatMessage({
                          id: `shipments.new_order.order_details.collect_cash_from_customer`
                        })}
                      </Radio>
                      <Radio value={COD_OPTIONS.REFUND}>
                        {intl.formatMessage({
                          id: `shipments.new_order.order_details.refund_cash_to_customer`
                        })}
                      </Radio>
                      )
                    </Radio.Group>
                  )}
              </span>
              <Form.Item
                className="br-order-details__cod-form-item br-form-without-label"
                name="cod"
                rules={[
                  {
                    required:
                      orderTypeOption.type ===
                        DELIVERY_TYPES_CODES.CASH_COLLECTION ||
                      !(
                        orderData ||
                        orderTypeOption.option === COD_OPTIONS.WITH_OUT
                      ),
                    label: intl.formatMessage({
                      id: `shipments.new_order.form_labels.${
                        orderTypeOption.type !== DELIVERY_TYPES_CODES.EXCHANGE
                          ? orderTypeOption.option === COD_OPTIONS.COD
                            ? orderTypeOption.type ===
                              DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
                              ? 'refund_amount'
                              : 'cod_amount'
                            : 'refund_amount'
                          : orderTypeOption.amount === COD_OPTIONS.COD
                          ? 'cod_amount'
                          : 'refund_amount'
                      }`
                    })
                  },
                  codRule(
                    `${intl.formatMessage({
                      id: `shipments.new_order.order_details.cod_greater_than_30000_text`
                    })}${intl.formatMessage(
                      {
                        id: COUNTRY_CURRENCY_MODIFIED
                      },
                      {
                        cod: selectedBusinessCodLimit || 30000
                      }
                    )}`,

                    false,
                    selectedBusinessCodLimit
                  )
                ]}
              >
                <Input
                  name="cod"
                  disabled={
                    orderTypeOption.option === COD_OPTIONS.WITH_OUT ||
                    (orderData && FINAL_STATS.includes(orderData?.state?.value))
                  }
                  step="0.01"
                  min={
                    orderData &&
                    orderTypeOption.type !==
                      DELIVERY_TYPES_CODES.CASH_COLLECTION
                      ? null
                      : '1'
                  }
                  prefix={intl.formatMessage(
                    {
                      id: COUNTRY_CURRENCY
                    },
                    { cod: null }
                  )}
                  autoComplete={`${Math.random()}`}
                  allowClear
                  onKeyDown={preventSpecialChar}
                />
              </Form.Item>
            </div>
            <Divider className="br-order-details__divider" />
            {orderTypeOption.type === DELIVERY_TYPES_CODES.CASH_COLLECTION && (
              <>
                <p className="br-new-order__card-content-title">
                  {intl.formatMessage({
                    id: `shipments.new_order.order_details.title`
                  })}
                </p>
                <div className="br-form-row__half-width">
                  <BRFormItemSubTitle
                    name="businessReference"
                    title={intl.formatMessage({
                      id: `shipments.new_order.form_labels.order_reference`
                    })}
                    subtitle={intl.formatMessage({
                      id: `shipments.new_order.form_labels.order_reference_sub_title`
                    })}
                    rules={[
                      {
                        label: intl.formatMessage({
                          id: `shipments.new_order.form_labels.order_reference`
                        })
                      }
                    ]}
                    optional
                    field={
                      <Input
                        disabled={
                          orderData &&
                          !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                            orderData?.state?.code
                          )
                        }
                        placeholder={intl.formatMessage({
                          id: `shipments.new_order.form_placeholder.order_reference_placeholder`
                        })}
                        autoComplete={`${Math.random()}`}
                        maxLength={100}
                        allowClear
                      />
                    }
                  />
                </div>
              </>
            )}
            {orderTypeOption.type !== DELIVERY_TYPES_CODES.CASH_COLLECTION && (
              <>
                <p className="br-new-order__card-content-titles">
                  <span className="br-new-order__card-content-title">
                    {intl.formatMessage({
                      id: `shipments.new_order.order_details.${
                        orderTypeOption.type === DELIVERY_TYPES_CODES.EXCHANGE
                          ? 'delivery_package_details'
                          : 'package_details'
                      }`
                    })}
                  </span>

                  {orderTypeOption.type === DELIVERY_TYPES_CODES.EXCHANGE && (
                    <span className="br-new-order__card-content-title">
                      {intl.formatMessage({
                        id: `shipments.new_order.order_details.return_package_details`
                      })}
                    </span>
                  )}
                </p>
                <div
                  className={classnames({
                    'br-order-details__package-details':
                      orderTypeOption.type === DELIVERY_TYPES_CODES.EXCHANGE
                  })}
                >
                  <div
                    className={classnames({
                      'br-order-details__delivery-package':
                        orderTypeOption.type !== DELIVERY_TYPES_CODES.EXCHANGE
                    })}
                  >
                    <div className="br-form-group">
                      {orderTypeOption.type === DELIVERY_TYPES_CODES.FORWARD &&
                        !IS_SAUDI && (
                          <BRFormItemSubTitle
                            name="packageType"
                            title={intl.formatMessage({
                              id: `shipments.new_order.form_labels.package_type`
                            })}
                            subtitle={intl.formatMessage({
                              id: `shipments.new_order.form_labels.package_type_sub_title`
                            })}
                            rules={[{ required: !orderData }]}
                            field={
                              <Radio.Group
                                disabled={
                                  orderData &&
                                  (orderData.type.code ===
                                    DELIVERY_TYPES_CODES.SIGN_AND_RETURN ||
                                    !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                      orderData?.state?.code
                                    ))
                                }
                                className="br-package-details__types display-flex"
                                onChange={handleCheckPackageType}
                              >
                                {PACKAGE_TYPES?.map((packageType) => (
                                  <Radio.Button value={packageType.value}>
                                    <Icon component={packageType.icon} />
                                    <span>
                                      {intl.formatMessage({
                                        id: `shipments.new_order.order_details.package_types.${packageType.label}`
                                      })}
                                    </span>
                                  </Radio.Button>
                                ))}
                              </Radio.Group>
                            }
                          />
                        )}
                      {formRef.current &&
                        formRef.current.getFieldValue('packageType') ===
                          PACKAGE_TYPES[2].value && (
                          <div className="display-flex ">
                            <Form.Item
                              name={'packageTypeBulky'}
                              className="br-form-group_types-bulky"
                            >
                              <Radio.Group
                                defaultValue={PACKAGE_TYPES_BULKY[0]}
                                disabled={
                                  orderData &&
                                  !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                    orderData?.state?.code
                                  )
                                }
                                onChange={(value) =>
                                  setBulkyType(value.target.value)
                                }
                              >
                                <Radio value={PACKAGE_TYPES_BULKY[0]}>
                                  <span>
                                    {intl.formatMessage({
                                      id: `shipments.new_order.package_type.light_bulky`
                                    })}
                                  </span>
                                  <span>
                                    <Tooltip
                                      title={intl.formatMessage({
                                        id: 'shipments.new_order.tooltips.tooltips_light_bulky'
                                      })}
                                      getPopupContainer={(trigger) =>
                                        trigger.parentElement.parentElement
                                          .parentElement.parentElement
                                      }
                                      autoAdjustOverflow={true}
                                      arrowPointAtCenter={true}
                                    >
                                      <TooltipsIcon />
                                    </Tooltip>
                                  </span>
                                </Radio>
                                <Radio value={PACKAGE_TYPES_BULKY[1]}>
                                  <span>
                                    {intl.formatMessage({
                                      id: `shipments.new_order.package_type.heavy_bulky`
                                    })}
                                  </span>
                                  <span>
                                    <Tooltip
                                      title={intl.formatMessage({
                                        id: 'shipments.new_order.tooltips.tooltips_heavy_bulky'
                                      })}
                                      getPopupContainer={(trigger) =>
                                        trigger.parentElement.parentElement
                                          .parentElement.parentElement
                                      }
                                      autoAdjustOverflow={true}
                                      arrowPointAtCenter={true}
                                    >
                                      <TooltipsIcon />
                                    </Tooltip>
                                  </span>
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        )}

                      <BRFormItemSubTitle
                        name="itemsCount"
                        title={intl.formatMessage({
                          id: `shipments.new_order.form_labels.number_of_items`
                        })}
                        subtitle={intl.formatMessage({
                          id: `shipments.new_order.form_labels.number_of_items_sub_title${
                            orderTypeOption.packageType ===
                            PACKAGE_TYPES[2].value
                              ? '_bulky'
                              : ''
                          }`
                        })}
                        field={
                          <BRFormInputNumber
                            disabled={
                              orderData &&
                              !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                orderData?.state?.code
                              )
                            }
                            maxLength={10}
                            label={intl.formatMessage({
                              id: 'shipments.new_order.form_labels.number_of_items'
                            })}
                            filedName="itemsCount"
                            handleOnChangeForm={handleOnChangeForm}
                            autoComplete={`${Math.random()}`}
                            rules={[
                              {
                                required: !orderData,
                                label: intl.formatMessage({
                                  id: `shipments.new_order.form_labels.number_of_items`
                                })
                              }
                            ]}
                          />
                        }
                      />

                      <Form.Item
                        className="br-form-text-area-label "
                        name="description"
                        rules={[
                          {
                            label: intl.formatMessage({
                              id: `shipments.new_order.form_labels.package_description`
                            })
                          }
                        ]}
                      >
                        <BRFormInputCounter
                          label={intl.formatMessage({
                            id: `shipments.new_order.form_labels.package_description`
                          })}
                          maxCount={500}
                          optional
                        >
                          <Input.TextArea
                            disabled={
                              orderData &&
                              !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                orderData?.state?.code
                              )
                            }
                            maxLength={500}
                            placeholder={
                              isBulky && bulkyType === PACKAGE_TYPES_BULKY[0]
                                ? intl.formatMessage({
                                    id: `shipments.new_order.form_placeholder.description_placeholder_ligth_bulky`
                                  })
                                : isBulky &&
                                  bulkyType === PACKAGE_TYPES_BULKY[1]
                                ? intl.formatMessage({
                                    id: `shipments.new_order.form_placeholder.description_placeholder_heavy_bulky`
                                  })
                                : intl.formatMessage({
                                    id: `shipments.new_order.form_placeholder.description_placeholder_${orderTypeOption?.packageType?.toLowerCase()}`
                                  })
                            }
                            autoSize={{ minRows: 2.8, maxRows: 2.8 }}
                            autoComplete={`${Math.random()}`}
                            allowClear
                          />
                        </BRFormInputCounter>
                      </Form.Item>
                      {orderTypeOption.packageType === PACKAGE_TYPES[1].value &&
                        selectedBusinessType === 'B2B' && (
                          <Checkbox
                            onChange={OnChangeSignAndReturnCheckBox}
                            className="br-order-details__sign-and-return"
                            checked={isSignAndCollectChecked}
                            disabled={orderData}
                          >
                            {intl.formatMessage({
                              id: `shipments.new_order.form_labels.sign_and_collect`
                            })}
                            <Tooltip
                              getPopupContainer={(trigger) =>
                                trigger.parentElement.parentElement
                                  .parentElement
                              }
                              title={intl.formatMessage({
                                id: `shipments.new_order.form_labels.sign_and_collect_tooltip`
                              })}
                            >
                              <Icon component={TooltipsIcon} />
                            </Tooltip>
                          </Checkbox>
                        )}
                    </div>
                    <div className="br-form-group">
                      <BRFormItemSubTitle
                        name="businessReference"
                        title={intl.formatMessage({
                          id: `shipments.new_order.form_labels.order_reference`
                        })}
                        subtitle={intl.formatMessage({
                          id: `shipments.new_order.form_labels.order_reference_sub_title`
                        })}
                        rules={[
                          {
                            label: intl.formatMessage({
                              id: `shipments.new_order.form_labels.order_reference`
                            })
                          }
                        ]}
                        optional
                        field={
                          <Input
                            disabled={
                              orderData &&
                              !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                orderData?.state?.code
                              )
                            }
                            placeholder={intl.formatMessage({
                              id: `shipments.new_order.form_placeholder.order_reference_placeholder${
                                orderTypeOption.packageType ===
                                PACKAGE_TYPES[2].value
                                  ? '_bulky'
                                  : ''
                              }`
                            })}
                            autoComplete={`${Math.random()}`}
                            maxLength={100}
                            allowClear
                          />
                        }
                      />

                      {(formRef.current &&
                        formRef.current.getFieldValue('packageType') ===
                          PACKAGE_TYPES[2].value) ||
                        (orderTypeOption.type !==
                          DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP &&
                          !isSignAndCollectChecked && (
                            <Form.Item
                              className={classnames(
                                'br-form-without-label',
                                'br-order-details__allow-customer-open-packages-row',
                                {
                                  'br-order-details__allow-customer-open-packages-column':
                                    orderTypeOption.type ===
                                    DELIVERY_TYPES_CODES.EXCHANGE
                                }
                              )}
                              name="allowToOpenPackage"
                              valuePropName="checked"
                            >
                              <Checkbox
                                disabled={orderData}
                                onChange={onChangeAllowOpenPackage}
                              >
                                <div className="br-order-details__allow-customer-open-packages__content">
                                  <span className="br-order-details__allow-customer-open-packages__content__title">
                                    {intl.formatMessage({
                                      id: `shipments.new_order.form_labels.open_package`
                                    })}
                                    <Tooltip
                                      getPopupContainer={(trigger) =>
                                        trigger.parentElement.parentElement
                                          .parentElement
                                      }
                                      title={intl.formatMessage({
                                        id: `shipments.new_order.tooltips.open_package_tooltip`
                                      })}
                                    >
                                      <Icon component={TooltipsIcon} />
                                    </Tooltip>
                                  </span>
                                  {showOpenPackageFeesNote && (
                                    <p>
                                      {intl.formatMessage(
                                        {
                                          id: `shipments.new_order.order_details.open_package_fees_note`
                                        },
                                        {
                                          value: getCurrency(
                                            openPackageFees.amount
                                          ).localized
                                        }
                                      )}
                                    </p>
                                  )}
                                </div>
                              </Checkbox>
                            </Form.Item>
                          ))}
                      {(orderTypeOption.type ===
                        DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
                        isSignAndCollectChecked) &&
                        handleReturnLocation()}
                      {isMultiPackageAvailable() &&
                        ALLOWED_TYPES_FOR_MULTI_PACKAGE.includes(
                          orderTypeOption.type
                        ) && (
                          <BRFormInputs
                            rules={[
                              rangeRule({
                                message: intl.formatMessage(
                                  {
                                    id: 'shipments.new_order.order_details.multi_packages_error'
                                  },
                                  {
                                    min: MULTI_PACKAGES_RANGE.MIN,
                                    max: MULTI_PACKAGES_RANGE.MAX
                                  }
                                ),
                                min: MULTI_PACKAGES_RANGE.MIN,
                                max: MULTI_PACKAGES_RANGE.MAX
                              })
                            ]}
                            inputTitle={intl.formatMessage({
                              id: `shipments.new_order.form_labels.multi_packages`
                            })}
                            type={NUMBER_TEXT}
                            formRef={formRef}
                            name="multiPackages"
                            hasFeedback
                          />
                        )}
                    </div>
                  </div>

                  {orderTypeOption.type === DELIVERY_TYPES_CODES.EXCHANGE && (
                    <div className="br-order-details__return-package">
                      <BRFormItemSubTitle
                        name="returnItemsCount"
                        title={intl.formatMessage({
                          id: `shipments.new_order.form_labels.number_of_items`
                        })}
                        subtitle={intl.formatMessage({
                          id: `shipments.new_order.form_labels.number_of_items_sub_title`
                        })}
                        rules={[
                          {
                            required: !orderData,
                            label: intl.formatMessage({
                              id: `shipments.new_order.form_labels.number_of_items`
                            })
                          }
                        ]}
                        field={
                          <BRFormInputNumber
                            disabled={
                              orderData &&
                              !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                orderData?.state?.code
                              )
                            }
                            maxLength={10}
                            label={intl.formatMessage({
                              id: 'shipments.new_order.form_labels.number_of_items'
                            })}
                            filedName="returnItemsCount"
                            handleOnChangeForm={handleOnChangeForm}
                            autoComplete={`${Math.random()}`}
                          />
                        }
                      />

                      <Form.Item
                        className="br-form-text-area-label "
                        name="returnDescription"
                        rules={[
                          {
                            label: intl.formatMessage({
                              id: `shipments.new_order.form_labels.package_description`
                            })
                          }
                        ]}
                      >
                        <BRFormInputCounter
                          label={intl.formatMessage({
                            id: `shipments.new_order.form_labels.package_description`
                          })}
                          maxCount={500}
                          optional
                        >
                          <Input.TextArea
                            disabled={
                              orderData &&
                              !ALLOWED_STATES_TO_EDIT_ORDER.includes(
                                orderData?.state?.code
                              )
                            }
                            maxLength={500}
                            placeholder={intl.formatMessage({
                              id: `shipments.new_order.form_placeholder.description_placeholder`
                            })}
                            autoSize={{ minRows: 2.8, maxRows: 2.8 }}
                            autoComplete={`${Math.random()}`}
                            allowClear
                          />
                        </BRFormInputCounter>
                      </Form.Item>
                      {handleReturnLocation()}
                    </div>
                  )}
                  {[
                    DELIVERY_TYPES_CODES.EXCHANGE,
                    DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP,
                    DELIVERY_TYPES_CODES.SEND
                  ].includes(orderTypeOption.type) && (
                    <ItemValue formRef={formRef} />
                  )}
                </div>
                {orderData && (
                  <div className="br-form-row">
                    <Form.Item
                      className="mt-1 mb-1"
                      name="pricingPackageSize"
                      label={intl.formatMessage({
                        id: `shipments.new_order.form_labels.flyer_size`
                      })}
                      rules={[{ required: !orderData }]}
                    >
                      <Select
                        placeholder={intl.formatMessage({
                          id: `form.select_placeholder`
                        })}
                      >
                        {pricingSizes?.map((item) => (
                          <Select.Option value={item.name} key={item.name}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(OrderDetails);
