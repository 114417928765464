import XlsxPopulate from 'xlsx-populate';
import Papa from 'papaparse';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';

import {
  FILE_SHEET_UPLOAD_ERROR_TYPES,
  UPLOADED_FILE_TYPES
} from 'constants/upload';
import {
  PO_PRODUCT_HEADER_MAPPING,
  PO_PRODUCT_OBJ_MAPPING,
  SHEET_MAX_NUM_OF_PO_PRODUCTS
} from 'constants/fulfillment';
import { AMAZON_TIPS_SHEET } from 'constants/cod-reports';

const { XLSX, CSV } = UPLOADED_FILE_TYPES;

export const getCSVRowsData = (convertedCSV, mappingByIndex) => {
  const { data } = convertedCSV;
  const allRows = data.filter(
    (row) => Object.keys(omitBy(row, (item) => !item)).length
  );

  if (!mappingByIndex) {
    return allRows;
  } else {
    const rows = [];
    allRows.forEach((cells, index) => {
      if (index > 0) {
        const record = {};
        cells.forEach((cell, i) => {
          record[mappingByIndex[i]] = cell === '' ? undefined : cell;
        });
        rows.push(record);
      }
    });
    return rows;
  }
};

export const getXlsxRowsData = (sheetRecords, mappingByIndex) => {
  const allRows = sheetRecords
    .slice(1)
    .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
    .map((row) => {
      const record = {};
      const r = row.map((cell, index) => {
        const c =
          cell instanceof XlsxPopulate.RichText
            ? cell.text().replace(/undefined/g, '')
            : cell;

        if (mappingByIndex) {
          record[mappingByIndex[index]] = c;
        }
        return c;
      });

      return mappingByIndex ? record : r;
    });

  return allRows;
};

export const getSheetFileError = ({
  header,
  rowsData,
  headerMapping,
  sheetType
}) => {
  const { INVALID_FILE, EMPTY_FILE, EXCEEDED_MAX_ROWS } =
    FILE_SHEET_UPLOAD_ERROR_TYPES;
  if (
    !isEqual(
      headerMapping || PO_PRODUCT_HEADER_MAPPING,
      header?.map((colName) =>
        isString(colName) ? colName.split(/\n|\r/)[0] : colName
      )
    )
  ) {
    return {
      errorType: INVALID_FILE
    };
  } else if (rowsData?.length < 1) {
    return {
      errorType: EMPTY_FILE
    };
  } else if (
    rowsData?.length > SHEET_MAX_NUM_OF_PO_PRODUCTS &&
    sheetType !== AMAZON_TIPS_SHEET
  ) {
    return {
      errorType: EXCEEDED_MAX_ROWS,
      rowsData
    };
  }

  return null;
};

export const convertXlsxToJSON = (file, { headerMapping, sheetType } = {}) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      const sheetRecords = workbook.sheet(0).usedRange().value();

      const rowsData = getXlsxRowsData(
        sheetRecords,
        headerMapping || PO_PRODUCT_OBJ_MAPPING
      );
      const header = sheetRecords[0].map((cell) =>
        cell instanceof XlsxPopulate.RichText
          ? cell.text().replace(/undefined/g, '')
          : cell
      );
      const error = getSheetFileError({
        header,
        rowsData,
        headerMapping,
        sheetType
      });

      if (error) {
        reject(error);
      }
      return resolve(rowsData);
    });
  });
};

export const convertCSVToJSON = (
  file,
  config = {
    dynamicTyping: true
  }
) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      config,
      complete: (result) => {
        const header = result?.data?.[0];
        const rowsData = getCSVRowsData(result, PO_PRODUCT_OBJ_MAPPING);
        const error = getSheetFileError({
          header,
          rowsData
        });
        if (error) {
          reject(error);
        }
        return resolve(rowsData);
      }
    });
  });
};

export const convertSheetToJSON = (file) => {
  switch (file?.type) {
    case XLSX:
      return convertXlsxToJSON(file);

    case CSV:
      return convertCSVToJSON(file);

    default:
      return;
  }
};

export const formatImages = (images) => {
  let formattedimages = [];
  for (let i = 0; i < images.attachments.length; i++) {
    formattedimages.push({
      image: images.attachments[i],
      type: images.attachmentsTypes[i]
    });
  }
  return formattedimages;
};
