import { Typography } from 'antd';

import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { DELIVERIES_TYPES, formatAddress } from 'constants/pickups';

import DetailsGrid from 'components/Pickups/components/PickupDetails/components/DetailsGrid/DetailsGrid';

import { ReactComponent as RequestDetailsIcon } from 'assets/bosta-icons/pickup-requests.svg';

const formatPickupAddress = (pickup) => {
  if (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])) {
    return pickup.type === DELIVERIES_TYPES.CUSTOMER_RETURN_PICKUP ||
      pickup.type === DELIVERIES_TYPES.SIGN_RETURN_PICKUP
      ? formatAddress(pickup?.consigneeAddress)
      : formatAddress(pickup?.business?.address);
  }
  return '-';
};

export default function RequestDetailsTable({ pickup }) {
  const { numberOfParcels, contactPerson, type, packageType, business, notes } =
    pickup;

  return (
    <DetailsGrid
      strokedIcon
      icon={<RequestDetailsIcon />}
      title={
        <>
          Request Details <q>By Business</q>
        </>
      }
    >
      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          #Requested Packages
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {numberOfParcels ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Contact Person
        </Typography.Title>

        <Typography.Paragraph className="br-pickup-details-content__value">
          {contactPerson?.name ?? '-'}
          {contactPerson && (
            <Typography.Text>{contactPerson.phone}</Typography.Text>
          )}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Pickup Type
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {type ?? '-'}
        </Typography.Paragraph>
      </div>

      <div>
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Package Type
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {packageType ?? '-'}
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Pickup Address
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {business?.address
            ? `${
                business?.address.district
                  ? `${business?.address.district.name},`
                  : ''
              } ${business?.address.city.name}`
            : '-'}
          {business?.address?.firstLine && (
            <Typography.Text>{formatPickupAddress(pickup)}</Typography.Text>
          )}
        </Typography.Paragraph>
      </div>

      <div className="double-width">
        <Typography.Title
          level={5}
          className="br-pickup-details-content__title"
        >
          Pickup Notes
        </Typography.Title>
        <Typography.Paragraph className="br-pickup-details-content__value">
          {notes ?? '-'}
        </Typography.Paragraph>
      </div>
    </DetailsGrid>
  );
}
