import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Skeleton, Tooltip } from 'antd';

import { getAllDeliveries } from 'actions/DeliveriesActions';
import {
  exportHubDeliveries,
  exportInTransitReport,
  exportSortingFacilityData,
  fetchDeliveriesCount,
  getCurrentActiveDate,
  getSortingFacilityData,
  getSortingFacilityDeliveries
} from 'services/ops-control';
import { fetchBusinesses } from 'services/business';
import { exportXlsx } from 'services/deliveries';
import {
  CURRENT_DATE_PARAMS,
  DATE_FORMAT,
  DELIVERIES_TABLE_QUICK_FILTERS,
  DELIVERIES_TYPE,
  ORDER_STATES,
  QUICK_FILTERS_VALUES,
  RECEIVED_AT_SF_CARDS_INFO,
  SORTING_FACILITY_FILTERS,
  SORTING_FACILITY_LOST_OR_DAMAGED_DELIVERIES_COUNT,
  SORTING_FACILITY_PENDING_DELIVERIES_COUNT,
  TABLE_COLUMNS,
  TO_SORTING_FACILITY_CARDS_IFNO
} from 'constants/sorting-facility';
import { PENDING_TRANSIT_DATE_FORMAT } from 'constants/pending-transit';

import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import { CAIRO_SORTING_FACILITY_ID, ORDER_TYPES } from 'constants/hubs';
import { sumOfTotalReceived } from 'utils/sorting-facility';
import { getPaginationCount } from 'utils/helpers';
import { downloadFromUrl } from 'utils/download';

import { notify } from 'components/Notify/Notify';
import CrumbsBar from 'components/OpsControl/CrumbsBar/CrumbsBar';
import SortingFacilityFilter from 'components/OpsControl/SortingFacility/SortingFacilityFilter/SortingFacilityFilter';
import GeneralInfoCard from 'components/OpsControl/GeneralInfoCard/GeneralInfoCard';
import BRTable from 'components/BRTable/BRTable';
import SingleInfoCard from 'components/OpsControl/SingleCardInfo/SingleCardInfo';
import PreviousDateCard from 'components/OpsControl/PreviousDateCard/PreviousDateCard';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as SortingIcon } from 'assets/bosta-icons/shipment.svg';
import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as DispatchIcon } from 'assets/bosta-icons/dispatching.svg';
import { ReactComponent as MultiOrdersIcon } from 'assets/bosta-icons/multi-orders.svg';
import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as Download } from 'assets/bosta-icons/Download.svg';
import { ReactComponent as ClosedEye } from 'assets/bosta-icons/Eye-1.svg';

import './SortingFacility.less';

const SortingFacility = ({ intl }) => {
  const [currentTab, setCurrentTab] = useState(QUICK_FILTERS_VALUES.ALL);
  const [initialSelectedDate, setInitialSelectedDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [sortingFacilityData, setSortingFacilityData] = useState(null);
  const [filters, setFilters] = useState();
  const [businessesOptions, setBusinessesOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [delieveriesCounts, setDelieveriesCounts] = useState();
  const [isDeliveriesCountLoading, setIsDeliveriesCountLoading] =
    useState(false);
  const [showDeliveriesCount, setShowDeliveriesCount] = useState(false);
  const [filtersValue, setFiltersValue] = useState();

  const refreshTable = useRef();

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const getTablePayload = ({
    limit,
    orderState,
    business,
    orderType,
    searchInputText,
    quickFiltersValue,
    isExport
  }) => {
    return isExport
      ? {
          ...(currentTab === QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR && {
            minOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR,
            maxOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR
          }),
          ...(currentTab === QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS && {
            minOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS,
            maxOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS
          }),
          ...(currentTab === QUICK_FILTERS_VALUES.TWO_HOURS_PLUS && {
            minOrderAgeInHours: QUICK_FILTERS_VALUES.TWO_HOURS_PLUS
          }),
          date: selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT),
          states: orderState,
          businessIds: business,
          trackingNumberOrSealNumber: searchInputText,
          types: orderType,
          hubId: CAIRO_SORTING_FACILITY_ID
        }
      : {
          ...(quickFiltersValue === QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR && {
            minOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR,
            maxOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_ONE_HOUR
          }),
          ...(quickFiltersValue === QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS && {
            minOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS,
            maxOrderAgeInHours: QUICK_FILTERS_VALUES.EXCEED_TWO_HOURS
          }),
          ...(quickFiltersValue === QUICK_FILTERS_VALUES.TWO_HOURS_PLUS && {
            minOrderAgeInHours: QUICK_FILTERS_VALUES.TWO_HOURS_PLUS
          }),
          date: selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT),
          states: orderState,
          businessIds: business,
          trackingNumberOrSealNumber: searchInputText,
          types: orderType,
          hubId: CAIRO_SORTING_FACILITY_ID,
          limit: limit
        };
  };

  const getCurrentinitalDate = async () => {
    const payload = { pageName: CURRENT_DATE_PARAMS.SOTRING_FACILITY };
    try {
      const res = await getCurrentActiveDate(payload);
      setInitialSelectedDate(dayjs(res?.data?.currentDate));
      setSelectedDate(dayjs(res?.data?.currentDate));
      refreshTable.current();
    } catch (error) {
      notify(error.message);
    }
  };

  const getDeliveriesCount = async () => {
    setIsDeliveriesCountLoading(true);

    try {
      const countPayload = getTablePayload({
        orderState: filters?.orderState,
        business: filters?.business,
        orderType: filters?.orderType,
        searchInputText: filters?.searchInputText,
        isExport: true,
        isCount: true
      });
      const res = await fetchDeliveriesCount({
        ...countPayload
      });
      setDelieveriesCounts(res?.data?.count);
      setShowDeliveriesCount(true);
    } catch (error) {
      notify(error.message);
    }

    setIsDeliveriesCountLoading(false);
  };

  const exportTransferredFromHubsData = async ({ isDispatching }) => {
    try {
      const payload = {
        date: dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(!isDispatching
          ? { destinationHubIds: [CAIRO_SORTING_FACILITY_ID] }
          : { sourceHubIds: [CAIRO_SORTING_FACILITY_ID] })
      };
      const res = await exportHubDeliveries(CAIRO_SORTING_FACILITY_ID, payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchSortingFacilityData = async () => {
    try {
      setLoading(true);
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      const payload = { date: formattedDate };
      const { data } = await getSortingFacilityData(payload);
      setSortingFacilityData(data);
      setLoading(false);
    } catch (error) {
      notify(error.msg);
    }
    setLoading(false);
  };

  const formatTableData = (data) => {
    const formattedData = data.map((order) => {
      const lastReceived = dayjs(order?.received_at)?.format(DATE_FORMAT);

      const picked_up = dayjs(order?.picked_up_at)?.format(DATE_FORMAT);

      return {
        tracking_number: (
          <Link
            to={`/deliveries/${order?.tracking_number}/details`}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {order?.tracking_number}
          </Link>
        ),
        business_name: order?.business_name,
        order_type: order?.formatted_type,
        order_state: order?.state,
        picked_up: picked_up,
        last_receive: lastReceived,
        seal_number:
          order.seal_number || intl.formatMessage({ id: 'common.empty_field' })
      };
    });
    return formattedData;
  };

  const fetchBusinessesData = async () => {
    try {
      const businessesOptions = await fetchBusinesses();
      setBusinessesOptions(
        businessesOptions?.map((business) => {
          return { label: business.name, value: business._id };
        })
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchTableData = async ({
    page,
    limit,
    orderState,
    business,
    orderType,
    searchInputText,
    quickFiltersValue
  }) => {
    if (initialSelectedDate) {
      const payload = getTablePayload({
        page,
        limit,
        orderState,
        business,
        orderType,
        searchInputText,
        quickFiltersValue,
        isExport: false
      });
      setShowDeliveriesCount(false);

      const res = await getSortingFacilityDeliveries({
        ...payload
      });
      setFiltersValue({
        business: business,
        orderState: orderState,
        orderType: orderType,
        searchInputText: searchInputText
      });

      setCurrentTab(quickFiltersValue);
      setFilters({
        orderState: orderState,
        orderType: orderType,
        business: business,
        searchInputText: searchInputText
      });
      return {
        list: formatTableData(res.data),
        total: getPaginationCount({
          page,
          result: res.data,
          pageSize: limit
        })
      };
    }
  };

  const handleUrlDownload = () => {
    downloadFromUrl(sortingFacilityData?.url, 'orders');
  };

  const exportCardsData = async ({ deliveryReceivedState, states }) => {
    const payload = {
      ...(deliveryReceivedState && {
        deliveryReceivedState: deliveryReceivedState
      }),
      ...(states && { states: states }),
      date: selectedDate.format(PENDING_TRANSIT_DATE_FORMAT),
      hubId: CAIRO_SORTING_FACILITY_ID
    };
    try {
      const res = await exportSortingFacilityData(payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const exportOrders = async () => {
    try {
      setIsExporting(true);
      const payload = getTablePayload({
        orderState: filters?.orderState,
        business: filters?.business,
        orderType: filters?.orderType,
        searchInputText: filters?.searchInputText,
        isExport: true
      });
      const res = await exportSortingFacilityData(payload);
      notify(res.message, 'success');
      setIsExporting(false);
    } catch (error) {
      notify(error.message);
    }
    setIsExporting(false);
  };

  useEffect(async () => {
    await fetchBusinessesData();
    getCurrentinitalDate();
  }, []);

  useEffect(() => {
    if (initialSelectedDate) {
      fetchSortingFacilityData();
    }
  }, [selectedDate]);

  return (
    <div className="sorting-facility__sorting-facility-container">
      <div className="sorting-facility__sorting-facility-header">
        <p className="heading">
          {intl.formatMessage({ id: 'ops_control.sorting_facility_label' })}
        </p>
        <SortingFacilityFilter
          loading={loading}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          initialDate={initialSelectedDate}
        />
      </div>
      <div className="sorting-facility__backlog-banner">
        <div className="sorting-facility__backlog-number">
          <h3 className="display-xs">
            {intl.formatMessage({
              id: 'ops_control.sorting_facility.current_backlog'
            })}
          </h3>
          <Tooltip
            title={intl.formatMessage({
              id: 'ops_control.sorting_facility.tooltips.backlog_tooltip'
            })}
          >
            <TooltipIcon />
          </Tooltip>
          <h3 className="display-sm parcels-amount">
            {intl.formatMessage(
              { id: 'ops_control.sorting_facility.parcels' },
              { parcels: sortingFacilityData?.old_received_count || 0 }
            )}
          </h3>
        </div>
        <BRButton
          disabled={!sortingFacilityData?.old_export_url}
          label={'Export'}
          prefixIcon={<Download />}
          onClick={() => {
            downloadFromUrl(
              sortingFacilityData?.old_export_url,
              intl.formatMessage({
                id: 'ops_control.sorting_facility.current_backlog'
              })
            );
          }}
        />
      </div>
      <div className="sorting-facility__general-cards-container">
        <GeneralInfoCard
          initialDate={initialSelectedDate}
          selectedDate={selectedDate}
          loading={loading}
          cardsData={TO_SORTING_FACILITY_CARDS_IFNO(
            sortingFacilityData,
            exportTransferredFromHubsData
          )}
          Icon={SortingIcon}
          title={intl.formatMessage({
            id: 'ops_control.sorting_facility.to_sorting_facility'
          })}
        />
        <GeneralInfoCard
          selectedDate={selectedDate}
          initialDate={initialSelectedDate}
          loading={loading}
          cardsData={RECEIVED_AT_SF_CARDS_INFO(
            sortingFacilityData,
            exportCardsData
          )}
          Icon={CircleCheck}
          title={intl.formatMessage({
            id: 'ops_control.sorting_facility.recieved_at_sorting_facility'
          })}
          total={intl.formatMessage(
            {
              id: 'ops_control.sorting_facility.total_recieved_at_sf'
            },
            { total: sumOfTotalReceived(sortingFacilityData) }
          )}
        />
      </div>
      <div className="sorting-facility__dispatching-card">
        <div className="sorting-facility__dispatching__header">
          <DispatchIcon />
          <h3 className="display-xs sorting-facility__dispatching-title">
            {intl.formatMessage({
              id: 'ops_control.dispatching.dispatching_title'
            })}
          </h3>

          {loading ? (
            <Skeleton active paragraph={{ rows: 0 }} />
          ) : (
            <h3 className="sorting-facility__dispatching-total display-xs">
              {intl.formatMessage(
                {
                  id: 'ops_control.dispatching.over_all_recieved'
                },
                { total: sortingFacilityData?.overall_received_count || 0 }
              )}
            </h3>
          )}
        </div>
        <CrumbsBar />
        <div className="sorting-facility__crumb-bar-data">
          <div>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <h4 className="display-xs">
                {sortingFacilityData?.ready_to_dispatch_deliveries_count || 0}
                <span className="caption">
                  {intl.formatMessage(
                    {
                      id: 'ops_control.dispatching.seals_number'
                    },
                    {
                      seals_number:
                        sortingFacilityData?.ready_to_dispatch_seals_count || 0
                    }
                  )}
                </span>
                <span className="export-data-button">
                  {!dayjs(selectedDate)?.isBefore(
                    initialSelectedDate,
                    'day'
                  ) && (
                    <Download
                      onClick={() =>
                        exportCardsData({
                          states: [ORDER_STATES.READY_TO_DISPATCH]
                        })
                      }
                    />
                  )}
                </span>
              </h4>
            )}
            <h6>
              {intl.formatMessage({
                id: 'ops_control.dispatching.ready_to_dispatch'
              })}
              <Tooltip
                title={intl.formatMessage({
                  id: 'ops_control.sorting_facility.tooltips.ready_to_dispatch'
                })}
              >
                <TooltipIcon />
              </Tooltip>
            </h6>
          </div>
          <div>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <h4 className="display-xs">
                {sortingFacilityData?.in_transit_deliveries_count || 0}
                <span className="caption">
                  {intl.formatMessage(
                    {
                      id: 'ops_control.dispatching.seals_number'
                    },
                    {
                      seals_number:
                        sortingFacilityData?.in_transit_seals_count || 0
                    }
                  )}
                </span>
                <span className="export-data-button">
                  {!dayjs(selectedDate)?.isBefore(
                    initialSelectedDate,
                    'day'
                  ) && (
                    <Download
                      onClick={() =>
                        exportTransferredFromHubsData({ isDispatching: true })
                      }
                    />
                  )}
                </span>
              </h4>
            )}
            <h6>
              {intl.formatMessage({
                id: 'ops_control.dispatching.dispatched'
              })}
              <Tooltip
                title={intl.formatMessage({
                  id: 'ops_control.sorting_facility.tooltips.dispatched'
                })}
              >
                <TooltipIcon />
              </Tooltip>
            </h6>
          </div>
          <div>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <h4 className="display-xs">
                {sortingFacilityData?.completed_deliveries_count || 0}
                <span className="caption">
                  {intl.formatMessage(
                    {
                      id: 'ops_control.dispatching.seals_number'
                    },
                    {
                      seals_number:
                        sortingFacilityData?.completed_seals_count || 0
                    }
                  )}
                </span>
              </h4>
            )}
            <h6>
              {intl.formatMessage({
                id: 'ops_control.dispatching.completed'
              })}
              <Tooltip
                title={intl.formatMessage({
                  id: 'ops_control.sorting_facility.tooltips.completed'
                })}
              >
                <TooltipIcon />
              </Tooltip>
            </h6>
          </div>
        </div>
        <div className="sorting-facility__dispatching-info-container">
          <div className="sorting-facility__pending_delievries">
            {SORTING_FACILITY_PENDING_DELIVERIES_COUNT(
              sortingFacilityData,
              exportCardsData
            ).map((el) => {
              return (
                <SingleInfoCard
                  initialDate={initialSelectedDate}
                  selectedDate={selectedDate}
                  loading={loading}
                  title={el.title}
                  data={el.data}
                  type={DELIVERIES_TYPE.PENDING}
                  tooltip={el.tooltip}
                  exportFunction={el?.exportFunction}
                  states={el?.states}
                />
              );
            })}
          </div>
          <div className="sorting-facility__lost-or-damaged-deliveries">
            {SORTING_FACILITY_LOST_OR_DAMAGED_DELIVERIES_COUNT(
              sortingFacilityData,
              exportCardsData
            ).map((el) => {
              return (
                <SingleInfoCard
                  initialDate={initialSelectedDate}
                  selectedDate={selectedDate}
                  loading={loading}
                  title={el.title}
                  data={el.data}
                  type={DELIVERIES_TYPE.DAMAGED}
                  tooltip={el.tooltip}
                  exportFunction={el.exportFunction}
                  states={el.states}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="sorting-facility__orderes-overview-table">
        {sortingFacilityData?.url ? (
          <PreviousDateCard
            loading={isExporting}
            downloadFunction={handleUrlDownload}
            exportButtonTitle={intl.formatMessage({ id: 'common.export' })}
            Icon={MultiOrdersIcon}
            header={intl.formatMessage({
              id: 'ops_control.orders_table.header'
            })}
            exportHeader={intl.formatMessage({
              id: 'ops_control.orders_table.export_orders'
            })}
            discreption={intl.formatMessage({
              id: 'ops_control.orders_table.export_orders_discreption'
            })}
          />
        ) : (
          <BRTable
            shareMethods={acceptMethods}
            initialQuickFilter={currentTab}
            quickFilters={DELIVERIES_TABLE_QUICK_FILTERS}
            title={
              <div>
                <div className="ops_control__table-header title-sm">
                  <MultiOrdersIcon />
                  <span>
                    {intl.formatMessage({
                      id: 'ops_control.orders_table.header'
                    })}
                  </span>
                  <span className="ml-2">
                    {showDeliveriesCount ? (
                      delieveriesCounts
                    ) : (
                      <BRButton
                        label={intl.formatMessage({
                          id: 'pickups.pickup_request.show_count'
                        })}
                        loading={isDeliveriesCountLoading}
                        prefixIcon={<ClosedEye />}
                        onClick={getDeliveriesCount}
                      />
                    )}
                  </span>
                </div>
              </div>
            }
            columns={TABLE_COLUMNS}
            listFunction={fetchTableData}
            tableFilters={SORTING_FACILITY_FILTERS(businessesOptions)}
            showFilter
            searchPlaceholder={intl.formatMessage({
              id: 'ops_control.orders_table.search_placeholder'
            })}
            showSearch
            pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
            exportListFileFunction={exportOrders}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(SortingFacility);
