import React from 'react';
import { useIntl } from 'react-intl';

import BRTable from 'components/BRTable/BRTable';
import DetailsGrid from 'components/Pickups/components/PickupDetails/components/DetailsGrid/DetailsGrid';

import { ReactComponent as DeliveriesIcon } from 'assets/bosta-icons/Orders.svg';

import './DeliveriesTable.less';

const PAGE_SIZE = 5;

export default function DeliveriesTable({ pickup }) {
  const intl = useIntl();

  const columns = [
    {
      title: 'Tracking number',
      dataIndex: 'trackingNumber',
      render: (trackingNumber, delivery) => {
        return delivery.isMissing ? (
          <div>
            {trackingNumber}
            <span className="br-pickup-details__missed-delivery-tag">
              Missing
            </span>
          </div>
        ) : (
          trackingNumber
        );
      }
    },
    {
      title: 'Customer Info',
      dataIndex: 'receiver',
      render: (receiver) => (
        <>
          <p>
            {receiver?.firstName} {receiver?.lastName}
          </p>
          <p>{receiver?.phone}</p>
        </>
      )
    },
    {
      title: 'Order type',
      dataIndex: 'type'
    },
    {
      title: 'Dropoff location',
      dataIndex: 'dropOffAddress',
      render: (dropOffAddress) => (
        <>
          <p>{dropOffAddress?.firstLine}</p>
          <p>
            {`${dropOffAddress?.district?.name || 'N/A'}${
              dropOffAddress?.zone?.name ? `-${dropOffAddress?.zone?.name}` : ''
            },${dropOffAddress?.city?.name || 'N/A'}`}
          </p>
        </>
      )
    }
  ];

  const hasDeliveryData = !!pickup?.deliveries && pickup.deliveries.length > 0;

  return (
    <DetailsGrid
      icon={<DeliveriesIcon />}
      title={intl.formatMessage(
        { id: 'pickups.pickup_details.deliveries' },
        { count: pickup?.deliveries?.length }
      )}
    >
      {hasDeliveryData && (
        <BRTable
          className="full-width"
          listingData={pickup?.deliveries ?? []}
          columns={columns}
          showFilter={false}
          pageSizeOptions={Array.from({ length: 5 }).map(
            (_, idx) => (idx + 1) * PAGE_SIZE
          )}
          pageLimit={PAGE_SIZE}
          onRowClick={(item) => {}}
          withOutCheckBoxes
        />
      )}
    </DetailsGrid>
  );
}
