import { useContext, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { OpsControl } from 'contexts/ops-control.context';
import {
  HUBS_TABLE_COLUMNS,
  PENDING_TRANSIT_DATE_FORMAT,
  TOGGLE_BUTTON_INITAL_VALUE,
  TOGGLE_BUTTONS_LIST
} from 'constants/pending-transit';
import {
  exportInTransitReport,
  getCurrentActiveDate,
  inTransitDeliveriesCount
} from 'services/ops-control';
import { getPaginationCount } from 'utils/helpers';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import { downloadAsXlsx } from 'utils/download';
import {
  CURRENT_DATE_PARAMS,
  DELIVERIES_TYPE
} from 'constants/sorting-facility';

import PendingTransitFilter from './PendingTransitFilter/PendingTransitFilter';
import BRToggleButton from 'components/BRToggleButton/BRToggleButton';
import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import HubDeliveries from './HubDeliveries/HubDeliveries';

import { ReactComponent as HubsIcon } from 'assets/bosta-icons/hubs.svg';

import './PendingTransit.less';

const PendingTransit = ({ intl }) => {
  useEffect(() => {
    getCurrentinitalDate();
  }, []);

  const [currentHub, setCurrentHub] = useState();
  const [toggleButtonValue, setToggleButtonValue] = useState(
    TOGGLE_BUTTON_INITAL_VALUE
  );
  const [HubDetails, setHubDetails] = useState();
  const [totalPendingTransit, setTotalPendingTransit] = useState(0);
  const [initalDate, setInitalDate] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPendingTransitPlus, setTotalPendingTrasitPlus] = useState(0);
  const [investigation, setInvestigation] = useState(0);
  const [exceededInvestigation, setExceededInvestigation] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const { allHubs } = useContext(OpsControl);

  const getPendingValues = ({
    toggleButton,
    el,
    pendingFromForwardValue,
    pendingToForwardValue,
    PendingFromReturnValue,
    pendingToReturnValue,
    maxOrderForwardAging,
    maxOrderReturnAging,
    receivedForwardDate,
    receivedReturnDate
  }) => {
    const values = {
      pendingFromValue: 0,
      pendingToValue: 0,
      pendingFromPlusValue: 0,
      pendingToPlusValue: 0,
      maxOrderAging: '',
      investigation: 0,
      exceededInvestigation: 0,
      investigationFrom: 0,
      exceededInvestigationFrom: 0,
      investigationTo: 0,
      exceededInvestigationTo: 0
    };

    switch (toggleButton) {
      case TOGGLE_BUTTONS_LIST[1].value:
        values.pendingFromValue = pendingFromForwardValue;
        values.pendingToValue = pendingToForwardValue;
        values.pendingFromPlusValue =
          el?.outgoing_forward_deliveries_exceeded_duration;
        values.pendingToPlusValue =
          el?.incoming_forward_deliveries_exceeded_duration;
        values.maxOrderAging = maxOrderForwardAging;
        values.investigation =
          el.outgoing_forward_deliveries_investigation +
          el?.incoming_forward_deliveries_investigation;
        values.exceededInvestigation =
          el.outgoing_forward_deliveries_exceeded_investigation +
          el?.incoming_forward_deliveries_exceeded_investigation;
        values.investigationFrom =
          el?.outgoing_forward_deliveries_investigation;
        values.exceededInvestigationFrom =
          el?.outgoing_forward_deliveries_exceeded_investigation;
        values.investigationTo = el?.incoming_forward_deliveries_investigation;
        values.exceededInvestigationTo =
          el?.incoming_forward_deliveries_exceeded_investigation;
        break;

      case TOGGLE_BUTTONS_LIST[2].value:
        values.pendingFromValue = PendingFromReturnValue;
        values.pendingToValue = pendingToReturnValue;
        values.pendingFromPlusValue =
          el?.outgoing_return_deliveries_exceeded_duration;
        values.pendingToPlusValue =
          el?.incoming_return_deliveries_exceeded_duration;
        values.maxOrderAging = maxOrderReturnAging;
        values.investigation =
          el.outgoing_return_deliveries_investigation +
          el?.incoming_return_deliveries_investigation;
        values.exceededInvestigation =
          el.outgoing_return_deliveries_exceeded_investigation +
          el?.incoming_return_deliveries_exceeded_investigation;
        values.investigationFrom = el?.outgoing_return_deliveries_investigation;
        values.exceededInvestigationFrom =
          el?.outgoing_return_deliveries_exceeded_investigation;
        values.investigationTo = el?.incoming_return_deliveries_investigation;
        values.exceededInvestigationTo =
          el?.incoming_return_deliveries_exceeded_investigation;
        break;
      default:
        values.pendingFromValue =
          pendingFromForwardValue + PendingFromReturnValue;
        values.pendingToValue = pendingToForwardValue + pendingToReturnValue;
        values.pendingFromPlusValue =
          el?.outgoing_forward_deliveries_exceeded_duration +
          el?.outgoing_return_deliveries_exceeded_duration;
        values.pendingToPlusValue =
          el?.incoming_forward_deliveries_exceeded_duration +
          el?.incoming_return_deliveries_exceeded_duration;
        values.maxOrderAging =
          el?.oldest_received_at_forward || el?.oldest_received_at_return
            ? receivedForwardDate?.isBefore(receivedReturnDate)
              ? receivedForwardDate
              : receivedReturnDate
            : intl.formatMessage({ id: 'common.empty_field' });
        values.investigation =
          el.outgoing_return_deliveries_investigation +
          el?.outgoing_forward_deliveries_investigation +
          el.incoming_return_deliveries_investigation +
          el?.incoming_forward_deliveries_investigation;
        values.exceededInvestigation =
          el.outgoing_return_deliveries_exceeded_investigation +
          el?.outgoing_forward_deliveries_exceeded_investigation +
          el.incoming_return_deliveries_exceeded_investigation +
          el?.incoming_forward_deliveries_exceeded_investigation;
        values.investigationFrom =
          el?.outgoing_return_deliveries_investigation +
          el?.outgoing_forward_deliveries_investigation;
        values.exceededInvestigationFrom =
          el?.outgoing_return_deliveries_exceeded_investigation +
          el?.outgoing_forward_deliveries_exceeded_investigation;
        values.investigationTo =
          el?.incoming_forward_deliveries_investigation +
          el?.incoming_return_deliveries_investigation;
        values.exceededInvestigationTo =
          el?.incoming_forward_deliveries_exceeded_investigation +
          el?.incoming_return_deliveries_exceeded_investigation;
    }

    return values;
  };

  const formatTableData = (data, toggleButton, selectedHub) => {
    let totalPendingSum = 0;
    let totalPendingPlusSum = 0;
    let totalInvestigation = 0;
    let totalExceededInvestigation = 0;
    const formattedData = data?.map((el) => {
      const pendingFromForwardValue =
        el?.outgoing_forward_deliveries_not_exceeded_duration +
        el?.outgoing_forward_deliveries_exceeded_duration;

      const PendingFromReturnValue =
        el?.outgoing_return_deliveries_not_exceeded_duration +
        el?.outgoing_return_deliveries_exceeded_duration;

      const pendingToForwardValue =
        el?.incoming_forward_deliveries_not_exceeded_duration +
        el?.incoming_forward_deliveries_exceeded_duration;

      const pendingToReturnValue =
        el?.incoming_return_deliveries_not_exceeded_duration +
        el?.incoming_return_deliveries_exceeded_duration;

      const receivedReturnDate = el?.oldest_received_at_return
        ? dayjs(el?.oldest_received_at_return)
        : dayjs();

      const receivedForwardDate = el?.oldest_received_at_forward
        ? dayjs(el?.oldest_received_at_forward)
        : dayjs();

      const maxOrderForwardAging = el?.oldest_received_at_forward
        ? receivedForwardDate
        : intl.formatMessage({ id: 'common.empty_field' });

      const maxOrderReturnAging = el?.oldest_received_at_return
        ? receivedReturnDate
        : intl.formatMessage({ id: 'common.empty_field' });

      const {
        pendingFromValue,
        pendingToValue,
        pendingFromPlusValue,
        pendingToPlusValue,
        maxOrderAging,
        investigation,
        exceededInvestigation,
        investigationFrom,
        exceededInvestigationFrom,
        investigationTo,
        exceededInvestigationTo
      } = getPendingValues({
        toggleButton,
        el,
        pendingFromForwardValue,
        pendingToForwardValue,
        PendingFromReturnValue,
        pendingToReturnValue,
        maxOrderForwardAging,
        maxOrderReturnAging,
        receivedForwardDate,
        receivedReturnDate
      });
      const totalPending = selectedHub
        ? pendingFromValue +
          pendingToValue +
          investigation +
          exceededInvestigation +
          pendingFromPlusValue +
          pendingToPlusValue
        : pendingFromValue +
          investigationFrom +
          exceededInvestigationFrom +
          pendingFromPlusValue;
      const totalPendingPlus = selectedHub
        ? pendingFromPlusValue + pendingToPlusValue
        : pendingFromPlusValue;

      totalInvestigation += selectedHub ? investigation : investigationFrom;

      totalExceededInvestigation += selectedHub
        ? exceededInvestigation
        : exceededInvestigationFrom;

      totalPendingSum += totalPending;

      totalPendingPlusSum += totalPendingPlus;

      return {
        hub: (
          <div
            style={{ width: '200px' }}
            onClick={() => {
              setCurrentHub();
              setHubDetails({ id: el?.warehouse_id, name: el?.warehouse_name });
            }}
            className="hub-deliveries__hubname-column"
          >
            {el.warehouse_name}
          </div>
        ),
        pending_from:
          pendingFromValue +
          investigationFrom +
          exceededInvestigationFrom +
          pendingFromPlusValue,
        pending_to:
          pendingToValue +
          investigationTo +
          exceededInvestigationTo +
          pendingToPlusValue,
        pending_from_plus: pendingFromPlusValue,
        pending_to_plus: pendingToPlusValue,
        total_pending:
          pendingFromValue +
          pendingToValue +
          pendingFromPlusValue +
          pendingToPlusValue +
          investigationFrom +
          investigationTo +
          exceededInvestigationFrom +
          exceededInvestigationTo,
        total_pending_plus: pendingFromPlusValue + pendingToPlusValue,
        max_order_age:
          Math.ceil(dayjs().diff(dayjs(maxOrderAging), 'day', true)) ||
          intl.formatMessage({ id: 'common.empty_field' })
      };
    });
    setInvestigation(totalInvestigation);
    setExceededInvestigation(totalExceededInvestigation);
    setTotalPendingTransit(totalPendingSum);
    setTotalPendingTrasitPlus(totalPendingPlusSum);
    return formattedData;
  };

  const changeToggleButton = (value) => {
    setToggleButtonValue(value);
    fetchTableData(selectedDate, currentHub, value);
  };
  const changeDateValue = (dateValue) => {
    setSelectedDate(dateValue);
    fetchTableData(dateValue, currentHub, toggleButtonValue);
  };

  const fetchTableData = async (
    selectedDateValue = null,
    currentHubValue = null,
    toggleButton = null
  ) => {
    try {
      setLoading(true);
      const payload = {
        date:
          selectedDateValue !== null && (selectedDateValue || selectedDate)
            ? selectedDateValue?.format(PENDING_TRANSIT_DATE_FORMAT) ||
              selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
            : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(currentHubValue !== null &&
          (currentHubValue || currentHub) && {
            hubId: currentHubValue || currentHub
          })
      };
      const res = await inTransitDeliveriesCount(payload);
      const formattedData = formatTableData(
        res?.data,
        toggleButton || toggleButtonValue,
        currentHubValue
      );
      setTableData(formattedData);
      setLoading(false);
    } catch (error) {
      notify(error.message);
    }
  };

  const getCurrentinitalDate = async () => {
    const payload = { pageName: CURRENT_DATE_PARAMS.PENDING_TRANSIT };
    try {
      const res = await getCurrentActiveDate(payload);
      setInitalDate(dayjs(res?.data?.currentDate));
      setSelectedDate(dayjs(res?.data?.currentDate));
      fetchTableData(
        dayjs(res?.data?.currentDate),
        currentHub,
        toggleButtonValue
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleHubChange = (hubValue) => {
    setCurrentHub(hubValue);
    fetchTableData(selectedDate, hubValue, toggleButtonValue);
  };

  const exportTableData = async () => {
    try {
      const payload = {
        date: selectedDate
          ? selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(currentHub && { hubId: currentHub }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[1].value && {
          forwardDeliveriesOnly: true
        }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[2].value && {
          forwardDeliveriesOnly: false
        })
      };
      const data = await exportInTransitReport(payload);
      downloadAsXlsx(data, `inTransit_report_${selectedDate || dayjs()}.xlsx`);
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="pending-transit__container">
      {!HubDetails ? (
        <>
          <p className="display-xs pending-transit__title">
            {intl.formatMessage({ id: 'ops_control.pending_transit.header' })}
          </p>

          <div className="pending-transit__header">
            <BRToggleButton
              loading={loading}
              buttonsList={TOGGLE_BUTTONS_LIST}
              setValue={setToggleButtonValue}
              value={toggleButtonValue}
              changeToggleButton={changeToggleButton}
            />
            <PendingTransitFilter
              loading={loading}
              intl={intl}
              allHubs={allHubs}
              currentHub={currentHub}
              selectedDate={selectedDate}
              setCurrentHub={setCurrentHub}
              setSelectedDate={setSelectedDate}
              handleHubChange={handleHubChange}
              changeDateValue={changeDateValue}
            />
          </div>
          <div className="pending-transit__cards-container">
            <SingleInfoCard
              loading={loading}
              data={totalPendingTransit}
              title={intl.formatMessage({
                id: 'ops_control.pending_transit.totat_pending_orders'
              })}
            />

            <SingleInfoCard
              loading={loading}
              data={totalPendingTransitPlus}
              title={intl.formatMessage({
                id: 'ops_control.pending_transit.totat_pending_orders_plus'
              })}
            />
            <SingleInfoCard
              type={DELIVERIES_TYPE.PENDING}
              loading={loading}
              data={investigation}
              title={intl.formatMessage({
                id: 'ops_control.pending_transit.investigation'
              })}
            />
            <SingleInfoCard
              type={DELIVERIES_TYPE.DAMAGED}
              loading={loading}
              data={exceededInvestigation}
              title={intl.formatMessage({
                id: 'ops_control.pending_transit.exceeded_investigation'
              })}
            />
          </div>
          <div className="pending-transit__hubs-table-container">
            <BRTable
              showPagination={false}
              isLoading={loading}
              exportListFileFunction={exportTableData}
              listingData={tableData}
              columns={HUBS_TABLE_COLUMNS}
              hideFilterButton
              title={
                <div className="pending-transit__hubs-table-title">
                  <HubsIcon />
                  {intl.formatMessage({
                    id: 'ops_control.pending_transit.hubs_table.title'
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <HubDeliveries
          initalDate={initalDate}
          allHubs={allHubs}
          hubDetails={HubDetails}
          setHubDetails={setHubDetails}
          fetchMainTableData={fetchTableData}
          intl={intl}
        />
      )}
    </div>
  );
};
export default injectIntl(PendingTransit);
