import http from 'utils/http';

export const getStarLogs = (payload) => {
  return http.post(`users/stars/star-actions`, payload);
};

export const exportStarMap = (payload) => {
  return http.get('users/stars/star-actions/export', payload);
};

export const getStarLocations = (payload) => {
  return http.post('/users/stars/star-actions-locations', payload);
};
