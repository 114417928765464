import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import aclMatrix, {
  ACCOUNTANT,
  HUB_LEADER,
  SUPER_ADMIN,
  WAREHOUSE_CLERK
} from 'common/aclMatrix';
import {
  isDefaultBostaCourier,
  isUserAuthorized,
  isVendor
} from 'utils/helpers';
import { haveNewTransferPageAccess } from 'common/countries/countries-mapping';
import { ACL_MATRIX } from 'common/permissions';

import Hub from 'containers/Hub/Hub';
import ShelvesScan from 'containers/Hub/ShelvesScan/ShelvesScan';
import TransferToHub from 'containers/Hub/TransferToHub/TransferToHub';
import MoneyDebrief from 'containers/Hub/MoneyDebrief/MoneyDebrief';
import SealsLogs from 'containers/Hub/SealsLogs/SealsLogs';
import DispatchRTO from 'containers/Hub/DispatchRTO/DispatchRTO';
import NewPackagesDebrief from 'components/Hub/PackagesDebrief/PackagesDebrief';
import Container from 'components/Container/index';
import BRTabs from 'components/BRTabs/BRTabs';
import BRHeader from 'components/BRHeader/BRHeader';
import HubTransfer from 'components/HubOperations/HubTransfer/HubTransfer';
import ReceiveContainer from 'components/HubOperations/ReceiveContainer/ReceiveContainer';
import BarcodeToAWB from 'components/HubOperations/BarcodeToAWB/BarcodeToAWB';
import Manifests from 'components/HubOperations/Manifests/Manifests';
import WrongScan from 'components/HubOperations/WrongScan/WrongScan';
import BarcodeScanning from 'components/HubOperations/BarcodeToAWB/components/BarcodeScanning/BarcodeScanning';

import './HubOperationsContainer.less';

const HubOperationsContainer = ({ intl, match: { path } }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const showMoneyDebriefPage = () => {
    const countryObject = JSON.parse(
      localStorage.getItem('userAutoCodCollection')
    );
    return countryObject ? !countryObject.autoCODCollection : true;
  };

  const TABS = {
    rececive: {
      label: intl.formatMessage({ id: 'hubs.tabs.receive' }),
      component: ReceiveContainer,
      path: '/hubs/hub-operation/receive',
      exact: false,
      isUserAuthorized: isUserAuthorized(
        [...aclMatrix.DISPATCH_RTO_TAB, ...aclMatrix.HUB_OPERATIONS],
        [ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED]
      )
    },
    barcode_to_awb: {
      label: intl.formatMessage({ id: 'hubs.tabs.barcode_to_awb' }),
      component: BarcodeToAWB,
      path: '/hubs/hub-operation/barcode-to-awb',
      isUserAuthorized: isUserAuthorized(
        [SUPER_ADMIN, WAREHOUSE_CLERK, HUB_LEADER],
        [ACL_MATRIX.BARCODES_GET, ACL_MATRIX.BARCODES_CREATE]
      )
    },
    print_awb: {
      label: intl.formatMessage({ id: 'hubs.tabs.print_awb' }),
      component: () => <BarcodeScanning isAWBPrintingPage />,
      path: '/hubs/hub-operation/print-awb',
      isUserAuthorized: isUserAuthorized(
        [SUPER_ADMIN, WAREHOUSE_CLERK, HUB_LEADER],
        [ACL_MATRIX.DELIVERY_PRINT_AWB]
      )
    },
    shelves_scan: {
      label: intl.formatMessage({ id: 'hubs.tabs.add_to_shelf' }),
      component: ShelvesScan,
      path: '/hubs/hub-operation/shelves-scan',
      isUserAuthorized: isUserAuthorized(aclMatrix.SHELVES_SCAN, [
        ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED
      ])
    },
    transfer: {
      label: intl.formatMessage({ id: 'hubs.tabs.transfer' }),
      component: haveNewTransferPageAccess ? HubTransfer : TransferToHub,
      path: '/hubs/hub-operation/transfer-to-hub',
      exact: false,
      isUserAuthorized: isUserAuthorized(aclMatrix.HUB_OPERATIONS, [
        ACL_MATRIX.DELIVERY_MARK_AS_IN_TRANSIT_BETWEEN_HUBS
      ])
    },
    dispatch_rto: {
      label: intl.formatMessage({ id: 'hubs.tabs.dispatch_returns' }),
      component: DispatchRTO,
      path: '/hubs/hub-operation/dispatch-rto',
      isUserAuthorized: isUserAuthorized(aclMatrix.DISPATCH_RTO_TAB, [
        ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED
      ])
    },
    debrief_stars: {
      label: intl.formatMessage({ id: 'hubs.tabs.debrief_stars' }),
      component: NewPackagesDebrief,
      path: '/hubs/hub-operation/packages-debrief',
      isUserAuthorized: isUserAuthorized(aclMatrix.HUB_OPERATIONS, [
        ACL_MATRIX.ROUTES_DEBRIEF
      ])
    },
    debrief_cash: {
      label: intl.formatMessage({ id: 'hubs.tabs.debrief_cash' }),
      component: MoneyDebrief,
      path: '/hubs/hub-operation/money-debrief',
      exact: false,
      isUserAuthorized:
        isUserAuthorized(
          showMoneyDebriefPage()
            ? aclMatrix.DEBRIEF_CASH
            : [ACCOUNTANT, SUPER_ADMIN],
          [ACL_MATRIX.CASH_MONEY_CREATE]
        ) || userInfo?._id === '37i6VC9ueUAyr93nrb4Zr'
    },
    seals_logs: {
      label: intl.formatMessage({ id: 'hubs.tabs.seals_logs' }),
      component: SealsLogs,
      path: '/hubs/hub-operation/seals-logs',
      isUserAuthorized: isUserAuthorized(aclMatrix.HUB_OPERATIONS, [
        ACL_MATRIX.SEALS_LIST
      ])
    },
    manifests: {
      label: intl.formatMessage({ id: 'hubs.tabs.manifests' }),
      component: Manifests,
      path: '/hubs/hub-operation/manifests',
      isUserAuthorized: isUserAuthorized(aclMatrix.HUB_OPERATIONS, [
        ACL_MATRIX.RECEIVING_MANIFEST_LIST
      ])
    },
    wrong_scan: {
      label: intl.formatMessage({ id: 'hubs.tabs.wrong_scan' }),
      component: WrongScan,
      path: '/hubs/hub-operation/wrong-scan',
      isUserAuthorized: isUserAuthorized(aclMatrix.HUB_OPERATIONS, [
        ACL_MATRIX.DELIVERY_SCANS_LOGS_LIST
      ])
    }
  };

  const getDefaultTabPath = () => {
    let pathName = '';
    Object.keys(TABS).some((tab) => {
      if (TABS[tab].isUserAuthorized) {
        pathName = TABS[tab].path;
        return true;
      }
    });
    return pathName;
  };

  const getTabsPath = () => {
    const path = [];
    Object.keys(TABS).filter((tab) => {
      return TABS[tab].isUserAuthorized && path.push(TABS[tab].path);
    });

    return path;
  };

  return (
    <Switch>
      <Route
        path={getTabsPath()}
        render={() => (
          <Container
            className="br-hub-operation__content"
            header={
              <BRHeader
                title={intl.formatMessage({
                  id: 'hubs.title'
                })}
              />
            }
            content={<BRTabs tabs={TABS} />}
          />
        )}
      />
      <Redirect from={path} to={getDefaultTabPath()} />
    </Switch>
  );
};

export default injectIntl(withRouter(HubOperationsContainer));
