import dayjs from 'dayjs';
import React from 'react';
import classnames from 'classnames';
import { Tabs, Typography } from 'antd';
import { useIntl } from 'react-intl';

import { convertSecondsToTime } from 'utils/helpers';
import { CALL_TYPES } from 'constants/shipment-details';

import EmptyData from 'components/Pickups/components/PickupDetails/components/EmptyData/EmptyData';

import { ReactComponent as IncomingCallIcon } from 'assets/bosta-icons/incoming-call.svg';
import { ReactComponent as OutgoingCallIcon } from 'assets/bosta-icons/outgoing-call.svg';
import { ReactComponent as WhatsAppOutlineIcon } from 'assets/bosta-icons/whatsapp-outline.svg';
import { ReactComponent as SMSIcon } from 'assets/bosta-icons/Chat.svg';
import { ReactComponent as CircleCheckIcon } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as CircleClearIcon } from 'assets/bosta-icons/Circle-clear.svg';

import './CallLog.less';

const callIcons = {
  CALL: <OutgoingCallIcon />,
  MISSED_CALL: <IncomingCallIcon />,
  INCOMING_CALL: <IncomingCallIcon />
};

const getWhatsAppEntries = (whatsAppEntry, exception, intl) => {
  let whatsAppEntries = [];

  if (whatsAppEntry) {
    whatsAppEntries.push({
      ...whatsAppEntry,
      icon: <WhatsAppOutlineIcon />,
      title: (
        <Typography.Text className="br-pickup-activity-entry__header-title">
          {intl.formatMessage({
            id: 'pickups.pickup_details.call_log.whatsapp.clicked_on_button'
          })}
        </Typography.Text>
      )
    });
  }

  if (
    !exception?.whatsAppVerification?.conversationStatus
      ?.conversationStartedSuccessfully
  ) {
    return whatsAppEntries;
  }

  const {
    fakeAttempt,
    verified,
    time: validationTime,
    conversationStatus: { time: conversationStartTime }
  } = exception.whatsAppVerification;

  const noReply = !(verified || fakeAttempt);

  whatsAppEntries.push({
    icon: <WhatsAppOutlineIcon />,
    title: (
      <Typography.Text className="br-pickup-activity-entry__header-title">
        {intl.formatMessage({
          id: 'pickups.pickup_details.call_log.whatsapp.message_sent'
        })}
      </Typography.Text>
    ),
    date: conversationStartTime
  });

  const verificationEntry = {
    icon: <WhatsAppOutlineIcon />,
    date: validationTime?.$date ?? validationTime,
    title: (
      <>
        <Typography.Text className="br-pickup-activity-entry__header-title successful-entry">
          {intl.formatMessage({
            id: 'pickups.pickup_details.call_log.whatsapp.validated'
          })}
        </Typography.Text>
        <CircleCheckIcon className="successful-entry" />
      </>
    )
  };

  if (noReply) {
    verificationEntry.title = (
      <Typography.Text className="br-pickup-activity-entry__header-title">
        {intl.formatMessage({
          id: 'pickups.pickup_details.call_log.whatsapp.no_reply'
        })}
      </Typography.Text>
    );
  }

  if (fakeAttempt) {
    verificationEntry.title = (
      <>
        <Typography.Text className="br-pickup-activity-entry__header-title failed-entry">
          {intl.formatMessage({
            id: 'pickups.pickup_details.call_log.whatsapp.denied'
          })}
        </Typography.Text>
        <CircleClearIcon className="failed-entry" />
      </>
    );
  }

  whatsAppEntries.push(verificationEntry);

  return whatsAppEntries.reverse();
};

export default function CallLog({ callLogData, exception }) {
  const intl = useIntl();

  const callLogTabs = [
    {
      key: 'call_log',
      data: callLogData.callLogs.map(({ action, callDuration, ...call }) => ({
        ...call,
        icon: callIcons[action],
        title: (
          <>
            <Typography.Text className="br-pickup-activity-entry__header-title">
              {CALL_TYPES[action]}
            </Typography.Text>

            <Typography.Text
              className={classnames({
                'successful-entry': callDuration > 0
              })}
            >
              {convertSecondsToTime(callDuration)}
            </Typography.Text>
          </>
        )
      }))
    },
    {
      key: 'sms',
      data: callLogData.smsLogs.map((sms) => ({
        ...sms,
        icon: <SMSIcon />,
        title: (
          <>
            <Typography.Text className="br-pickup-activity-entry__header-title">
              {intl.formatMessage({
                id: 'deliveries.delivery_details.call_logs.sms_message'
              })}
            </Typography.Text>
          </>
        ),
        body: (
          <Typography.Paragraph className="line-clamp-3">
            <q>{sms.smsBody}</q>
          </Typography.Paragraph>
        )
      }))
    },
    {
      key: 'whatsapp',
      data: getWhatsAppEntries(callLogData.whatsAppLogs[0], exception, intl)
    }
  ];

  return (
    <Tabs
      defaultActiveKey={callLogTabs[0].key}
      size="large"
      centered
      className="br-pickup-activity-call-log"
    >
      {callLogTabs.map(({ key, data }) => (
        <Tabs.TabPane
          key={key}
          tab={intl.formatMessage({
            id: `deliveries.delivery_details.call_logs.${key}`
          })}
        >
          {data.length === 0 && <EmptyData />}
          {data.length > 0 && <EntryList data={data} />}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}

function EntryList({ data }) {
  return (
    <>
      {data.map((entry) => {
        const { _id, date, starName, title, icon, body } = entry;
        const formattedDate = date
          ? dayjs(date).tz().format('dddd, DD MMM YYYY - h:mm A')
          : undefined;
        return (
          <div key={_id} className="br-pickup-activity-entry">
            {icon}

            <div>
              <Typography.Paragraph className="br-pickup-activity-entry__header">
                {title}
              </Typography.Paragraph>

              {body}

              <Typography.Paragraph>{formattedDate}</Typography.Paragraph>

              <Typography.Paragraph>{starName}</Typography.Paragraph>
            </div>
          </div>
        );
      })}
    </>
  );
}
