import classnames from 'classnames';
import { Typography } from 'antd';

import EmptyData from 'components/Pickups/components/PickupDetails/components/EmptyData/EmptyData';

import './DetailsGrid.less';

export default function DetailsGrid({
  icon,
  strokedIcon,
  title,
  children,
  className,
  ...props
}) {
  return (
    <div
      className={classnames('br-pickup-details', {
        [className]: !!className
      })}
      {...props}
    >
      <div className="br-pickup-details-header">
        <div
          className={classnames('br-pickup-details-header__icon', {
            'stroked-icon': !!strokedIcon
          })}
        >
          {icon}
        </div>
        <Typography.Title className="br-pickup-details-header__title" level={4}>
          {title}
        </Typography.Title>
      </div>
      <div className="br-pickup-details-content">
        {!children && <EmptyData className="full-width" /> }
        {children}
      </div>
    </div>
  );
}
