import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { openModal } from 'utils/modal';

import { notify } from 'components/Notify/Notify';
import PreviewImage from 'components/PreviewImage/PreviewImage';

import uploadImage from 'assets/bosta-icons/Upload-Attachment.svg';

import './UploadAttachment.less';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function getBinary(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class UploadAttachment extends React.Component {
  state = {
    fileList: [],
    imageValid: false,
    isSendLoading: false
  };

  getAttachments = async () => {
    const { fileList } = this.state;
    const { encodeBase64, disableEncoding } = this.props;
    const attachments = [];
    const attachmentsTypes = [];
    await Promise.all(
      fileList.map(async (item) => {
        if (!item.url && !item.preview) {
          item.preview = disableEncoding
            ? item.originFileObj
            : encodeBase64
            ? await getBase64(item.originFileObj)
            : await getBinary(item.originFileObj);
        }
        attachments.push(item.preview);
        attachmentsTypes.push(item.type);
      })
    );
    return { attachments, attachmentsTypes };
  };

  getTotalFilesSize = (filesList) =>
    filesList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0
    );

  handleChange = async (info) => {
    // if (info.fileList.length < 11) {
    if (
      this.props.noMaxTotalSize ||
      this.getTotalFilesSize(info.fileList) / 1024 / 1024 < 15
    ) {
      this.setState({
        fileList: info.fileList.filter((file) => !!file.status)
      });
    }
    this.props.onChange && this.props.onChange(await this.getAttachments());
    // }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    openModal(PreviewImage, {
      previewImage: file.thumbUrl || file.url || file.preview,
      showPreviewActions: false,
      onCloseAction: (values) => this.onCloseAction({ file, ...values }),
      previewImageTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  };

  customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess();
    }, 0);
  };

  beforeUpload = (file) => {
    const { intl, maxSize = 2 } = this.props;

    const isExceedingMaximumSize = file.size / 1024 / 1024 <= maxSize;

    if (!isExceedingMaximumSize) {
      notify(
        intl.formatMessage(
          { id: 'common.size_error' },
          {
            size: maxSize
          }
        )
      );
    }
    return isExceedingMaximumSize;
  };

  render() {
    const { fileList } = this.state;
    const {
      intl,
      maxCount = 1,
      accept = null,
      customSubtitle = '',
      customTitle,
      hidePlusIcon = false,
      sizeLimit = false,
      encodeBase64 = true,
      haveUploadButton = false,
      customButton,
      beforeUpload,
      uploadTitle,
      uploadIcon,
      withTitle = true,
      withSubtitle = true,
      children,
      listType,
      hideUploadButtonAfterMaxCount
    } = this.props;
    const uploadContent = () => {
      if (fileList.length === 0) {
        return (
          <div className="br-upload-dialog__empty-view">
            <span className="br-upload-dialog__empty-view__image">
              <img alt="" src={uploadIcon || uploadImage} />
            </span>
            <span className="br-upload-dialog__empty-view__title">
              <span className="br-upload-dialog__empty-view__upload-title-part">
                {uploadTitle ||
                  intl.formatMessage({
                    id: 'support_tickets.upload_images.click_to_upload'
                  })}
              </span>
              {withTitle &&
                (customTitle ||
                  intl.formatMessage({
                    id: 'support_tickets.upload_images.title'
                  }))}
            </span>
            {withSubtitle && (
              <span className="br-upload-dialog__empty-view__sub-title">
                {customSubtitle ||
                  intl.formatMessage({
                    id: 'support_tickets.upload_images.sub_title'
                  })}
              </span>
            )}
            {haveUploadButton &&
              (customButton || (
                <Button className="br-upload-dialog__empty-view__upload-button">
                  {intl.formatMessage({
                    id: 'support_tickets.upload_images.upload'
                  })}
                </Button>
              ))}
          </div>
        );
      }

      return (
        <div className="br-upload-dialog__upload-card">
          {!hidePlusIcon && <PlusOutlined />}
          <span>
            {intl.formatMessage({
              id: 'support_tickets.upload_images.upload'
            })}
          </span>
        </div>
      );
    };

    return (
      <Upload
        className={classnames(
          'br-upload-dialog__upload br-support-ticket__upload',
          {
            'br-upload-dialog__upload-empty': !fileList.length,
            'br-hide-upload-button':
              hideUploadButtonAfterMaxCount && fileList.length === maxCount
          }
        )}
        listType={listType || 'picture-card'}
        accept={accept || '.jpg, .png, .csv, .xlsx'}
        fileList={fileList}
        multiple={true}
        customRequest={this.customRequest}
        onPreview={this.handlePreview}
        onChange={this.handleChange}
        maxCount={this.props.maxCount}
        beforeUpload={beforeUpload || this.beforeUpload}
      >
        {children || uploadContent()}
      </Upload>
    );
  }
}

export default injectIntl(UploadAttachment);
